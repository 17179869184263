import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-chooser-entity-card',
  templateUrl: './chooser-entity-card.component.html',
  styleUrls: ['./chooser-entity-card.component.scss'],
})
export class ChooserEntityCardComponent implements OnInit {
  @Input() entity: any;
  @Input() entityType: string;
  @Input() allowAddMultiple = false;
  public name: string;
  public image: string;
  @Output() entityClicked = new EventEmitter();
  @Output() detailsClicked = new EventEmitter();
  @Output() toggleSelection = new EventEmitter();
  @Output() addEntity = new EventEmitter();
  @Input() showDetailIcon = false;
  @Input() selectedEntityIndex: any;
  @Input() isExistingEntity;
  public justAdded = false;
  constructor() {}

  ngOnInit(): void {
    if (this.entity) {
      this.name = this.entity.name || this.entity.fileName;
      if (this.entity.entityType === 'workspace') {
        this.image = 'assets/images/folder_icon.svg';
      } else {
        this.image = this.entity.thumbnail || this.entity.smallViewableDownloadUrl || this.entity.smallViewableUrl;
      }
    }
  }

  getAddIcon() {
    return 'add_circle_outline';
    // return this.isExistingEntity || this.justAdded ? 'add_circle' : 'add_circle_outline';
  }

  handleClick() {
    this.entityClicked.emit(this.entity);
  }

  handleDetailsClicked() {
    this.detailsClicked.emit(this.entity);
  }

  toggleCheckbox(event) {
    this.toggleSelection.emit({ entityData: this.entity, selected: event.checked });
  }

  addEntityData(entity) {
    this.addEntity.emit(entity);
  }

  get isEntityItemOption() {
    return Boolean(this.entityType === 'item' && this.entity?.optionName && this.entity?.roles?.includes('option'));
  }
}
