import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Entities, EntityReference, Types } from '@contrail/sdk';
import { CdkDrag } from '@angular/cdk/drag-drop';
import { ContentModalComponent } from '@common/content/content-modal/content-modal.component';
import { ItemDetailsModalComponent } from 'src/app/common/items/item-details-modal/item-details-modal.component';
import { BoardItemService } from '../../../board-item/board-item.service';
import { ContextualEntityHelper } from '../../contextual-entity-helper';
import { DocumentContentEditorService } from '../../document-content-editor/document-content-editor.service';
import { DocumentService } from '../../document.service';
import { ComponentEditorService } from '../../component-editor/component-editor.service';
import { UntypedFormControl } from '@angular/forms';
import { ObjectUtil } from '@contrail/util';
import { AuthService } from '@common/auth/auth.service';
import { TypePropertyFormHelper } from '@common/types/forms/type-property-form-field/property-types/type-property-form-helper';
import { MatMenuTrigger } from '@angular/material/menu';
import { RootStoreState } from '@rootstore';
import { Store } from '@ngrx/store';
import { FeatureFlagsSelectors } from '@common/feature-flags';
import { take, tap } from 'rxjs';
import { Feature, FeatureFlag } from '@common/feature-flags/feature-flag';
import { AssortmentsSelectors } from '@common/assortments/assortments-store';
import { BoardService } from '../../../board.service';
import { WorkspacesSelectors } from '@common/workspaces/workspaces-store';
import { EntityModalComponent } from '@common/entity-details/entity-modal/entity-modal.component';

@Component({
  selector: 'app-property-configurator-entity-details',
  templateUrl: './property-configurator-entity-details.component.html',
  styleUrls: ['./property-configurator-entity-details.component.scss'],
})
export class PropertyConfiguratorEntityDetailsComponent implements OnInit, OnChanges {
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Output() setEditingInProgress = new EventEmitter();
  @Input() selectedElement: any;
  public contextualEntity;
  public contextualEntityReference: EntityReference;
  public canViewDetails = false;
  public name;
  //public assortment: any;
  //public project: any;
  public projectItem: any;

  currentBoard: any;
  currentWorkspace: any;
  editItemFamilyName = false;
  //editItemOptionName = false;
  itemFamilyNameInput = new UntypedFormControl();
  //itemOptionNameInput = new UntypedFormControl();
  itemFamilyNameDisabled = false;
  itemOptionNameDisabled = false;
  //assortments: any[];
  editable = true;
  itemContextFeatureActive = false;
  projectContextFeatureActive = false;
  queryContextAssortmentInProgress = false;
  queryEntitiesInProgress = false;
  hasSvgRecolorFeatureFlag = false;

  public elementType;
  public viewableEntity;
  @Input() resetDrag: any;
  @ViewChild(CdkDrag) cdkDrag: CdkDrag;
  @ViewChild(MatMenuTrigger) optionListMenuTrigger: MatMenuTrigger;
  @ViewChild('editItemFamilyNameInput') editItemFamilyNameInput: ElementRef;
  //@ViewChild('editItemOptionNameInput') editItemOptionNameInput: ElementRef;

  constructor(
    private matDialog: MatDialog,
    private contextualEntityHelper: ContextualEntityHelper,
    private boardItemService: BoardItemService,
    private contentEditorService: DocumentContentEditorService,
    private documentService: DocumentService,
    private componentEditorService: ComponentEditorService,
    private authService: AuthService,
    private boardService: BoardService,
    private store: Store<RootStoreState.State>,
  ) {}

  ngOnInit(): void {
    if (this.authService.isOnSharedLink()) {
      this.editable = false;
    }
    this.store
      .select(FeatureFlagsSelectors.featureFlags)
      .pipe(
        tap((flags: FeatureFlag[]) => {
          const featureNames = flags.map((x) => x.featureName);
          if (featureNames.includes(Feature.ITEM_CONTEXT)) {
            this.itemContextFeatureActive = true;
          }
          if (featureNames.includes(Feature.SVG_RECOLORING)) {
            this.hasSvgRecolorFeatureFlag = true;
          }
          if (featureNames.includes(Feature.PROJECT_CONTEXT)) {
            this.projectContextFeatureActive = true;
          }
        }),
      )
      .subscribe();
    this.store.select(AssortmentsSelectors.backingAssortmentItemData).subscribe((backingAssortmentItemData) => {
      if (!this.contextualEntity?.name) {
        this.deriveSelectedEntity(true);
      }
    });
    this.store
      .select(WorkspacesSelectors.currentWorkspace)
      .pipe(take(1))
      .subscribe((currentWorkspace) => (this.currentWorkspace = currentWorkspace));
    this.boardService.currentBoard.pipe(tap((board) => (this.currentBoard = board))).subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.selectedElement) {
      return;
    }
    //this.assortment = null;
    //this.project = null;
    this.name = null;
    this.canViewDetails = false;
    this.deriveSelectedEntity();
    this.computeElementType();
    if (changes.resetDrag && !changes.resetDrag.firstChange) {
      this.cdkDrag?.reset();
    }
  }

  private computeElementType() {
    if (this.selectedElement?.modelBindings?.item) {
      this.elementType = 'ITEM';
    } else if (this.selectedElement?.modelBindings?.color) {
      this.elementType = 'COLOR';
    } else if (this.selectedElement?.modelBindings?.asset) {
      this.elementType = 'ASSET'; // Asset has also content property in modelBindings
    } else if (this.selectedElement?.modelBindings?.content) {
      this.elementType = 'CONTENT';
    } else if (this.selectedElement?.modelBindings?.image) {
      this.elementType = 'IMAGE'; //TODO: @Yulia Image has `content`, not image
    }
  }

  viewDetails() {
    console.log('view details: ', this.selectedElement, this.contextualEntityReference);
    const modelBindings = this.selectedElement?.modelBindings;
    if (modelBindings?.item) {
      this.launchItemDetails(this.contextualEntityReference.id);
    } else if (modelBindings?.color) {
      this.launchColorDetails(this.contextualEntityReference.id);
    } else if (modelBindings?.content) {
      this.launchContentDetails(this.contextualEntityReference.id);
    }
  }

  private async deriveSelectedEntity(skipProjectItem = false) {
    this.queryEntitiesInProgress = true;
    const contextualEntityInformation = await this.contextualEntityHelper.getContextualEntityFromDocumentElement(
      this.selectedElement,
      false, //TODO: @Yulia `false` doesn't make sense because `if (fetchContentViewable != null) {...}`
    );
    if (!contextualEntityInformation) {
      this.contextualEntityReference = null;
    }
    this.contextualEntityReference = contextualEntityInformation.reference;
    this.name = contextualEntityInformation.name || 'N/A';
    this.contextualEntity = ObjectUtil.cloneDeep(contextualEntityInformation.entity);
    this.viewableEntity = contextualEntityInformation?.viewableEntity;
    if (['item', 'content', 'color'].includes(this.contextualEntityReference.entityType)) {
      this.canViewDetails = true;
    } else {
      //TODO: @Yulia : No else cases, always TRUE
      this.canViewDetails = false;
    }
    if (this.elementType === 'ITEM' && !skipProjectItem) {
      const itemType = await new Types().getType({ path: 'item' });
      const familyNameProp = itemType.typeProperties.find((prop) => prop.slug === 'name');
      const optionNameProp = itemType.typeProperties.find((prop) => prop.slug === 'optionName');
      this.itemFamilyNameDisabled = await TypePropertyFormHelper.isDisabled(
        { editable: this.editable, typeProperty: familyNameProp },
        this.contextualEntity,
      );
      this.itemOptionNameDisabled = await TypePropertyFormHelper.isDisabled(
        { editable: this.editable, typeProperty: optionNameProp },
        this.contextualEntity,
      );
      if (!this.projectItem || !this.selectedElement.modelBindings.projectItem?.includes(this.projectItem.id)) {
        this.projectItem = await this.contextualEntityHelper.getContextProjectItem(this.selectedElement);
      }
      /*
      this.assortment = await this.contextualEntityHelper.getContextAssortment(this.selectedElement);
      if (this.contextualEntity?.assortments) {
        this.assortments = this.contextualEntity.assortments;
      }*/
    }
    this.queryEntitiesInProgress = false;
  }

  private launchItemDetails(itemId: string, accessLevel = 'EDIT') {
    const config = {
      data: { itemId, accessLevel },
      panelClass: [`no-padding`, `item-details-modal`],
      maxWidth: '95vw',
      width: '1350px',
      height: '800px',
      autoFocus: true,
    };
    const dialogRef = this.matDialog.open(ItemDetailsModalComponent, config);
    const itemModalComponent: ItemDetailsModalComponent = dialogRef.componentInstance;
    const subscription = itemModalComponent.updated.subscribe((result) => {
      this.boardItemService.syncElements(result);
    });
    dialogRef.afterClosed().subscribe(() => subscription.unsubscribe());
  }

  private async launchColorDetails(colorId: string, accessLevel = 'EDIT') {
    const config = {
      data: {
        entityReference: this.contextualEntityReference.reference,
        entity: this.contextualEntity,
        accessLevel: 'EDIT',
      },
      panelClass: [`no-padding`, `entity-details-modal`],
      maxWidth: '95vw',
      width: '1350px',
      height: '800px',
      autoFocus: true,
    };
    const dialogRef = this.matDialog.open(EntityModalComponent, config);
    const entityModalComponent: EntityModalComponent = dialogRef.componentInstance;
    const subscription = entityModalComponent.updated.subscribe((result) => {
      const event = {
        changes: result.changes,
        object: result.entity,
      };
      this.componentEditorService.saveEntitiesAndSyncElements(event);
    });
    dialogRef.afterClosed().subscribe(() => subscription.unsubscribe());
  }

  private launchContentDetails(contentId: string, accessLevel = 'EDIT') {
    const content = this.contextualEntity;
    const config = {
      data: { contentId, accessLevel, content },
      panelClass: [`no-padding`],
      maxWidth: '100vw',
      width: '98vw',
      height: '98vh',
      autoFocus: true,
    };
    const dialogRef = this.matDialog.open(ContentModalComponent, config);
  }

  public openContentEditor() {
    if (this.viewableEntity?.contentType !== 'image/svg+xml') {
      return;
    }

    const canvasElement = this.documentService.getCanvasElementById(this.selectedElement.id);
    this.contentEditorService.openSvgEditor(canvasElement);
  }

  public copyViewable() {
    console.log('Copy viewable from component', this.viewableEntity, this.contextualEntity);
    if (!this.viewableEntity?.primaryFileUrl || !this.viewableEntity?.contentType) {
      return;
    }
    this.documentService.fileHandler.addImageElementsFromFileUrl(
      this.viewableEntity.primaryFileUrl,
      this.viewableEntity.fileName || 'fileName',
      this.viewableEntity.contentType,
      {
        position: {
          x:
            this.selectedElement.position.x +
            this.selectedElement.elements.find((e) => e.type === 'image').size.width +
            150,
          y: this.selectedElement.position.y,
        },
      },
    );
  }

  public handleSubMenuClick(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  public startEditItemFamilyName() {
    if (this.elementType !== 'ITEM' || this.itemFamilyNameDisabled) {
      return;
    }
    this.itemFamilyNameInput.setValue(this.name);
    this.editItemFamilyName = true;
    setTimeout(() => {
      this.editItemFamilyNameInput.nativeElement.focus();
    }, 10);
    this.setEditingInProgress.emit(true);
  }

  public async endEditItemFamilyName(event, save = false) {
    event.stopPropagation();
    this.editItemFamilyName = false;
    if (this.itemFamilyNameInput.value.length > 0 && save) {
      if (this.contextualEntity.name === this.itemFamilyNameInput.value) {
        return;
      }
      this.name = this.itemFamilyNameInput.value;
      this.contextualEntity.name = this.name;
      const changes = { name: this.itemFamilyNameInput.value };
      const updatedItem = await new Entities().update({
        entityName: 'item',
        id: this.contextualEntity.itemFamilyId,
        object: changes,
      });
      this.boardItemService.syncElements({ object: updatedItem, changes });
    }
    this.setEditingInProgress.emit(false);
  }

  /*
  public startEditItemOptionName(event) {
    event.stopPropagation();
    if (this.elementType !== 'ITEM' || this.itemOptionNameDisabled || !this.contextualEntity?.optionName) {
      return;
    }
    this.itemOptionNameInput.setValue(this.contextualEntity.optionName);
    setTimeout(() => {
      this.editItemOptionNameInput.nativeElement.focus();
    }, 10);
    this.editItemOptionName = true;
  }

  public async endEditItemOptionName(event, save = false) {
    event.stopPropagation();
    this.editItemOptionName = false;

    if (this.itemOptionNameInput.value.length > 0 && save) {
      if (this.contextualEntity.optionName === this.itemOptionNameInput.value) {
        return;
      }
      this.contextualEntity.optionName = this.itemOptionNameInput.value;
      const changes = { optionName: this.itemOptionNameInput.value };
      const updatedItem = await new Entities().update({
        entityName: 'item',
        id: this.contextualEntity.id,
        object: changes,
      });
      this.boardItemService.syncElements({ object: updatedItem, changes });
    }
  }*/
}
