import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDividerModule } from '@angular/material/divider';
import { ComponentsModule } from 'src/app/common/components/components.module';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { PipesModule } from '@common/pipes/pipes.module';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { DocumentCreateTypedItemsComponent } from './document-create-item/create-typed-items-component/create-typed-items.component';
import { DocumentCreateItemsComponent } from './document-create-item/create-item-component/create-items.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { TypesModule } from '@common/types/types.module';

@NgModule({
  declarations: [DocumentCreateItemsComponent, DocumentCreateTypedItemsComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    ComponentsModule,
    MatExpansionModule,
    MatDividerModule,
    MatTooltipModule,
    PipesModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    TypesModule,
  ],
  exports: [DocumentCreateItemsComponent, DocumentCreateTypedItemsComponent],
})
export class DocumentItemModule {}
