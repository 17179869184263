<div class="ml-3 flex items-center">
  <div class="h-[40px] mr-1 flex items-center" [matTooltip]="getTooltipText()" matTooltipClass="multiline-tooltip">
    <mat-icon data-test="regenerate-lineboard-options-summary">info_outline</mat-icon>
  </div>
  <button
    matTooltip="Lineboard options"
    mat-icon-button
    data-test="regenerate-lineboard-options-button"
    (click)="editLineboardConfig()"
  >
    <mat-icon>tune</mat-icon>
  </button>
  <button
    data-test="lineboard-select-all"
    mat-icon-button
    matTooltip="Select all lineboard frames"
    matTooltipPosition="above"
    (click)="selectAllLineboardFrames()"
  >
    <mat-icon svgIcon="lineboard-selectall"></mat-icon>
  </button>
</div>
