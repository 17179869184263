import { DocumentElement, PositionDefinition, ScaleTransformation, SizeDefinition } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { CanvasDocument, CANVAS_MODE } from '../../canvas-document';
import { CanvasUtil } from '../../canvas-util';
import { ImageElement } from '../../components/image-element/image-element';
import { BLACK_05, TABLE_ROW_MIN_HEIGHT, TABLE_TEXT_PADDING } from '../../constants';
import { DrawParams, DrawOptions } from '../../renderers/canvas-renderer';
import { CanvasElement } from '../canvas-element';
import { DEFAULT_TEXT_FONT_FAMILY, DEFAULT_TEXT_FONT_SIZE, TEXT_VALIGN } from '../text/editor/text-editor';
import { TextImageGenerator, TextImageGeneratorOptions } from '../text/text-image-generator';
import { TextStyle } from '../text/text-style';
import { DEFAULT_TABLE_TEXT_BORDER_SIZE, TABLE_TEXT_PLACEHOLDER } from './table-editor/table-editor';
import { Table } from './table-manager/table/table';

export class CanvasCellElement extends CanvasElement {
  private textImageElement: ImageElement = null;
  private textStyle: TextStyle;
  protected isLoadingInProgress = false;
  public textSize: SizeDefinition = null;
  public currentElementDefinition: DocumentElement = null;
  public currentScale: ScaleTransformation = null;

  public table: Table;
  public rowIndex: number;
  public columnIndex: number;

  constructor(
    public elementDefinition: DocumentElement,
    protected canvasDocument: CanvasDocument,
    public interactable = false,
  ) {
    elementDefinition.text = CanvasUtil.removeFontSize(elementDefinition.text); // Remove inline font size since cell uses style.font.size for entire text
    super(elementDefinition, canvasDocument, interactable);
    const defaultStyle = {
      backgroundColor: this.DEFAULT_BACKGROUND_COLOR,
    };
    this.elementDefinition.style = ObjectUtil.mergeDeep(defaultStyle, this.elementDefinition.style || {});
    this.isAsync = true;
    this.isRotationEnabled = false;
    this.isResizeEnabled = false;
    this.isDragEnabled = false;
    this.TEXT_PADDING = TABLE_TEXT_PADDING;
    this.TEXT_PLACEHOLDER = TABLE_TEXT_PLACEHOLDER;
    this.DEFAULT_BORDER_SIZE = DEFAULT_TABLE_TEXT_BORDER_SIZE;
    this.VALID_PROPERTIES = ['style.font.size', 'style.text.valign'];
    this.EDITOR_INLINE_STYLE_COMMANDS = [
      'textColor',
      'textBackgroundColor',
      'listType',
      'indent',
      'outdent',
      'bold',
      'italic',
      'underline',
      'strikethrough',
      'link',
      'unlink',
    ];
    this.EDITOR_STYLE_COMMANDS = ['textValign', 'textAlign', 'fontSize', 'fontFamily', 'backgroundColor'];
  }

  protected draw(ctx: any, opts: DrawParams, options?: DrawOptions): { height: number } {
    return;
  }

  public render(ctx: any, { x, y, width, height }: DrawParams, options?: DrawOptions) {
    if (this.isVisible) {
      const coveredArea = this.getCoveredArea();
      if (this.canvasDocument.mode !== CANVAS_MODE.SNAPSHOT && coveredArea < 0.02) {
        this.drawPlaceholder(ctx, { x, y, width, height }, options);
      } else {
        this.drawText(ctx, { x, y, width, height }, options);
      }
    }
  }

  private drawPlaceholder(ctx, { x, y, width, height }, options?: DrawOptions) {
    if (this.elementDefinition.text === '') return;
    ctx.beginPath();
    ctx.rect(x, y, width, height);
    ctx.fillStyle = BLACK_05;
    ctx.fill();
    ctx.closePath();
  }

  private drawText(ctx, { x, y, width, height }, options?: DrawOptions) {
    if (width === 0 || height === 0 || this.elementDefinition.text === '') {
      return;
    }
    this.textStyle = new TextStyle(this);
    if (!options?.skipRecalculate && (!this.textImageElement || this.hasChanged())) {
      if (!this.isLoadingInProgress) {
        if (this.canvasDocument.mode === 'SNAPSHOT') {
          console.log('Warning: generating text image while making snapshot', this);
        }
        this.textImageElement = null;
        this.textSize = null;
        this.generateTextImage({ width, height });
      }
    } else {
      if (this.textImageElement) {
        const scale = this.getScale();
        const imgScale = scale?.x != null ? scale.x / (this.currentScale?.x ?? 1) : 1;
        let imgW = this.textImageElement?.img?.width * imgScale;
        let imgH = this.textImageElement?.img?.height * imgScale;
        if (options?.scaleBy) {
          imgW = imgW / options.scaleBy;
          imgH = imgH / options.scaleBy;
        }

        const valign = this.elementDefinition?.style?.text?.valign ?? TEXT_VALIGN;
        const rowHeight = this.table.row(this.rowIndex).height;
        switch (valign) {
          case 'middle':
            y = y + Math.round(rowHeight * 0.5 - height * 0.5);
            break;
          case 'bottom':
            y = y + Math.round(rowHeight - height);
            break;
          default:
            break;
        }

        ctx.drawImage(this.textImageElement.img, x, y, imgW, imgH);
      }
    }
  }

  private async generateTextImage({ width, height }) {
    await this.setTextImage({ width, height });
    const fontFamily = `${this.elementDefinition.style?.font?.family ?? DEFAULT_TEXT_FONT_FAMILY}`;
    const fontSize = this.elementDefinition.style?.font?.size ?? DEFAULT_TEXT_FONT_SIZE;
    const fontSizeString = `${fontSize}pt`;
    const textSize = this.canvasDocument.editorHandler.editorCalc.getTextSize(
      this.elementDefinition.text,
      fontSizeString,
      fontFamily,
    );
    this.textSize = {
      width: Math.min(this.elementDefinition.size.width, textSize.width + 2 * this.TEXT_PADDING),
      height: textSize.height,
    };
    this.canvasDocument.debounceDraw();
    this.isLoadingInProgress = false;
  }

  private async setTextImage({ width, height }) {
    const scale = this.getScale();
    this.textStyle = new TextStyle(this);
    this.currentElementDefinition = ObjectUtil.cloneDeep(this.elementDefinition);
    this.currentScale = ObjectUtil.cloneDeep(scale);
    const options: TextImageGeneratorOptions = { width, height, padding: this.TEXT_PADDING * (scale?.x ?? 1) };
    if (scale?.x != null) {
      const sizeNotScaled = this.getSize({ skipScale: true });
      options.originalWidth = sizeNotScaled.width - this.TEXT_PADDING * 2;
      options.originalHeight = sizeNotScaled.height - this.TEXT_PADDING * 2;
      options.scale = scale.x;
    }
    this.textImageElement = await TextImageGenerator.getGenerateTextImagePromise(
      this.elementDefinition.text,
      options,
      this.textStyle,
      true,
      null,
      false,
    );
  }

  public async preload(options?: DrawOptions) {
    const { width, height } = this.getSize(Object.assign(ObjectUtil.cloneDeep(options), { skipScale: false }));
    await this.setTextImage({ width, height });
    return this.textImageElement;
  }

  public isEmpty() {
    return (
      this.elementDefinition.text === '' ||
      this.canvasDocument?.editorHandler?.editorCalc?.isEmptyTextContent(this.elementDefinition.text)
    );
  }

  public clearText() {
    this.elementDefinition.text = '';
    this.elementDefinition.size = {
      width: this.table.column(this.columnIndex).width,
      height: TABLE_ROW_MIN_HEIGHT,
    };
  }

  /**
   * Adjust element height if needed or if @force according to current element text or to given @text
   * to fit on element width.
   * @param param0
   * @returns
   */
  public autoAdjustSize({ force, text }: { force?: boolean; text?: string } = { force: false }): DocumentElement {
    if (force || this.shouldBreakOrExpandText()) {
      const fontFamily = `${this.elementDefinition.style?.font?.family ?? DEFAULT_TEXT_FONT_FAMILY}`;
      const fontSize = `${this.elementDefinition.style?.font?.size ?? DEFAULT_TEXT_FONT_SIZE}pt`;
      const fixedWidth = `${this.elementDefinition.size.width - this.TEXT_PADDING * 2}px`;
      const size = this.canvasDocument.editorHandler.editorCalc.getTextSize(
        text ?? this.elementDefinition.text,
        fontSize,
        fontFamily,
        fixedWidth,
      );
      this.elementDefinition.size = {
        height: Math.max(size.height + this.TEXT_PADDING * 2, TABLE_ROW_MIN_HEIGHT),
        width: this.elementDefinition.size.width,
      };
      return {
        size: {
          height: this.elementDefinition.size.height,
          width: this.elementDefinition.size.width,
        },
      };
    }
    return null;
  }

  /**
   * Check if actual text size @textSize (width: auto) doesn't fit on
   * current element width; or if text was broken into lines to fit on
   * current element width but now can be expanded
   * @returns
   */
  private shouldBreakOrExpandText() {
    return (
      this.textSize &&
      // break text if new width is less than actual text size width
      (this.elementDefinition.size.width < this.textSize.width ||
        // expand text if new width is greater than text that was fit at fixed width
        (this.elementDefinition.size.width > this.currentElementDefinition.size.width &&
          this.currentElementDefinition.size.width === this.textSize.width))
    );
  }

  private hasChanged() {
    // regenerate if text is different
    if (this.elementDefinition.text !== this.currentElementDefinition.text) return true;

    // regenerate if style is different (valign, align, font size, font family)
    const styleChange = ObjectUtil.compareDeep(
      this.elementDefinition.style ?? {},
      this.currentElementDefinition.style ?? {},
      '',
    ).filter((v) => ['backgroundColor'].indexOf(v.propertyName) === -1);
    if (styleChange.length > 0) return true;

    const scaleChange = ObjectUtil.compareDeep(this.getScale(), this.currentScale, '');
    if (scaleChange.length > 0) return true;

    const sizeChange = ObjectUtil.compareDeep(this.elementDefinition.size, this.currentElementDefinition.size, '');
    // do not regenerate if size wasn't changed
    if (sizeChange.length === 0) return false;

    // regenerate if size changed and alignment is not left
    if (['center', 'right'].indexOf(this.elementDefinition?.style?.text?.align) !== -1 && sizeChange.length > 0)
      return true;

    // regenerate if height is different
    if (this.elementDefinition.size.height !== this.currentElementDefinition.size.height) return true;

    // regenerate if need to break lines or undo broken lines
    if (this.shouldBreakOrExpandText()) return true;
    return false;
  }

  public getDimensions(options?: DrawOptions): any {
    if (!this.table) return { width: 0, height: 0 };
    const { x, y } = this.getPosition(options);
    const { width, height } = this.getSize(options);
    return { x, y, width, height };
  }

  public getPosition(options?: DrawOptions): PositionDefinition {
    if (!this.table) return { x: 0, y: 0 };
    const cellX = this.table.column(this.columnIndex).x;
    const cellY = this.table.row(this.rowIndex).y;
    return {
      x: this.table.position.x + cellX,
      y: this.table.position.y + cellY,
    };
  }

  public getScale(): ScaleTransformation {
    return this.table.element.getScale();
  }
}
