import { DRAG_DIRECTIONS } from '../../../../renderers/selection-widget-renderer/selection-widget-renderer';
import { ElementMouseTarget } from '../../../../state/canvas-state';
import { Table } from '../table/table';
import { TableRange } from '../table/table-range';
import { TableSelectRenderer } from './table-select-renderer';

export class TableSelectState {
  public selectedRanges: TableRange[] = [];
  private renderer: TableSelectRenderer;
  constructor(private table: Table) {
    this.renderer = new TableSelectRenderer(this.table, this);
  }

  public render(ctx, params) {
    this.renderer.render(ctx, params);
  }

  public clear() {
    this.set([]);
  }

  private addRange(range: TableRange, ranges: TableRange[], shouldMerge = false) {
    const { intersectedRange, otherRanges } = this.getIntersectedAndOtherRanges(range, ranges);
    if (intersectedRange) {
      const difference = intersectedRange.difference(range);
      this.set([...otherRanges, ...difference]);
    } else {
      this.set(shouldMerge ? this.mergeRange(range, ranges) : [...ranges, range]);
    }
  }

  public set(ranges: TableRange[]) {
    this.selectedRanges = ranges;
  }

  private setRange(range: TableRange) {
    this.set([range]);
  }

  public selectedColumns(): TableRange[] {
    return this.selectedRanges.filter((r) => r.startRow === 0 && r.endRow === this.table.rows.length - 1);
  }

  public selectedRows(): TableRange[] {
    return this.selectedRanges.filter((r) => r.startColumn === 0 && r.endColumn === this.table.columns.length - 1);
  }

  /**
   * Get fully selected column indexes (no other single cells are selected)
   * @returns
   */
  public getSelectedColumnIndexes(): number[] {
    const selectedColumns = this.selectedColumns();
    if (selectedColumns.length === this.selectedRanges.length) {
      const columnIndexes = [];
      this.selectedRanges.forEach((range) => {
        range.eachColumn((columnIndex) => {
          columnIndexes.push(columnIndex);
        });
      });
      return [...new Set(columnIndexes)];
    }
    return [];
  }

  /**
   * Get fully selected row indexes (no other single cells are selected)
   * @returns
   */
  public getSelectedRowIndexes(): number[] {
    const selectedRows = this.selectedRows();
    if (selectedRows.length === this.selectedRanges.length) {
      const rowIndexes = [];
      this.selectedRanges.forEach((range) => {
        range.eachRow((rowIndex) => {
          rowIndexes.push(rowIndex);
        });
      });
      return [...new Set(rowIndexes)];
    }
    return [];
  }

  public getValidRowsToDelete(): number[] {
    if (this.selectedRanges.length === 1 && this.selectedRanges[0].singular()) {
      return [this.selectedRanges[0].startRow];
    }
    return this.getSelectedRowIndexes();
  }

  public getValidColumnsToDelete(): number[] {
    if (this.selectedRanges.length === 1 && this.selectedRanges[0].singular()) {
      return [this.selectedRanges[0].startColumn];
    }
    return this.getSelectedColumnIndexes();
  }

  private getIntersectedAndOtherRanges(
    range: TableRange,
    ranges: TableRange[],
  ): {
    intersectedRange?: TableRange;
    otherRanges: TableRange[];
  } {
    const otherRanges = [];
    let intersectedRange;
    ranges.forEach((r) => {
      if (range.within(r)) {
        intersectedRange = r;
      } else {
        otherRanges.push(r);
      }
    });
    return { intersectedRange, otherRanges };
  }

  private mergeRange(range: TableRange, ranges: TableRange[]) {
    const mergedRanges = [];
    let rangeWasMerged = false;
    for (let i = 0; i < ranges.length; i++) {
      const r = ranges[i];
      if (r.touches(range) && !rangeWasMerged) {
        mergedRanges.push(r.union(range));
        rangeWasMerged = true;
      } else {
        mergedRanges.push(r);
      }
    }
    if (!rangeWasMerged) mergedRanges.push(range);
    return mergedRanges;
  }

  public unionRangeWithSelectedRanges(range: TableRange) {
    const lastSelectedStartRange: TableRange = this.getLastSelectedStartRange();
    if (lastSelectedStartRange) {
      const unionRange = lastSelectedStartRange.union(range);
      this.setRange(unionRange);
    } else {
      this.setRange(range);
    }
  }

  public getLastSelectedStartRange(): TableRange {
    const lastSelectedRange: TableRange =
      this.selectedRanges.length > 0 ? this.selectedRanges[this.selectedRanges.length - 1] : null;
    if (!lastSelectedRange) null;
    return lastSelectedRange.start();
  }

  public getSelectedRangesUnion(): TableRange {
    let union: TableRange;
    this.selectedRanges.forEach((range) => {
      if (!union) {
        union = range;
      } else {
        union = union.union(range);
      }
    });
    return union;
  }

  /**
   * Set selected range on drag to be union range of
   * @startingRange and @range
   * @param startingRange
   * @param range
   */
  public handleSelectOnDrag(startingRange: TableRange, range: TableRange) {
    const unionRange = startingRange.union(range);
    console.log('TableSelectState.handleSelectOnDrag', unionRange);
    this.setRange(unionRange);
  }

  public isOnlyCellSelected(cell: TableRange) {
    return this.selectedRanges.length === 1 && this.selectedRanges[0].singular() && cell.equals(this.selectedRanges[0]);
  }

  /**
   * Select cells on click.
   * Handle selection with shiftKey and metaKey.
   * @param elementTarget
   * @param options
   * @returns
   */
  public handleSelectOnClick(
    elementTarget: ElementMouseTarget,
    options: { shiftKey?: boolean; ctrlKey?: boolean } = { shiftKey: false, ctrlKey: false },
  ) {
    let startRow, endRow, startColumn, endColumn;
    if (elementTarget.target === DRAG_DIRECTIONS.DRAG_COLUMN && elementTarget?.index?.x >= 0) {
      startRow = 0;
      endRow = this.table.rows.length - 1;
      startColumn = elementTarget.index.x;
      endColumn = elementTarget.index.x;
    } else if (elementTarget.target === DRAG_DIRECTIONS.DRAG_ROW && elementTarget?.index?.y >= 0) {
      startRow = elementTarget.index.y;
      endRow = elementTarget.index.y;
      startColumn = 0;
      endColumn = this.table.columns.length - 1;
    } else if (
      elementTarget.target === DRAG_DIRECTIONS.CELL &&
      elementTarget?.index?.x >= 0 &&
      elementTarget?.index?.y >= 0
    ) {
      startRow = elementTarget.index.y;
      endRow = elementTarget.index.y;
      startColumn = elementTarget.index.x;
      endColumn = elementTarget.index.x;
    }
    if (startRow == null || endRow == null || startColumn == null || endColumn == null) return;
    const range = new TableRange(startRow, endRow, startColumn, endColumn);
    if (options.ctrlKey) {
      if (elementTarget.target === DRAG_DIRECTIONS.DRAG_COLUMN) {
        this.addRange(range, this.selectedColumns());
      } else if (elementTarget.target === DRAG_DIRECTIONS.DRAG_ROW) {
        this.addRange(range, this.selectedRows());
      } else {
        this.addRange(range, this.selectedRanges);
      }
    } else if (options.shiftKey) {
      this.unionRangeWithSelectedRanges(range);
    } else {
      this.setRange(range);
    }
  }
}
