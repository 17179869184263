import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { DocumentCreateItemsService } from '../document-create-items.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { CounterWidgetComponent } from '@components/counter-widget/counter-widget.component';

@Component({
  selector: 'app-create-typed-items',
  templateUrl: './create-typed-items.component.html',
  styleUrls: ['./create-typed-items.component.scss'],
})
export class DocumentCreateTypedItemsComponent implements OnInit {
  @ViewChild('menuTrigger') trigger: MatMenuTrigger;
  @ViewChild('counter') counter: CounterWidgetComponent;
  @Output() closeTriggerMenu = new EventEmitter<void>();
  types: any[];
  rootId: string;
  itemCount = 1;

  constructor(private documentCreateItemsService: DocumentCreateItemsService) {}

  ngOnInit() {}

  createTypedItem(typeId: string) {
    this.closeTriggerMenu.emit();
    this.documentCreateItemsService.createTypedItem({ typeId, itemCount: this.itemCount });
    this.itemCount = 1;
    this.counter.resetValue();
  }

  openMenu(menuTrigger: MatMenuTrigger) {
    if (this.itemCount) {
      menuTrigger.openMenu();
    }
  }

  preventClose(event: Event) {
    event.stopPropagation();
  }

  updateCount(count: number) {
    this.itemCount = count;
  }
}
