import { Injectable } from '@angular/core';
import { ObjectUtil } from '@contrail/util';
import {
  Document,
  DocumentAction,
  DocumentChangeType,
  DocumentElement,
  DocumentElementPropertyBindingHandler,
  PositionDefinition,
  SizeDefinition,
  StyleDefinition,
} from '@contrail/documents';
import { DocumentService } from '../document.service';
import { Content, Entities, EntityReference } from '@contrail/sdk';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { AssortmentsActions, AssortmentsSelectors } from '@common/assortments/assortments-store';
import { take, map } from 'rxjs/operators';
import { ColorComponentBuilder } from './color-component-builder';
import { setLoading } from 'src/app/common/loading-indicator/loading-indicator-store/loading-indicator.actions';
import { DocumentItemService } from '../document-item/document-item.service';
import { Item } from '@common/items/item';
import { ProjectItem, ProjectItemService } from '@common/projects/project-item.service';
import { IContent } from '@common/content/content-holder-details/content-holder-details.component';
import { AssortmentItem } from '@common/assortments/assortment-item';
import { AssortmentUtil } from '@common/assortments/assortment-util';
import { ItemService } from '@common/items/item.service';
import { ItemData } from '@common/item-data/item-data';
import { SVGHelper } from '../../canvas/svg-helper';
import { AuthService } from '@common/auth/auth.service';
import { nanoid } from 'nanoid';
import { OrgMembership } from '@common/auth/auth.service';
import { AuthSelectors } from '@common/auth/auth-store';
import { GetContentPolicies } from '@common/content/content-policy-helper';
import { DocumentManagerSelectors } from '../../document-manager/document-manager-store';
import { FeatureFlagsSelectors } from '@common/feature-flags';
import { Feature, FeatureFlag } from '@common/feature-flags/feature-flag';
import { Color } from '@common/color/color.service';
import { DocumentActions } from '../document-store';
import { ContextualEntityHelper } from '../contextual-entity-helper';
import { TypeManagerService } from '@common/types/type-manager.service';

export interface DocumentComponentModelBinding {
  id: string; // document element id
  item?: Item;
  projectItem?: ProjectItem;
  viewable?: Item;
  content?: IContent[];
  assortmentItem?: AssortmentItem;
  color?: Color;
}

@Injectable({
  providedIn: 'root',
})
export class DocumentComponentService {
  private lastComponentDocumentElement: DocumentElement;
  private currentOrg: OrgMembership;
  private documentElements: DocumentElement[];
  private itemContextFeatureActive = false;

  constructor(
    private store: Store<RootStoreState.State>,
    private documentService: DocumentService,
    private authService: AuthService,
    private projectItemService: ProjectItemService,
    private documentItemService: DocumentItemService,
    private itemService: ItemService,
    private contextualEntityHelper: ContextualEntityHelper,
    private typeManagerService: TypeManagerService,
  ) {
    this.store.select(AuthSelectors.selectAuthContext).subscribe((authContext) => {
      this.currentOrg = authContext?.currentOrg;
    });
    this.store.select(DocumentManagerSelectors.selectDocumentElements).subscribe((documentElements) => {
      this.documentElements = documentElements;
    });
    this.store.select(FeatureFlagsSelectors.featureFlags).subscribe((flags: FeatureFlag[]) => {
      if (flags.map((x) => x.featureName).includes(Feature.ITEM_CONTEXT)) {
        this.itemContextFeatureActive = true;
      }
    });
  }

  /**
   * Add @items to the document and place them such that they are stacked into columns
   * and fit horizontally into @documentHeight and vertically into @documentWidth starting at @initialPosition
   * @param items
   * @param documentWidth
   * @param documentHeight
   * @param initialPosition
   */
  async addItemsToDocument(
    items: any,
    documentWidth: number,
    documentHeight: number,
    initialPosition: PositionDefinition,
  ) {
    if (!items.length) {
      return;
    }
    if (this.itemContextFeatureActive) {
      //Fetch project items for items that could be in the project.
      const itemsWithoutProject = items.filter((item) => !item.projectItem?.id && item.entityType === 'item');
      if (itemsWithoutProject.length > 0) {
        const itemIds = itemsWithoutProject.map((item) => item.id);
        const projectItems = await this.projectItemService.getCurrentProjectItems(itemIds);
        items = items.map((item) => {
          if (item.entityType === 'item') {
            const projectItem = projectItems.find((projectItem) => projectItem.itemId === item.id);
            if (projectItem && !projectItem.isInactive) {
              item.projectItem = projectItem;
            }
          }
          return item;
        });
      }
    }

    const actions = [];
    const component = await this.createComponentElement(
      items[0],
      {
        position: { x: 0, y: 0 },
      },
      true,
    );
    const elementSize = {
      width: 20 + component.elements[0].size.width,
      height: component.elements.reduce((height, element) => height + element.size.height, 12),
    };
    const columns = Math.max(1, Math.floor(documentWidth / elementSize.width));
    const rows = Math.max(
      1,
      documentHeight ? Math.floor(documentHeight / elementSize.height) : Math.ceil(items.length / columns),
    );
    const defaultSpaceBetwen = 5;
    const ySpaceBetween = documentHeight
      ? rows === 1
        ? 0
        : (documentHeight - elementSize.height * rows) / (rows - 1)
      : defaultSpaceBetwen;
    let xSpaceBetween;
    for await (const row of [...Array(rows).keys()]) {
      const elements = items.slice(row * columns, columns + row * columns);
      if (xSpaceBetween == undefined && columns - elements.length <= 2) {
        xSpaceBetween = (documentWidth - elementSize.width * elements.length) / Math.max(1, elements.length - 1);
      } else if (xSpaceBetween == undefined) {
        xSpaceBetween = defaultSpaceBetwen;
      }

      for await (const [index, item] of elements.entries()) {
        let position = Object.assign({}, initialPosition);
        position.x = position.x + index * (elementSize.width + xSpaceBetween);
        position.y = position.y + (row % rows) * (elementSize.height + ySpaceBetween);

        const options: DocumentElement = {
          position: Object.assign({}, position),
        };
        const element = await this.createComponentElement(item, options, true);

        const action: DocumentAction = new DocumentAction(
          {
            elementId: element.id,
            elementData: element,
            changeType: DocumentChangeType.ADD_ELEMENT,
          },
          {
            elementId: element.id,
            elementData: element,
            changeType: DocumentChangeType.DELETE_ELEMENT,
          },
        );
        actions.push(action);
      }
    }

    this.documentService.deselectAllElements();
    this.documentService.handleDocumentActions(actions);
  }

  /**
   * Sets required entity data relative to source and adds a component element to the document.
   * @param itemData
   * @param options
   * @returns
   */
  async addItemDataAsComponentElement(itemData: ItemData | any, options): Promise<DocumentElement> {
    let entity = itemData;
    if (itemData?.entityType !== 'color') {
      if (!itemData.assortmentItem) {
        // only set relative to source for library items
        const itemDataRelativeToSource = await this.itemService.setItemDataRelativeToSource([itemData]);
        if (itemDataRelativeToSource.length > 0) {
          entity = AssortmentUtil.convertItemData(itemDataRelativeToSource[0]);
        } else {
          entity = AssortmentUtil.convertItemData(itemData);
        }
      } else {
        entity = AssortmentUtil.convertItemData(itemData);
      }
    }
    return await this.addComponentElement(entity, options);
  }

  async addComponentElement(entity: any, options?: DocumentElement): Promise<DocumentElement> {
    const elements = await this.addComponentElements([{ entity, options }]);
    return Object.values(elements)[0];
  }

  async addComponentElements(data: any[], skipUndo = false): Promise<any> {
    const actions: Array<DocumentAction> = [];
    const elements: any = {};
    for (let i = 0; i < data.length; i++) {
      const element = await this.createComponentElement(data[i]?.entity, data[i]?.options);
      elements[data[i]?.entity?.id || element.id] = element;
      const changeDefinition = {
        elementId: element.id,
        elementData: element,
        changeType: DocumentChangeType.ADD_ELEMENT,
      };
      const undoChangeDefinition = !skipUndo
        ? {
            elementId: element.id,
            elementData: element,
            changeType: DocumentChangeType.DELETE_ELEMENT,
          }
        : null;
      actions.push(new DocumentAction(changeDefinition, undoChangeDefinition));
    }
    this.documentService.deselectAllElements();
    this.documentService.handleDocumentActions(actions);
    return elements;
  }

  async createComponentElement(
    entity: any,
    options?: DocumentElement,
    skipProjectItemAssignment?: boolean,
  ): Promise<DocumentElement> {
    let element;

    if (!entity) {
      element = this.documentItemService.buildAndFormatComponentElement(
        null,
        options,
        options.elements ? options : this.lastComponentDocumentElement,
      );
    } else if (['item', 'assortment-item'].includes(entity.entityType)) {
      const clonedEntity = ObjectUtil.cloneDeep(entity);
      let elementOptions;
      if (options?.elements) {
        elementOptions = ObjectUtil.cloneDeep(options);
      }
      if (!clonedEntity.item) {
        const type = await this.typeManagerService.getTypeInfoById(clonedEntity.typeId);
        clonedEntity.type = { label: type.label };
      } else if (clonedEntity.item) {
        const type = await this.typeManagerService.getTypeInfoById(clonedEntity.item.typeId);
        clonedEntity.item.type = { label: type.label };
      }
      element = this.documentItemService.buildAndFormatComponentElement(
        clonedEntity,
        options,
        elementOptions || this.lastComponentDocumentElement,
        skipProjectItemAssignment,
      );
    } else if ('color' === entity.entityType) {
      element = await ColorComponentBuilder.buildComponent(entity, options);
    }

    return element;
  }

  public setLastComponentDocumentElement(lastComponentDocumentElement: DocumentElement) {
    this.lastComponentDocumentElement = lastComponentDocumentElement;
  }

  /** Determines the primary entity reference for an existing component */
  public derivePrimaryEntityFromElement(element): EntityReference {
    if (element?.modelBindings?.item) {
      return new EntityReference(element?.modelBindings?.item);
    }
    return null;
  }

  /** Gets an item from a component element */
  public async getItemFromComponentElement(element) {
    const entityReference = this.derivePrimaryEntityFromElement(element);
    if (!entityReference) {
      return;
    }
    let item;
    this.store
      .select(AssortmentsSelectors.selectItemFromBackingAssortment(entityReference.id))
      .pipe(
        take(1),
        map((i) => (item = i)),
      )
      .subscribe();
    return item;
  }

  public async getSameFamilyComponentElements(element: DocumentElement) {
    const item = await this.getItemFromComponentElement(element);
    if (!item) {
      return null;
    }

    const itemFamily = await this.itemService.getItemByFamily(item.itemFamilyId);
    const itemIds = [item.itemFamilyId].concat(itemFamily.options?.map((i) => i.id));
    const sameFamilyComponentElements = this.documentElements?.filter(
      (element) =>
        itemIds.findIndex(
          (id) => DocumentItemService.isItemComponet(element) && element.modelBindings.item === `item:${id}`,
        ) !== -1,
    );
    return sameFamilyComponentElements;
  }

  public async getColorFromComponentElement(element) {
    if (element?.modelBindings?.color) {
      const entityReference = new EntityReference(element?.modelBindings?.color);
      if (!entityReference) {
        return;
      }
      return await new Entities().get({ entityName: 'color', id: entityReference.id });
    }
    return null;
  }

  /**
   * Assign image from @imgElement to component @selectedElement
   * @param imgElement
   * @param selectedElement
   * @param item
   * @returns
   */
  public async assignImage(imgElement: DocumentElement, selectedElement: DocumentElement, item: any) {
    this.store.dispatch(setLoading({ loading: true, message: 'Please wait...' }));
    let content;
    if (imgElement.modelBindings.image) {
      // This will create a new Content for image or svg file (even if this file has already been assigned as Content)
      const fileReference = new EntityReference(imgElement.modelBindings.image);
      const entityPolicyIds = await GetContentPolicies(item, this.currentOrg);

      content = await new Content().create({
        fileId: fileReference.id,
        contentHolderReference: `item:${item.id}`,
        entityPolicyIds,
      });
    } else if (imgElement.modelBindings.content) {
      // Get existing content and link it with the item by creating ContentHolderContent
      const contentReference = new EntityReference(imgElement.modelBindings.content);
      content = await new Entities().get({
        entityName: 'content',
        id: contentReference.id,
      });
      await new Entities().create({
        entityName: 'content-holder-content',
        object: {
          contentHolderReference: `item:${item.id}`,
          contentId: content.id,
        },
      });
    } else {
      console.log('Invalid element, cannot get file reference: ', imgElement);
      this.store.dispatch(setLoading({ loading: false, message: '' }));
      return;
    }
    await new Entities().update({
      entityName: 'item',
      id: item.id,
      object: {
        primaryViewableId: content.id,
        largeViewableDownloadUrl: content.primaryFileUrl,
        mediumLargeViewableDownloadUrl: content.primaryFileUrl,
        mediumViewableDownloadUrl: content.primaryFileUrl,
        smallViewableDownloadUrl: content.primaryFileUrl,
        tinyViewableDownloadUrl: content.primaryFileUrl,
        primaryFileUrl: content.primaryFileUrl,
        contentType: content.contentType,
        fileName: content.fileName,
      },
    });
    await this.updateImageContentInComponent(selectedElement, content, item);
    this.store.dispatch(setLoading({ loading: false, message: '' }));
  }

  public static hasSVGViewable(element: DocumentElement) {
    return (
      SVGHelper.isSvg(element) ||
      (DocumentItemService.isItemComponet(element) &&
        element?.elements?.find((e) => e.type === 'image')?.alternateUrls?.originalFile?.indexOf('.svg') > -1)
    );
  }

  /**
   * For each svg element in @elements take it's svg html, recolor
   * fill attributes with @hexColorCode and create a new File entity
   * so original File doesn't get overwritten.
   * @param elements
   * @param hexColorCode
   */
  public async recolorAndUpdateSVGElements(elements: DocumentElement[], hexColorCode) {
    const actions = [];
    const uploadPromises = [];
    this.store.dispatch(setLoading({ loading: true, message: 'Recoloring SVG...' }));
    for (let element of elements) {
      const undoElement = ObjectUtil.cloneDeep(element);
      const result = await this.recolorAndUpdateSVGElement(element, hexColorCode);
      if (result?.element) {
        actions.push(
          new DocumentAction(
            {
              changeType: DocumentChangeType.MODIFY_ELEMENT,
              elementData: result.element,
              elementId: element.id,
            },
            {
              changeType: DocumentChangeType.MODIFY_ELEMENT,
              elementId: undoElement.id,
              elementData: undoElement,
            },
          ),
        );
        if (result?.updateModelBindingPromise) {
          uploadPromises.push(result?.updateModelBindingPromise);
        }

        if (result?.element?.modelBindings?.item) {
          const itemId = element.modelBindings.item.split(':')[1];
          const imageElement = element.elements.find((e) => e.type === 'image');
          const originalFile = imageElement.alternateUrls.originalFile;
          const highResolution = imageElement.alternateUrls.highResolution;
          const object = {
            largeViewableDownloadUrl: highResolution,
            mediumLargeViewableDownloadUrl: highResolution,
            mediumViewableDownloadUrl: highResolution,
            smallViewableDownloadUrl: highResolution,
            tinyViewableDownloadUrl: highResolution,
            primaryFileUrl: originalFile,
          };
          // Update backing assortment so item has correct latest properties
          this.store.dispatch(AssortmentsActions.syncBackingAssortmentItems({ id: itemId, changes: object }));
        }
      }
    }
    this.documentService.handleDocumentActions(actions);

    if (uploadPromises?.length > 0) {
      Promise.all(uploadPromises)
        .then((actions) => {
          if (actions?.length > 0) {
            // Set file url to current session
            this.documentService.applyDocumentActions(ObjectUtil.cloneDeep(actions), true);
            // Send file url to remote sessions
            this.documentService.splitActionsAndSendSessionEvent(actions);
          }
        })
        .finally(() => {});
    }
    this.store.dispatch(setLoading({ loading: false }));
  }

  public async recolorAndUpdateSVGElement(
    element: DocumentElement,
    hexColorCode,
  ): Promise<{ element: DocumentElement; updateModelBindingPromise?: Promise<any> }> {
    if (!DocumentComponentService.hasSVGViewable(element)) {
      console.error('Non svg element provided in recolor svg.');
      return;
    }

    return await this.documentService.fileHandler
      .getSVGTextFromDocumentElement(element)
      .then(async (sourceSVG) => {
        let newSvgHtmlString = await SVGHelper.recolorSVG(element.id, sourceSVG, hexColorCode);
        if (!newSvgHtmlString) {
          console.error('No svg string in recolorAndUpdateSVGElement.');
          return;
        }
        // Remove preserve aspect ratio so the svg image is not distorted in the item component
        newSvgHtmlString = newSvgHtmlString.replace(/preserveAspectRatio="(.*?)"/, '');

        let currentContentId;
        if (DocumentItemService.isItemComponet(element)) {
          const context = await this.contextualEntityHelper.getContextualEntityFromDocumentElement(element, true);
          if (!context?.viewableEntity?.id) {
            console.error('Could not get viewable entity', element);
            return;
          }
          currentContentId = context.viewableEntity.id;
        }
        return await this.documentService.fileHandler.updateContentElement(element, newSvgHtmlString, currentContentId);
      })
      .catch((error) => {
        return error;
      });
  }

  /**
   * Update image content in component @documentElement
   * @param documentElement
   * @param content
   */
  private updateImageContentInComponent(documentElement, content, item) {
    const undoElementData = ObjectUtil.cloneDeep(documentElement);
    documentElement.modelBindings.viewable = 'item:' + item.id;
    const imageElement = documentElement.elements.filter((element) => element.type === 'image')[0];
    // If a file was already a content then there is largeViewableUrl otherwise get downloadUrl
    imageElement.url = content?.mediumViewableUrl || content?.primaryFile?.fileUrl;
    imageElement.propertyBindings.url = 'viewable.mediumViewableDownloadUrl';
    const action = new DocumentAction(
      {
        changeType: DocumentChangeType.MODIFY_ELEMENT,
        elementId: documentElement.id,
        elementData: documentElement,
      },
      {
        changeType: DocumentChangeType.MODIFY_ELEMENT,
        elementId: documentElement.id,
        elementData: undoElementData,
      },
    );
    this.documentService.handleDocumentActions([action]);
  }

  /**
   * Get a list of entities from API except for @exceptForEntities
   * @param documentElements
   * @param exceptForEntities
   * @returns
   */
  public async fetchEntitiesExceptFor(documentElements: DocumentElement[], exceptForEntities: any[]): Promise<any[]> {
    const entityTypeIdsMap = new Map(this.getEntityTypeIdsMap(documentElements)); // group ids by type for bulk query of objects
    // Do not fetch entities from API if they were already fetched before
    Array.from(entityTypeIdsMap.keys()).forEach((type) => {
      const filteredIds = entityTypeIdsMap.get(type).filter((id) => {
        return !exceptForEntities.find((e) => e.id === id);
      });
      entityTypeIdsMap.set(type, filteredIds || []);
    });
    return await this.fetchEntities(entityTypeIdsMap);
  }

  /**
   * Get a list of entities from API
   * @param entityTypeIdsMap
   * @returns
   */
  private async fetchEntities(entityTypeIdsMap: Map<string, string[]>): Promise<any[]> {
    const promises = [];
    const entities = [];
    Array.from(entityTypeIdsMap.keys()).forEach((type) => {
      let ids = entityTypeIdsMap.get(type);
      ids = [...new Set(ids)]; // make unique array
      if (ids.length > 0) {
        const relations = [];
        if (type === 'item') {
          relations.push('content');
          relations.push('itemFamily');
        } else if (type === 'project-item') {
          relations.push('project');
        } else if (type === 'assortment-item') {
          relations.push('assortment');
          relations.push('projectItem');
        }
        promises.push(
          this.getFromAPI(type, ids, relations).then(async (results) => {
            for (let entity of results) {
              if (entity.entityType === 'item') {
                const type = await this.typeManagerService.getTypeInfoById(entity.typeId);
                entity.type = { label: type.label }; //currently only label is needed
              }
            }
            entities.push(...results);
          }),
        );
      }
    });
    if (promises.length > 0) {
      await Promise.all(promises);
    }
    return entities;
  }

  /**
   * Get a map of entity types and ids that are used in @documentElements
   * @param documentElements
   * @returns
   */
  public getEntityTypeIdsMap(documentElements: Array<DocumentElement>): Map<string, string[]> {
    const ids: Map<string, string[]> = new Map(); // entity type: entity ids[], for ex. { item: [123, 456, 789], projectItem: [111] }
    documentElements.forEach((element) => {
      Object.keys(element.modelBindings).forEach((type) => {
        // Legacy safe-gaurd. Previously could have a modelBinding with a 'thumbnail' that was pointing at a URL vs an entityReference.
        if (type === 'thumbnail' || !element.modelBindings[type]) {
          return;
        }

        const entityReference = new EntityReference(element.modelBindings[type]);
        if (entityReference) {
          const entityType = entityReference.entityType;
          ids.set(entityType, [...new Set([...(ids.get(entityType) || []), entityReference.id])]); // push id and make unique array
        }
      });
    });
    return ids;
  }

  /**
   * Fetches entities in bulk from the API.
   * @param entityType
   * @param ids
   * @param relations
   * @returns
   */
  private async getFromAPI(entityType, ids, relations) {
    const entities = await new Entities().get({ entityName: entityType, criteria: { ids }, relations });
    return entities;
  }

  /**
   * Updates component @documentElements based on changes in the binding @entities , e.g. item
   * @param document: document object that the updated component elements belong to
   * @param documentElements:  Array of Document Elements (component elements) that will be updated
   * @param entities: Objects that are use to bind properties on the component element
   * @param changeDefinitions: Array of objects that define the changes to be made to the entities
   */
  async updateValuesForComponentElements(
    document: Document,
    documentElements: DocumentElement[],
    entities: any[],
    changeDefinitions?: any[],
    propertiesToApply?: any[],
  ) {
    if (documentElements.length > 0) {
      const entitiesMap = await this.buildModelBindingsMapForElements(documentElements, ObjectUtil.cloneDeep(entities));
      let promises = [];
      for (const documentElement of documentElements) {
        const entityModel = entitiesMap.get(documentElement.id);
        const promise = this.createUpdateDocumentActionForComponentUpdate(
          documentElement,
          propertiesToApply || documentElement.elements,
          entityModel,
        );
        promises.push(promise);
      }

      if (changeDefinitions?.length > 0) {
        const updateEntityActions = this.createUpdateEntityActions(changeDefinitions, documentElements.length);
        promises = promises.concat(ObjectUtil.cloneDeep(updateEntityActions));
        this.store.dispatch(
          DocumentActions.batchApplyDocumentModelEntityChanges({ changes: updateEntityActions, skipSave: true }),
        );
      }

      const actions: Array<DocumentAction> = await Promise.all(promises);
      // document is needed for showcase because each frame has its own document
      this.documentService.handleDocumentActions(actions, document);
    }
  }

  /**
   * Builds a modelBinding map for each of the passed in document elements using
   * the array of previously fetched entities.
   * @param componentDocumentElements: elements to build modelBindings for
   * @param entities: All entities needed to build the mapping (previously fetched)
   * @return :  An array of maps (objects) that included an id that matches the documentElements id
   */
  async buildModelBindingsMapForElements(
    documentElements: Array<DocumentElement>,
    entities: Array<any>,
    formatData = true,
  ): Promise<Map<string, DocumentComponentModelBinding>> {
    const processedObjects: Map<string, DocumentComponentModelBinding> = new Map();
    const entitiesToFormat = [];
    documentElements.forEach((element) => {
      Object.keys(element.modelBindings).forEach((modelBindingKey) => {
        // Legacy safe gaurding.
        if (modelBindingKey === 'thumbnail' || !element.modelBindings[modelBindingKey]) {
          return;
        }
        const entityReference = new EntityReference(element.modelBindings[modelBindingKey]);
        const entity = entities.find((obj) => obj.id === entityReference.id);

        const object = processedObjects.get(element.id) || { content: [], id: element.id };
        object[modelBindingKey] = entity;
        if (entity?.content) {
          object.content = entity.content;
        }

        processedObjects.set(element.id, {
          ...object,
        });

        if (entity?.typeId && entitiesToFormat.findIndex((e) => e.id === entity.id) === -1) {
          entitiesToFormat.push(entity);
        }
      });
    });
    if (formatData) {
      for (let i = 0; i < entitiesToFormat.length; i++) {
        await this.documentItemService.formatData(entitiesToFormat[i]);
      }
    }

    return processedObjects;
  }

  /** Builds a DocumentAction to reflect the changes needed for a single component entity.
   * The code will update the set of properties on the component, and bind their values based
   * on the passed in entity model.
   * @param componentToModify:  Component document element to create an update action for
   * @param propertyElementsToApply:  A set of 'property' document elements to add/update in the component.
   * @param entityModel:  The loaded entities required to bind the model for the updated component.
   * @param style:  Style information to apply to the updated component
   * @param size:  Size information to apply to the updated component
   */
  public async createUpdateDocumentActionForComponentUpdate(
    componentToModify: DocumentElement,
    propertyElementsToApply,
    entityModel: any,
    size?: SizeDefinition,
    style?: StyleDefinition,
    bindingChanges?: any,
    skipUndo = false,
  ) {
    console.log('createUpdateDocumentActionForComponentUpdate: componentToModify: ', componentToModify);
    console.log('createUpdateDocumentActionForComponentUpdate: propertyElementsToApply: ', propertyElementsToApply);
    console.log('createUpdateDocumentActionForComponentUpdate: entityModel: ', entityModel);
    // The type is required to bind the type label to the component for "Item Type" property
    await this.setTypeIfNeeded(entityModel, propertyElementsToApply);
    const undoChangeElement = ObjectUtil.cloneDeep(componentToModify);
    const clonedPropertyElements = ObjectUtil.cloneDeep(propertyElementsToApply);
    if (bindingChanges) {
      componentToModify.modelBindings = bindingChanges;
    }

    /** Special handling if we have the viewable set:
     * Item and content have different properties for their viewable download.
     */
    const imageElement = clonedPropertyElements.find((el) => el.type === 'image');
    let legacyImageUrl;
    console.log('INFO: imageElement: ', imageElement);
    console.log('INFO: componentToModify.modelBindings: ', componentToModify.modelBindings);
    if (imageElement) {
      if (componentToModify.modelBindings?.viewable?.indexOf('content') > -1) {
        imageElement.propertyBindings = { url: 'viewable.mediumViewableUrl' };
      }
      if (componentToModify.modelBindings?.viewable?.indexOf('item') > -1) {
        imageElement.propertyBindings = { url: 'viewable.mediumViewableDownloadUrl' };
      }
      if (!componentToModify.modelBindings?.viewable && imageElement.url?.length) {
        const existingImageElement = componentToModify.elements.find((el) => el.type === 'image');
        if (existingImageElement) {
          legacyImageUrl = existingImageElement.url;
        }
      }
    }

    DocumentElementPropertyBindingHandler.bindPropertiesToElement(componentToModify, { ...entityModel });
    DocumentElementPropertyBindingHandler.bindPropertiesToElements(clonedPropertyElements, { ...entityModel });
    componentToModify.elements = clonedPropertyElements;

    // Handle legacy image URL:
    if (legacyImageUrl) {
      console.log('INFO: restoring legacy url');
      imageElement.url = legacyImageUrl;
    }

    if (style) {
      if (!componentToModify.style) {
        componentToModify.style = {};
      }
      Object.assign(componentToModify.style, style); // only copy the style
    } else {
      componentToModify.style = {};
    }
    if (size) {
      if (!componentToModify.size) {
        componentToModify.size = {};
      }
      Object.assign(componentToModify.size, size); // only copy the size
    }

    let newElements;
    if (entityModel?.item) {
      newElements = this.documentService.updateSizeAndPositionForPropertyElements(
        clonedPropertyElements,
        componentToModify,
      );
    } else if (entityModel?.color) {
      newElements = this.documentService.updateSizeAndPositionForColorElements(clonedPropertyElements, null);
    }

    const changeType = bindingChanges ? DocumentChangeType.REBIND_MODEL : DocumentChangeType.MODIFY_ELEMENT;
    componentToModify.elements = newElements;
    const changeAction = {
      changeType,
      elementId: componentToModify.id,
      elementData: componentToModify,
    };
    const undoChangeAction = !skipUndo
      ? {
          changeType,
          elementId: componentToModify.id,
          elementData: undoChangeElement,
        }
      : null;
    const action = new DocumentAction(changeAction, undoChangeAction);
    console.log('createUpdateDocumentActionForComponentUpdate: action: ', action);
    return action;
  }

  private async setTypeIfNeeded(entityModel: any, propertyElementsToApply: any) {
    if (
      entityModel.item &&
      !entityModel.item.type &&
      entityModel.item.typeId &&
      propertyElementsToApply.find((el) => el.propertyBindings?.text === 'item.type.label')
    ) {
      const type = await this.typeManagerService.getTypeInfoById(entityModel.item.typeId);
      entityModel.item.type = { label: type.label };
    }
  }

  /**
   *
   * @param changeDefinitions
   * @param componentElementCount used to show the number of component elements that were updated
   * @returns
   */
  private createUpdateEntityActions(changeDefinitions: any[], componentElementCount?: number): DocumentAction[] {
    const actions = [];
    changeDefinitions.forEach((changeDefinition) => {
      const action = new DocumentAction(
        {
          changeType: 'MODIFY_ENTITY',
          entityId: changeDefinition.id,
          entityData: changeDefinition.changes,
          entityType: changeDefinition.entityType,
          componentElementCount,
          updateEntityId: changeDefinition.updateEntityId,
        },
        {
          changeType: 'MODIFY_ENTITY',
          entityId: changeDefinition.id,
          entityData: changeDefinition.undoChanges,
          entityType: changeDefinition.entityType,
          componentElementCount,
          updateEntityId: changeDefinition.updateEntityId,
        },
      );
      actions.push(action);
    });
    return actions;
  }

  async createNewItemOption(itemFamily, data) {
    this.store.dispatch(setLoading({ loading: true, message: 'Creating new item.' }));
    const itemOption = await this.documentItemService.createEmptyItemOption(itemFamily, data);
    this.store.dispatch(setLoading({ loading: false }));
    return itemOption;
  }

  async promoteItems(elements = []) {
    const selectedElements = ObjectUtil.cloneDeep(elements);
    if (selectedElements.length > 0) {
      // create itemFamily based on selected image or svg elements
      const elements = selectedElements.filter((element) => ['image', 'svg'].includes(element.type));
      if (elements.length > 0) {
        await this.createItemAndAssignImage(elements);
      }
    } else {
      // create an empty itemFamily
      const item = await this.documentItemService.createEmptyItemFamily();
      await this.addComponentElement(item);
    }
  }

  private async createItemAndAssignImage(elements: any) {
    const itemImageMap = {};
    const itemMap = {};
    const entities: any[] = [];
    for (let i = 0; i < elements.length; i++) {
      const item = await this.documentItemService.createEmptyItemFamily();
      const position = Object.assign({}, elements[i].position);
      position.x = position.x + 100; // place item adjacent to the original image
      position.y = position.y + 100;
      const options: DocumentElement = {
        position: Object.assign({}, position),
      };
      entities.push({ entity: item, options });
      itemImageMap[item.id] = ObjectUtil.cloneDeep(elements[i]);
      itemMap[item.id] = item;
    }
    const newElements = await this.addComponentElements(entities);
    // assign images to created component elements
    for (const itemId in newElements) {
      await this.assignImage(itemImageMap[itemId], newElements[itemId], itemMap[itemId]);
    }
  }

  async copyItem(element: DocumentElement, keepMode: boolean = false) {
    this.store.dispatch(setLoading({ loading: true, message: 'Creating item copy.' }));

    const copiedElement = await this.documentItemService.copyDocumentItem(element);
    if (!copiedElement) {
      this.handleCopyItemComplete(keepMode);
      return;
    }

    const action = new DocumentAction(
      {
        elementId: copiedElement.id,
        elementData: copiedElement,
        changeType: DocumentChangeType.ADD_ELEMENT,
      },
      {
        elementId: copiedElement.id,
        elementData: copiedElement,
        changeType: DocumentChangeType.DELETE_ELEMENT,
      },
    );

    this.documentService.deselectAllElements();
    this.documentService.handleDocumentActions([action]);

    this.handleCopyItemComplete(keepMode);
  }

  private handleCopyItemComplete(keepMode: boolean) {
    this.store.dispatch(setLoading({ loading: false }));
    if (keepMode) {
      this.documentService.setInteractionMode('item_copy');
    }
  }
}
