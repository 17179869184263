import {
  DocumentElement,
  DocumentElementFactory,
  PositionDefinition,
  SizeDefinition,
  StyleDefinition,
} from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { nanoid } from 'nanoid';
import { OrderUtil } from '../../../../util/OrderUtil';
import {
  TABLE_ROW_MIN_HEIGHT,
  TABLE_CELL_HEIGHT,
  TABLE_CELL_WIDTH,
  TRANSPARENT_COLOR,
  TABLE_TEXT_PADDING,
} from '../../../constants';
import {
  DEFAULT_TEXT_FONT_FAMILY,
  DEFAULT_TEXT_FONT_SIZE,
  TEXT_ALIGN,
  TEXT_VALIGN,
} from '../../text/editor/text-editor';

export class TableService {
  constructor() {}

  public static DEFAULT_CELL_STYLE: StyleDefinition = {
    font: {
      size: DEFAULT_TEXT_FONT_SIZE,
      family: DEFAULT_TEXT_FONT_FAMILY,
    },
    text: {
      align: TEXT_ALIGN,
      valign: TEXT_VALIGN,
    },
    backgroundColor: TRANSPARENT_COLOR,
  };

  /**
   * Create document table and its child elements with empty cells.
   * @param position
   * @param rows
   * @param columns
   * @returns
   */
  public static createEmptyTableElement(position: PositionDefinition, rows = 4, columns = 3): Array<DocumentElement> {
    const rowElements: Array<DocumentElement> = [];
    const rowIds: Array<string> = [];
    const columnElements: Array<DocumentElement> = [];
    const columnIds: Array<string> = [];
    const cellElements: Array<DocumentElement> = [];
    const tableId = nanoid(16);

    for (let i = 0; i < rows; i++) {
      const rowId = nanoid(16);
      const row = {
        id: rowId,
        tableId,
        type: 'row',
        size: {
          width: 1,
          height: TABLE_CELL_HEIGHT,
        },
        style: {
          backgroundColor: TRANSPARENT_COLOR,
        },
        position: { x: 0, y: 0 },
      };
      rowIds.push(rowId);
      rowElements.push(row);
    }

    for (let j = 0; j < columns; j++) {
      const columnId = nanoid(16);
      const column = {
        id: columnId,
        tableId,
        type: 'column',
        size: {
          width: TABLE_CELL_WIDTH,
          height: 1,
        },
        style: {
          backgroundColor: TRANSPARENT_COLOR,
        },
        position: { x: 0, y: 0 },
      };
      columnIds.push(columnId);
      columnElements.push(column);
    }

    for (let i = 0; i < rowIds.length; i++) {
      for (let j = 0; j < columnIds.length; j++) {
        const cell: DocumentElement = {
          id: nanoid(16),
          type: 'cell',
          rowId: rowIds[i],
          columnId: columnIds[j],
          tableId,
          text: '',
          size: {
            width: TABLE_CELL_WIDTH,
            height: TABLE_ROW_MIN_HEIGHT,
          },
          style: {
            backgroundColor: TRANSPARENT_COLOR,
          },
          position: { x: 0, y: 0 },
        };
        cellElements.push(cell);
      }
    }

    const tableElement: DocumentElement = {
      type: 'table',
      id: tableId,
      position: {
        x: position.x - TABLE_CELL_WIDTH * 0.5,
        y: position.y - TABLE_CELL_HEIGHT * 0.5,
      },
      size: {
        width: columns * TABLE_CELL_WIDTH,
        height: rows * TABLE_CELL_HEIGHT,
      },
      style: {
        backgroundColor: TRANSPARENT_COLOR,
        border: {
          width: 1,
          color: '#616161',
        },
      },
      rowIds,
      columnIds,
    };

    return [tableElement, ...rowElements, ...columnElements, ...cellElements];
  }

  /**
   * Adds new column and empty cell elements at @index
   * Sets width to @optionalWidth or width of previous column
   * Applies @style of previous column cells
   * @param tableElement - modifies element's size and columnIds
   * @params childElements - used to get style of cells at previous column
   * @param index
   * @param optionalWidth
   * @returns new column and cell elements
   */
  public static addColumn(
    tableElement: DocumentElement,
    childElements: DocumentElement[],
    index,
    optionalWidth?: number,
  ): DocumentElement[] {
    const prevColumnId = tableElement.columnIds[Math.max(0, index - 1)];
    const prevColumnCells: DocumentElement[] = TableService.getCellsAtColumn(prevColumnId, tableElement, childElements);
    const width = optionalWidth != null ? optionalWidth : childElements.find((e) => e.id === prevColumnId).size.width;
    const columnId = nanoid(16);
    const column = {
      id: columnId,
      tableId: tableElement.id,
      type: 'column',
      size: {
        width,
        height: 1,
      },
      position: { x: 0, y: 0 },
    };
    tableElement.size.width = tableElement.size.width + width;
    tableElement.columnIds.splice(index, 0, columnId);

    const cellElements = [];
    for (let i = 0; i < tableElement.rowIds.length; i++) {
      const prevCell = prevColumnCells[i];
      const cell: DocumentElement = {
        id: nanoid(16),
        type: 'cell',
        rowId: tableElement.rowIds[i],
        columnId,
        tableId: tableElement.id,
        text: '',
        size: {
          width: width,
          height: TABLE_ROW_MIN_HEIGHT,
        },
        position: { x: 0, y: 0 },
        style: prevCell.style,
      };
      cellElements.push(cell);
    }
    return [column, ...cellElements];
  }

  /**
   * Finds cell elements at @columnId and sorts by row order
   * @param columnId
   * @param tableElement
   * @param childElements
   * @returns
   */
  public static getCellsAtColumn(
    columnId: string,
    tableElement: DocumentElement,
    childElements: DocumentElement[],
  ): DocumentElement[] {
    return childElements
      .filter((element) => element.type === 'cell' && element.columnId === columnId)
      .sort((a, b) => tableElement.rowIds.indexOf(a.rowId) - tableElement.rowIds.indexOf(b.rowId));
  }

  /**
   * Finds cell elements at @rowId and sort by column order
   * @param rowId
   * @param tableElement
   * @param childElements
   * @returns
   */
  public static getCellsAtRow(
    rowId: string,
    tableElement: DocumentElement,
    childElements: DocumentElement[],
  ): DocumentElement[] {
    return childElements
      .filter((element) => element.type === 'cell' && element.rowId === rowId)
      .sort((a, b) => tableElement.columnIds.indexOf(a.columnId) - tableElement.columnIds.indexOf(b.columnId));
  }

  /**
   * Adds new row and empty cell elements at @index
   * Sets height to @optionalHeight or height of previous row
   * Applies @style of previous row cells
   * @param tableElement - modifies it's size and rowIds
   * @param childElements - used to get style of cells at previous column
   * @param index
   * @param optionalHeight
   * @returns new row and cell elements
   */
  public static addRow(
    tableElement: DocumentElement,
    childElements: DocumentElement[],
    index,
    optionalHeight?: number,
  ): DocumentElement[] {
    const prevRowId = tableElement.rowIds[Math.max(0, index - 1)];
    const prevRowCells: DocumentElement[] = TableService.getCellsAtRow(prevRowId, tableElement, childElements);
    const height = optionalHeight != null ? optionalHeight : childElements.find((e) => e.id === prevRowId).size.height;
    const rowId = nanoid(16);
    const row = {
      id: rowId,
      tableId: tableElement.id,
      type: 'row',
      size: {
        width: 1,
        height,
      },
      position: { x: 0, y: 0 },
    };
    tableElement.size.height = tableElement.size.height + height;
    tableElement.rowIds.splice(index, 0, rowId);

    const cellElements = [];
    for (let i = 0; i < tableElement.columnIds.length; i++) {
      const prevCell = prevRowCells[i];
      const cell: DocumentElement = {
        id: nanoid(16),
        type: 'cell',
        rowId,
        columnId: tableElement.columnIds[i],
        tableId: tableElement.id,
        text: '',
        size: {
          width: prevCell.size.width,
          height: TABLE_ROW_MIN_HEIGHT,
        },
        position: { x: 0, y: 0 },
        style: prevCell.style,
      };
      cellElements.push(cell);
    }
    return [row, ...cellElements];
  }

  /**
   * Delete columns and cells at column @indexes
   * @param tableElement
   * @param childElements
   * @param indexes
   * @returns
   */
  public static deleteColumns(
    tableElement: DocumentElement,
    childElements: DocumentElement[],
    indexes: number[],
  ): DocumentElement[] {
    const idsToDelete: string[] = tableElement.columnIds.filter((columnId, index) => indexes.indexOf(index) !== -1);
    const columnsToDelete: DocumentElement[] = idsToDelete.map((columnId) =>
      childElements.find((e) => e.type === 'column' && e.id === columnId),
    );

    tableElement.columnIds = tableElement.columnIds.filter((id) => idsToDelete.indexOf(id) === -1);
    tableElement.size.width =
      tableElement.size.width - columnsToDelete.reduce((totalWidth, element) => totalWidth + element.size.width, 0);

    const cellsToDelete: DocumentElement[] = childElements.filter(
      (e) => e.type === 'cell' && idsToDelete.indexOf(e.columnId) !== -1,
    );
    return [...columnsToDelete, ...cellsToDelete];
  }

  /**
   * Delete rows and cells at row @indexes
   * @param tableElement
   * @param childElements
   * @param indexes
   * @returns
   */
  public static deleteRows(
    tableElement: DocumentElement,
    childElements: DocumentElement[],
    indexes: number[],
  ): DocumentElement[] {
    const idsToDelete: string[] = tableElement.rowIds.filter((rowId, index) => indexes.indexOf(index) !== -1);
    const rowsToDelete: DocumentElement[] = idsToDelete.map((rowId) =>
      childElements.find((e) => e.type === 'row' && e.id === rowId),
    );

    tableElement.rowIds = tableElement.rowIds.filter((id) => idsToDelete.indexOf(id) === -1);
    tableElement.size.height =
      tableElement.size.height - rowsToDelete.reduce((totalHeight, element) => totalHeight + element.size.height, 0);

    const cellsToDelete: DocumentElement[] = childElements.filter(
      (e) => e.type === 'cell' && idsToDelete.indexOf(e.rowId) !== -1,
    );
    return [...rowsToDelete, ...cellsToDelete];
  }

  /**
   * Moves id in columnIds or rowIds
   * @param key
   * @param tableElement
   * @param from
   * @param to
   * @returns
   */
  public static move(
    key: 'columnIds' | 'rowIds',
    tableElement: DocumentElement,
    from: number,
    to: number,
  ): DocumentElement {
    OrderUtil.move(tableElement[key], from, to);
    return tableElement;
  }

  /**
   * Resizes row with @rowId to @height
   * Modifies total table size
   * @param tableElement
   * @param childElements
   * @param rowId
   * @param height
   */
  public static resizeRow(
    tableElement: DocumentElement,
    childElements: DocumentElement[],
    rowId: string,
    height: number,
  ) {
    const row = childElements.find((e) => e.type === 'row' && e.id === rowId);
    row.size.height = height;
    tableElement.size.height = childElements
      .filter((e) => e.type === 'row')
      .reduce((acc, row) => acc + row.size.height, 0);
  }

  /**
   * Resizes column with @columnId to @width
   * Modifies total table size
   * @param tableElement
   * @param childElements
   * @param columnId
   * @param width
   */
  public static resizeColumn(
    tableElement: DocumentElement,
    childElements: DocumentElement[],
    columnId: string,
    width: number,
  ) {
    const column = childElements.find((e) => e.type === 'column' && e.id === columnId);
    column.size.width = width;
    tableElement.size.width = childElements
      .filter((e) => e.type === 'column')
      .reduce((acc, column) => acc + column.size.width, 0);
  }

  /**
   * Adjusts height of each cell at column with @columnId
   * Grows row height if cell height becomes long
   * @param tableElement
   * @param childElements
   * @param columnId
   * @returns
   */
  public static resizeRowCellHeight(
    tableElement: DocumentElement,
    childElements: DocumentElement[],
    columnId: string,
  ): { elements: DocumentElement[]; undoElements: DocumentElement[] } {
    const { elements, undoElements } = { elements: [], undoElements: [] }; // row elements
    const column = childElements.find((e) => e.type === 'column' && e.id === columnId);
    tableElement.rowIds.forEach((rowId) => {
      const row = childElements.find((e) => e.type === 'row' && e.id === rowId);
      const cell = childElements.find((e) => e.type === 'cell' && e.rowId === rowId && e.columnId === columnId);
      cell.size.width = column.size.width; // set cell width to equal column width
      const adjustedSize = TableService.getCellSize(cell); // get adjusted height such that cell text can fit on current width
      cell.size.height = adjustedSize.height;
      if (cell.size.height > row.size.height) {
        undoElements.push(ObjectUtil.cloneDeep(row));
        TableService.resizeRow(tableElement, childElements, rowId, cell.size.height);
        elements.push(row);
      }
    });
    return { elements, undoElements };
  }

  /**
   * Copies @tableElements and its @childElements
   * @param tableElement
   * @param childElements
   * @returns
   */
  public static copy(tableElement: DocumentElement, childElements: DocumentElement[]): DocumentElement[] {
    const copiedChildElementIds: Map<string, string> = new Map();
    const childElementsMap: Map<string, DocumentElement> = new Map();
    childElements.forEach((element) => {
      childElementsMap.set(element.id, element);
    });

    const rowIds: Array<string> = [];
    const columnIds: Array<string> = [];

    const oldTableElement = ObjectUtil.cloneDeep(tableElement);
    delete oldTableElement.id;
    const newTableElement = DocumentElementFactory.createElement('table', oldTableElement);
    newTableElement.tableId = newTableElement.id;
    const newChildElements = [];

    for (let i = 0; i < tableElement.rowIds.length; i++) {
      const oldId = tableElement.rowIds[i];
      const oldElement = childElementsMap.get(oldId);
      if (!oldElement) throw new Error('Invalid table element');
      const oldElementCopy = ObjectUtil.cloneDeep(oldElement);
      delete oldElementCopy.id;
      delete oldElementCopy.updatedOn;
      delete oldElementCopy.updatedById;
      delete oldElementCopy.createdOn;
      delete oldElementCopy.createdById;
      oldElementCopy.tableId = newTableElement.id;
      const newElement = DocumentElementFactory.createElement('row', oldElementCopy);
      copiedChildElementIds.set(oldId, newElement.id);
      rowIds.push(newElement.id);
      newChildElements.push(newElement);
    }

    for (let i = 0; i < tableElement.columnIds.length; i++) {
      const oldId = tableElement.columnIds[i];
      const oldElement = childElementsMap.get(oldId);
      if (!oldElement) throw new Error('Invalid table element');
      const oldElementCopy = ObjectUtil.cloneDeep(oldElement);
      delete oldElementCopy.id;
      delete oldElementCopy.updatedOn;
      delete oldElementCopy.updatedById;
      delete oldElementCopy.createdOn;
      delete oldElementCopy.createdById;
      oldElementCopy.tableId = newTableElement.id;
      const newElement = DocumentElementFactory.createElement('column', oldElementCopy);
      copiedChildElementIds.set(oldId, newElement.id);
      columnIds.push(newElement.id);
      newChildElements.push(newElement);
    }

    const cellElements = childElements.filter((e) => e.type === 'cell');
    if (cellElements.length < tableElement.rowIds.length * tableElement.columnIds.length) {
      for (let i = 0; i < tableElement.rowIds.length; i++) {
        for (let j = 0; j < tableElement.columnIds.length; j++) {
          const rowId = tableElement.rowIds[i];
          const columnId = tableElement.columnIds[j];
          const row = childElements.find((e) => e.id === rowId);
          const column = childElements.find((e) => e.id === columnId);
          const cellElement = cellElements.find(
            (e) => e.type === 'cell' && e.rowId === rowId && e.columnId === columnId,
          );
          if (!cellElement) {
            cellElements.push(
              DocumentElementFactory.createElement('cell', {
                type: 'cell',
                rowId,
                columnId,
                text: '',
                size: {
                  width: column.size.width,
                  height: row.size.height,
                },
                style: {
                  backgroundColor: TRANSPARENT_COLOR,
                },
                position: { x: 0, y: 0 },
              }),
            );
          }
        }
      }
    }
    for (let i = 0; i < cellElements.length; i++) {
      const oldCell = cellElements[i];
      const newRowId = copiedChildElementIds.get(oldCell.rowId);
      const newColumnId = copiedChildElementIds.get(oldCell.columnId);
      if (!newRowId || !newColumnId) throw new Error('Invalid table element');
      const oldElementCopy = ObjectUtil.cloneDeep(oldCell);
      delete oldElementCopy.id;
      delete oldElementCopy.updatedOn;
      delete oldElementCopy.updatedById;
      delete oldElementCopy.createdOn;
      delete oldElementCopy.createdById;
      oldElementCopy.tableId = newTableElement.id;
      oldElementCopy.rowId = newRowId;
      oldElementCopy.columnId = newColumnId;
      const newElement = DocumentElementFactory.createElement('cell', oldElementCopy);
      newChildElements.push(newElement);
    }

    newTableElement.rowIds = rowIds;
    newTableElement.columnIds = columnIds;

    return [newTableElement, ...newChildElements];
  }

  /**
   * Creates a new document table element structure from selected @cells
   * @param element - table element
   * @param rows
   * @param columns
   * @param cells
   * @returns new table structure with new table, row, column and cell elements
   */
  public static createTableFromCells(
    element: DocumentElement,
    rows: DocumentElement[],
    columns: DocumentElement[],
    cells: DocumentElement[],
  ): DocumentElement[] {
    const rowElements: Array<DocumentElement> = [];
    const rowIds: Map<number, string> = new Map();
    const columnElements: Array<DocumentElement> = [];
    const columnIds: Map<number, string> = new Map();
    const cellElements: Array<DocumentElement> = [];
    const existingCellsMap: Map<string, DocumentElement> = new Map();
    const tableId = nanoid(16);
    let width = 0,
      height = 0;
    let startRow, endRow, startColumn, endColumn;
    cells.forEach((cell) => {
      const rowIndex = element.rowIds.indexOf(cell.rowId);
      const columnIndex = element.columnIds.indexOf(cell.columnId);
      const isNotSet = startRow == undefined;
      if (isNotSet || rowIndex < startRow) startRow = rowIndex;
      if (isNotSet || rowIndex > endRow) endRow = rowIndex;
      if (isNotSet || columnIndex < startColumn) startColumn = columnIndex;
      if (isNotSet || columnIndex > endColumn) endColumn = columnIndex;
      existingCellsMap.set(`${rowIndex}_${columnIndex}`, cell);
    });

    // Skip rows and columns if they are not among selected cells
    const skipRows = Array.from({ length: endRow - startRow + 1 }, (value, index) => startRow + index).filter(
      (i) => !Array.from(existingCellsMap.keys()).find((v) => v.startsWith(`${i}_`)),
    );
    const skipColumns = Array.from(
      { length: endColumn - startColumn + 1 },
      (value, index) => startColumn + index,
    ).filter((j) => !Array.from(existingCellsMap.keys()).find((v) => v.endsWith(`_${j}`)));
    for (let i = startRow; i <= endRow; i++) {
      if (skipRows.indexOf(i) !== -1) continue;
      const rowId = nanoid(16);
      const row = {
        id: rowId,
        tableId,
        type: 'row',
        size: {
          width: 1,
          height: rows[i].size.height,
        },
        style: {
          backgroundColor: TRANSPARENT_COLOR,
        },
        position: { x: 0, y: 0 },
      };
      height += row.size.height;
      rowIds.set(i, rowId);
      rowElements.push(row);
    }

    for (let j = startColumn; j <= endColumn; j++) {
      if (skipColumns.indexOf(j) !== -1) continue;
      const columnId = nanoid(16);
      const column = {
        id: columnId,
        tableId,
        type: 'column',
        size: {
          width: columns[j].size.width,
          height: 1,
        },
        style: {
          backgroundColor: TRANSPARENT_COLOR,
        },
        position: { x: 0, y: 0 },
      };
      width += column.size.width;
      columnIds.set(j, columnId);
      columnElements.push(column);
    }

    for (let i = startRow; i <= endRow; i++) {
      if (skipRows.indexOf(i) !== -1) continue;
      for (let j = startColumn; j <= endColumn; j++) {
        if (skipColumns.indexOf(j) !== -1) continue;
        const cell = existingCellsMap.get(`${i}_${j}`);
        if (cell) {
          const element: DocumentElement = {
            id: nanoid(16),
            type: 'cell',
            rowId: rowIds.get(i),
            columnId: columnIds.get(j),
            tableId,
            text: cell.text,
            size: {
              width: columns[j].size.width,
              height: rows[i].size.height,
            },
            style: ObjectUtil.cloneDeep(cell.style),
            position: { x: 0, y: 0 },
          };
          cellElements.push(element);
        }
      }
    }

    const tableElement: DocumentElement = {
      type: 'table',
      id: tableId,
      position: { ...element.position },
      scale: { ...element.scale },
      size: {
        width,
        height,
      },
      style: {
        backgroundColor: TRANSPARENT_COLOR,
        border: {
          width: 1,
          color: '#616161',
        },
      },
      rowIds: Array.from(rowIds.values()),
      columnIds: Array.from(columnIds.values()),
    };
    return [tableElement, ...rowElements, ...columnElements, ...cellElements];
  }

  /**
   * Creates a new document table structure from one @cellElement
   * Used to paste it to a selected table.
   * @param cellElement
   * @returns - new table structure with new table, row, column and cell elements
   */
  public static createTableFromCell(cellElement: DocumentElement): DocumentElement[] {
    const tableId = nanoid(16);
    const rowId = nanoid(16);
    const row = {
      id: rowId,
      tableId,
      type: 'row',
      size: {
        width: 1,
        height: cellElement.size.height,
      },
      style: {
        backgroundColor: TRANSPARENT_COLOR,
      },
      position: { x: 0, y: 0 },
    };
    const columnId = nanoid(16);
    const column = {
      id: columnId,
      tableId,
      type: 'column',
      size: {
        width: cellElement.size.width,
        height: 1,
      },
      style: {
        backgroundColor: TRANSPARENT_COLOR,
      },
      position: { x: 0, y: 0 },
    };
    const cell: DocumentElement = ObjectUtil.mergeDeep(ObjectUtil.cloneDeep(cellElement), {
      id: nanoid(16),
      type: 'cell',
      rowId,
      columnId,
      tableId,
      scale: undefined,
    });

    const tableElement: DocumentElement = {
      type: 'table',
      id: tableId,
      position: { ...cellElement.position },
      scale: { ...cellElement.scale },
      size: { ...cellElement.size },
      style: {
        backgroundColor: TRANSPARENT_COLOR,
        border: {
          width: 1,
          color: '#616161',
        },
      },
      rowIds: [rowId],
      columnIds: [columnId],
    };

    return [tableElement, row, column, cell];
  }

  /**
   * Creates a new document text tool elemen from a @cellElement
   * @param cellElement
   * @returns
   */
  public static createTextFromCell(cellElement: DocumentElement): DocumentElement {
    const fontFamily = `${cellElement.style?.font?.family ?? DEFAULT_TEXT_FONT_FAMILY}`;
    const fontSize = `${cellElement.style?.font?.size ?? DEFAULT_TEXT_FONT_SIZE}pt`;
    const size = this.getSize(
      cellElement.text,
      `width: ${cellElement.size.width - TABLE_TEXT_PADDING * 2}px; height: auto; font-family: ${fontFamily}; font-size: ${fontSize};`,
    );
    const documentElement = {
      isTextTool: true,
      text: cellElement.text,
      position: { ...cellElement.position },
      scale: { ...cellElement.scale },
      size: { ...size },
      style: ObjectUtil.mergeDeep(ObjectUtil.cloneDeep(cellElement.style), {
        border: {
          width: 0,
        },
      }),
    };
    return DocumentElementFactory.createTextElement('', documentElement);
  }

  /**
   * Pastes @elementsToPaste into @tableElement at @targetRow and @targetColumn indexes
   * Adds necessary columns and rows to fit all @elementsToPaste
   * Resizes columns and rows if size of @elementsToPaste is larger
   * @param tableElement
   * @param childElements
   * @param elementsToPaste
   * @param targetRow
   * @param targetColumn
   * @returns
   */
  public static pasteTableCells(
    tableElement: DocumentElement,
    childElements: DocumentElement[],
    elementsToPaste: DocumentElement[],
    targetRow: number,
    targetColumn: number,
  ) {
    const tableToPaste = elementsToPaste.find((e) => e.type === 'table');
    const rowsToPaste = elementsToPaste.filter((e) => e.type === 'row');
    const columnsToPaste = elementsToPaste.filter((e) => e.type === 'column');
    const cellsToPaste = elementsToPaste.filter((e) => e.type === 'cell');
    const startRow = targetRow;
    const endRow = startRow + tableToPaste.rowIds.length - 1;
    const startColumn = targetColumn;
    const endColumn = startColumn + tableToPaste.columnIds.length - 1;
    const elementsToUpdate: Array<{ change: DocumentElement; undo: DocumentElement }> = [];
    const elementsToCreate: DocumentElement[] = [];
    const existingCells = childElements.filter((e) => e.type === 'cell');
    const tableElementUndo = ObjectUtil.cloneDeep(tableElement);
    for (let rowIndex = startRow; rowIndex <= endRow; rowIndex++) {
      const rowToPaste = rowsToPaste[rowIndex - targetRow];
      let existingRowId = tableElement.rowIds[rowIndex];
      let existingRow =
        childElements.find((e) => e.type === 'row' && e.id === existingRowId) ||
        elementsToCreate.find((e) => e.type === 'row' && e.id === existingRowId);
      if (!existingRowId) {
        // Add new row and row cells
        const [newRow, ...newRowCells] = TableService.addRow(
          tableElement,
          [...existingCells, ...elementsToCreate],
          rowIndex,
          rowToPaste.size.height,
        );
        elementsToCreate.push(newRow, ...newRowCells);
        existingRowId = newRow.id;
        existingRow = newRow;
      }
      if (rowToPaste.size.height > existingRow.size.height) {
        // Need to make row longer
        const existingRowUndo = ObjectUtil.cloneDeep(existingRow);
        // Update table state
        TableService.resizeRow(tableElement, childElements, existingRowId, rowToPaste.size.height);
        elementsToUpdate.push({
          change: existingRow,
          undo: existingRowUndo,
        });
      }
      for (let columnIndex = startColumn; columnIndex <= endColumn; columnIndex++) {
        const columnToPaste = columnsToPaste[columnIndex - targetColumn];
        let existingColumnId = tableElement.columnIds[columnIndex];
        let existingColumn =
          childElements.find((e) => e.type === 'column' && e.id === existingColumnId) ||
          elementsToCreate.find((e) => e.type === 'column' && e.id === existingColumnId);
        if (!existingColumnId) {
          // Add new column and column cells
          const [newColumn, ...newColumnCells] = TableService.addColumn(
            tableElement,
            [...existingCells, ...elementsToCreate],
            columnIndex,
            columnToPaste.size.width,
          );
          // tableElement.table.addColumn(columnIndex, newColumn, newColumnCells);
          elementsToCreate.push(newColumn, ...newColumnCells);
          existingColumnId = newColumn.id;
          existingColumn = newColumn;
        }
        // Need to make column wider
        if (columnToPaste.size.width > existingColumn.size.width) {
          const existingColumnCells = TableService.getCellsAtColumn(existingColumnId, tableElement, childElements);
          const existingColumnCellsUndo = existingColumnCells.map((e) => ObjectUtil.cloneDeep(e));
          const existingColumnUndo = ObjectUtil.cloneDeep(existingColumn);

          // Update table state
          TableService.resizeColumn(tableElement, childElements, existingColumnId, columnToPaste.size.width);
          TableService.resizeRowCellHeight(tableElement, childElements, existingColumnId);

          elementsToUpdate.push({
            change: existingColumn,
            undo: existingColumnUndo,
          });
          elementsToUpdate.push(
            ...existingColumnCells.map((e, i) => ({
              change: e,
              undo: existingColumnCellsUndo[i],
            })),
          );
        }
        const cellToPaste = cellsToPaste.find((e) => e.rowId === rowToPaste.id && e.columnId === columnToPaste.id);
        if (!cellToPaste) continue;

        const updatedCell = elementsToUpdate.find(
          ({ change }) =>
            change.type === 'cell' && change.rowId === existingRowId && change.columnId === existingColumnId,
        )?.change;
        const createdCell = elementsToCreate.find(
          (e) => e.type === 'cell' && e.rowId === existingRowId && e.columnId === existingColumnId,
        );

        let cell = updatedCell || createdCell;
        let cellUndo;

        if (!cell) {
          const existingCell = existingCells.find((e) => e.rowId === existingRowId && e.columnId === existingColumnId);
          cellUndo = ObjectUtil.cloneDeep(existingCell);
          cell = ObjectUtil.cloneDeep(existingCell);
        }

        // Set text and style
        cell.text = cellToPaste.text;
        cell.style = ObjectUtil.mergeDeep(
          ObjectUtil.cloneDeep(TableService.DEFAULT_CELL_STYLE),
          ObjectUtil.cloneDeep(cellToPaste.style),
        );

        // Pasted cell fits on existing column, just adjust its height
        cell.size.width = existingColumn.size.width;
        cell.size.height = this.getCellSize(cell).height;

        if (cellUndo) {
          elementsToUpdate.push({
            change: cell,
            undo: cellUndo,
          });
        }
      }
    }

    elementsToUpdate.push({
      change: tableElement,
      undo: tableElementUndo,
    });

    return {
      elementsToUpdate,
      elementsToCreate,
    };
  }

  public static clearCell(element: DocumentElement) {
    element.text = '';
    element.size.height = TABLE_ROW_MIN_HEIGHT;
  }

  public static getCellSize(element: DocumentElement, text?: string): SizeDefinition {
    const fontFamily = `${element.style?.font?.family ?? DEFAULT_TEXT_FONT_FAMILY}`;
    const fontSize = `${element.style?.font?.size ?? DEFAULT_TEXT_FONT_SIZE}pt`;
    const fixedWidth = `${element.size.width - TABLE_TEXT_PADDING * 2}px`;
    const size = this.getSize(
      text ?? element.text,
      `width: ${fixedWidth}; height: auto; font-family: ${fontFamily}; font-size: ${fontSize};`,
    );
    return { height: Math.max(size.height + TABLE_TEXT_PADDING * 2, TABLE_ROW_MIN_HEIGHT), width: size.width };
  }

  public static getSize(text: string, style: string): SizeDefinition {
    if (!document) throw new Error('No document found.');
    let div = document.createElement('div');
    div.innerHTML = text;
    div.setAttribute('class', 'text-calc');
    div.setAttribute('style', style);
    document.body.appendChild(div);
    const width = div.clientWidth;
    const height = div.clientHeight;
    document.body.removeChild(div);
    div = null;
    return { width, height };
  }
}
