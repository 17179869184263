import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { buildTypeMenu, TypeMenuItem } from './type-menu-item';
import { SubTypesHelper, TypeListEntry } from '@contrail/sdk';

@Component({
  selector: 'app-type-selector-menu',
  templateUrl: './type-selector-menu.component.html',
  styleUrls: ['./type-selector-menu.component.scss'],
})
export class TypeSelectorMenuComponent implements OnInit {
  @Input() rootTypePath: string;
  @Input() typeMenu: TypeMenuItem;
  @ViewChild('childMenu', { static: true }) public childMenu;
  @Output() emitSelectedType = new EventEmitter<string>();
  types: TypeListEntry[];
  rootId: string;
  constructor() {}

  async ngOnInit(): Promise<void> {
    if (!this.rootTypePath) {
      return;
    }
    this.types = await SubTypesHelper.getTypeListFromPath(this.rootTypePath, { includeParentTypes: true });
    const menuItem = buildTypeMenu(this.types);
    this.rootId = menuItem.id;
    this.typeMenu = menuItem;
  }

  selectType(typeId: string) {
    this.emitSelectedType.emit(typeId);
  }
}
