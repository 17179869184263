import { DocumentElement, PositionDefinition, SizeDefinition, StyleDefinition } from '@contrail/documents';
import { CanvasDocument } from '../../../../canvas-document';
import { DrawOptions } from '../../../../renderers/canvas-renderer';
import { DRAG_DIRECTIONS } from '../../../../renderers/selection-widget-renderer/selection-widget-renderer';
import { ElementMouseTarget } from '../../../../state/canvas-state';
import { TableHoverState } from '../table-hover/table-hover-state';
import { TableRange } from './table-range';
import { TableRenderer } from './table-renderer';
import { TableSelectState } from '../table-select/table-select-state';
import { TableReorderState } from '../table-reorder/table-reoder-state';
import { TableCellState } from '../table-cell/table-cell-state';
import { CanvasTableElement } from '../../canvas-table-element';
import { CanvasCellElement } from '../../canvas-cell-element';
import { TableEditor } from '../../table-editor/table-editor';
import { TableService } from '../table.service';
import { ObjectUtil } from '@contrail/util';

export interface TableRow {
  y: number;
  height: number;
  originalY: number;
  originalHeight: number;
  id: string;
  index: number;
}
export interface TableColumn {
  x: number;
  width: number;
  originalX: number;
  originalWidth: number;
  id: string;
  index: number;
}
export interface TableCell {
  rowIndex: number;
  columnIndex: number;
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface TableMouseTarget {
  direction: DRAG_DIRECTIONS;
  index?: { x?: number; y?: number };
  cell?: TableCell;
}

export class Table {
  public tableRenderer: TableRenderer;
  public tableReorderState: TableReorderState;
  public tableHoverState: TableHoverState;
  public tableSelectionState: TableSelectState;
  public tableCellState: TableCellState;

  public rows: Array<TableRow> = [];
  public columns: Array<TableColumn> = [];
  public isEditing = false;
  public isInitialized = false;

  private cellAtCache: TableCell;

  constructor(
    private canvasDocument: CanvasDocument,
    public element: CanvasTableElement,
  ) {
    this.tableHoverState = new TableHoverState(this);
    this.tableReorderState = new TableReorderState(this);
    this.tableSelectionState = new TableSelectState(this);
    this.tableCellState = new TableCellState(this, this.canvasDocument);
  }

  get size(): SizeDefinition {
    return this.element.elementDefinition.size;
  }

  get position(): PositionDefinition {
    return this.element.elementDefinition.position;
  }

  get style(): StyleDefinition {
    return this.element.elementDefinition.style;
  }

  public init() {
    this.setRows();
    this.setColumns();
    this.tableCellState.setCells();
    this.tableRenderer = new TableRenderer(this);

    if (
      this.element.elementDefinition.rowIds.length === this.rows.length &&
      this.element.elementDefinition.columnIds.length === this.columns.length
    ) {
      this.isInitialized = true;
    } else {
      this.isInitialized = false;
    }

    console.log('Table init', this.element.elementDefinition, this.rows, this.columns, this.tableCellState.cells);
  }

  public updateTableState() {
    this.init();
  }

  public render(ctx, params: { x; y; width; height }, options?: DrawOptions) {
    if (!this.isInitialized) return;
    const viewScale = this.canvasDocument.getViewScale();

    // Render cells with fills
    this.tableCellState.render(ctx, params, options);

    // Render lines
    this.tableRenderer.render(ctx, params, options);

    // Render selected cells, columns, rows
    this.tableSelectionState.render(ctx, params);

    this.tableHoverState.render(ctx, viewScale, params);
    this.tableReorderState.render(ctx, viewScale, params);
  }

  /**
   * Get row index if px and py is positioned on the bottom line of a row
   * @param px
   * @param py
   * @param param2
   * @param lineWidth
   * @returns
   */
  public getRowBottomAt(px, py, { x, y, width, height }, lineWidth): number {
    const horizontal = x < px && x + width > px;
    let rowIndex;
    for (let i = 0; i < this.rows.length; i++) {
      const row = this.rows[i];
      const rowBottom = row.y + row.height;
      const top = Math.abs(py - (y + rowBottom)) < lineWidth;
      if (top && horizontal) {
        rowIndex = i;
        break;
      }
    }
    return rowIndex;
  }

  /**
   * Get column index if px and py is positioned on the right line of a column
   * @param px
   * @param py
   * @param param2
   * @param lineWidth
   * @returns
   */
  public getColumnRightAt(px, py, { x, y, width, height }, lineWidth) {
    const vertical = y < py && y + height > py;
    let columnIndex;
    for (let i = 0; i < this.columns.length; i++) {
      const column = this.columns[i];
      const columnRight = column.x + column.width;
      const right = Math.abs(px - (x + columnRight)) < lineWidth;
      if (right && vertical) {
        columnIndex = i;
        break;
      }
    }
    return columnIndex;
  }

  public row(index) {
    return this.rows[index];
  }

  public column(index) {
    return this.columns[index];
  }

  public getCellAt(px, py): TableCell {
    if (this.cellAtCache != null) {
      if (
        px > this.cellAtCache.x &&
        px <= this.cellAtCache.x + this.cellAtCache.width &&
        py > this.cellAtCache.y &&
        py <= this.cellAtCache.y + this.cellAtCache.height
      ) {
        return this.cellAtCache;
      }
    }

    const cell = this.tableRenderer.getCellAt(px, py, { ...this.element.elementDefinition.position });

    this.cellAtCache = cell;
    return cell;
  }

  public isOnlyCellSelected(cell: TableRange) {
    return this.tableSelectionState.isOnlyCellSelected(cell);
  }

  public emitSelectedAreaStyle() {
    const selectedRanges = this.tableSelectionState.selectedRanges;
    if (selectedRanges.length > 0) {
      const first = selectedRanges[0];
      if (first.startRow === 0 && first.endRow === this.rows.length - 1) {
        const column = this.element.getColumnElement(first.startColumn);
        if (column) {
          (this.canvasDocument.editorHandler.tableEditorManager.editor as TableEditor).getCurrentStyle({
            elementDefinition: column,
          });
        }
      } else if (first.startColumn === 0 && first.endColumn === this.columns.length - 1) {
        const row = this.element.getRowElement(first.startRow);
        if (row) {
          (this.canvasDocument.editorHandler.tableEditorManager.editor as TableEditor).getCurrentStyle({
            elementDefinition: row,
          });
        }
      } else {
        const cell = this.tableCellState.getCell(first.startRow, first.startColumn);
        if (cell) {
          this.canvasDocument.editorHandler.getCurrentStyle(cell);
        }
      }
    }
  }

  public handleSelectOnClick(
    elementTarget: ElementMouseTarget,
    options: { shiftKey?: boolean; ctrlKey?: boolean } = { shiftKey: false, ctrlKey: false },
  ) {
    this.isEditing = true;
    this.tableSelectionState.handleSelectOnClick(elementTarget, options);
    this.emitSelectedAreaStyle();
  }

  public handleDeselectOnClick() {
    this.isEditing = false;
    this.tableSelectionState.clear();
  }

  public handleSelectOnDrag(startingRange: TableRange, range: TableRange) {
    this.tableSelectionState.handleSelectOnDrag(startingRange, range);
  }

  /**
   * Set height of row at index @rowIndex to @height
   * Update @y position for all next rows
   * Update total table height
   * @param rowIndex
   * @param height
   */
  public resizeRow(rowIndex, height) {
    const row = this.rows[rowIndex];
    row.height = height * (this.element.getScale()?.x ?? 1);
    row.originalHeight = height;
    this.element.getRowElement(rowIndex).size.height = height;

    let prevHeight = row.y + row.height;
    let originalPrevHeight = row.originalY + row.originalHeight;
    for (let i = rowIndex + 1; i < this.rows.length; i++) {
      const nextRow = this.rows[i];
      if (nextRow) {
        nextRow.y = prevHeight;
        nextRow.originalY = originalPrevHeight;
        prevHeight += nextRow.height;
        originalPrevHeight += nextRow.originalHeight;
      }
    }

    this.element.elementDefinition.size.height = Array.from(this.rows.values()).reduce(
      (acc, row) => acc + row.originalHeight,
      0,
    );
  }

  /**
   * Set width of column at index @columnIndex to @width
   * Update @x position for all next columns
   * Update total table width
   * @param columnIndex
   * @param width
   */
  public resizeColumn(columnIndex, width) {
    const column = this.columns[columnIndex];
    column.width = width * (this.element.getScale()?.x ?? 1);
    column.originalWidth = width;
    this.element.getColumnElement(columnIndex).size.width = width;

    let prevWidth = column.x + column.width;
    let originalPrevWidth = column.originalX + column.originalWidth;
    for (let i = columnIndex + 1; i < this.columns.length; i++) {
      const nextColumn = this.columns[i];
      if (nextColumn) {
        nextColumn.x = prevWidth;
        nextColumn.originalX = originalPrevWidth;
        prevWidth += nextColumn.width;
        originalPrevWidth += nextColumn.originalWidth;
      }
    }

    this.element.elementDefinition.size.width = Array.from(this.columns.values()).reduce(
      (acc, col) => acc + col.originalWidth,
      0,
    );
  }

  public resizeRows(heights: { [id: string]: number }) {
    let totalHeight = 0,
      originalTotalHeight = 0;
    const scale = this.element.getScale()?.x ?? 1;
    this.rows.forEach((row, index) => {
      let height = row.height;
      let originalHeight = row.originalHeight;
      if (heights[row.id] != null) {
        originalHeight = heights[row.id];
        height = originalHeight * scale;
      }
      row.y = totalHeight;
      row.height = height;
      row.originalY = originalTotalHeight;
      row.originalHeight = originalHeight;
      this.element.getRowElement(index).size.height = originalHeight;
      totalHeight += height;
      originalTotalHeight += originalHeight;
    });

    this.element.elementDefinition.size.height = this.rows.reduce((acc, row) => acc + row.originalHeight, 0);
  }

  public resizeColumns(widths: { [id: string]: number }) {
    let totalWidth = 0,
      originalTotalWidth = 0;
    const scale = this.element.getScale()?.x ?? 1;
    this.columns.forEach((column, index) => {
      let width = column.width;
      let originalWidth = column.originalWidth;
      if (widths[column.id] != null) {
        originalWidth = widths[column.id];
        width = originalWidth * scale;
      }
      column.x = totalWidth;
      column.width = width;
      column.originalX = originalTotalWidth;
      column.originalWidth = originalWidth;
      this.element.getColumnElement(index).size.width = originalWidth;
      totalWidth += width;
      originalTotalWidth += originalWidth;
    });
    this.element.elementDefinition.size.width = this.columns.reduce((acc, col) => acc + col.originalWidth, 0);
  }

  public resizeRowCellHeight(
    columnIndex?,
    config?: { force?: boolean },
  ): { elements: DocumentElement[]; undoElements: DocumentElement[] } {
    return this.tableCellState.resizeRowCellHeight(columnIndex, config);
  }

  /**
   * Resets ordered list of @rows and recalculates its @y and @height
   */
  public setRows() {
    let totalHeight = 0;
    let originalY = 0;
    const rows: Array<TableRow> = [];
    this.element.elementDefinition.rowIds.forEach((rowId, index) => {
      const row = this.canvasDocument.documentDefinition.elements.find((element) => element.id === rowId);
      if (row) {
        const originalHeight = row.size.height;
        const height = originalHeight * (this.element.getScale()?.x ?? 1);
        rows.push({ y: totalHeight, height, originalHeight, originalY, id: rowId, index });
        totalHeight += height;
        originalY += originalHeight;
      } else {
        console.warn('Table init warning: could not find row', this.element.elementDefinition, rowId);
      }
    });
    this.rows = rows;
  }

  /**
   * Resets ordered list of @columns and recalculates its @x and @width
   */
  public setColumns() {
    let totalWidth = 0;
    let originalX = 0;
    const columns: Array<TableColumn> = [];
    this.element.elementDefinition.columnIds.forEach((columnId, index) => {
      const column = this.canvasDocument.documentDefinition.elements.find((element) => element.id === columnId);
      if (column) {
        const originalWidth = column.size.width;
        const width = originalWidth * (this.element.getScale()?.x ?? 1);
        columns.push({ x: totalWidth, width, originalWidth, originalX, id: columnId, index });
        totalWidth += width;
        originalX += originalWidth;
      } else {
        console.warn('Table init warning: could not find column', this.element.elementDefinition, columnId);
      }
    });
    this.columns = columns;
  }

  public setReorderSelectedArea(selectedArea: TableColumn | TableRow) {
    this.tableHoverState.set();
    this.tableReorderState.setSelectedArea(selectedArea);
  }

  public setReorderTargetArea(direction: 'column' | 'row', px: number, py: number): TableColumn | TableRow {
    return this.tableReorderState.setTargetArea(direction, px, py);
  }

  public showEditor(rowIndex, columnIndex) {
    const element = this.tableCellState.getCell(rowIndex, columnIndex);
    this.canvasDocument.editorHandler.showEditor(element);
  }

  public clearReorderData() {
    this.tableReorderState.clear();
  }

  public clear() {
    this.isEditing = false;
    this.tableSelectionState.clear();
    this.tableReorderState.clear();
    this.tableHoverState.set();
  }

  public updateCellElement(element: DocumentElement) {
    this.tableCellState.updateCellElement(element);
  }

  public getSelectedCellElements(): CanvasCellElement[] {
    const selectedRanges = this.tableSelectionState.selectedRanges;
    return this.tableCellState.getCells(selectedRanges);
  }

  public getSelectedColumnIndexes(): number[] {
    return this.tableSelectionState.getSelectedColumnIndexes();
  }

  public getSelectedRowIndexes(): number[] {
    return this.tableSelectionState.getSelectedRowIndexes();
  }

  public getAllColumnIndexes(): number[] {
    return this.columns.map((c) => c.index);
  }

  public getAllRowIndexes(): number[] {
    return this.rows.map((r) => r.index);
  }

  public getSelectedColumnsOrRows(): DocumentElement[] {
    const selectedRanges = this.tableSelectionState.selectedRanges;
    return selectedRanges?.length === 0
      ? [this.element.elementDefinition, ...this.element.getRowElements(), ...this.element.getColumnElements()]
      : [];
  }

  public getAllCells(): CanvasCellElement[] {
    return this.tableCellState.getAllCells();
  }

  public handleDeleteAction() {
    const selectedRanges = this.tableSelectionState.selectedRanges;
    if (selectedRanges?.length === 0) return;
    const nonEmptyCells = this.tableCellState.getNonEmptyCells(selectedRanges);
    const selectedColumnIndexes = this.getSelectedColumnIndexes();
    const selectedRowIndexes = this.getSelectedRowIndexes();
    if (nonEmptyCells.length > 0) {
      this.canvasDocument.canvasTableService.clear(nonEmptyCells);
    } else if (selectedColumnIndexes.length === this.columns.length && selectedRowIndexes.length === this.rows.length) {
      this.tableHoverState.set();
      this.canvasDocument.canvasTableService.deleteTable(this.element);
    } else if (selectedColumnIndexes.length > 0) {
      this.tableHoverState.set();
      this.canvasDocument.canvasTableService.delete('column', this.element, selectedColumnIndexes);
    } else if (selectedRowIndexes.length > 0) {
      this.tableHoverState.set();
      this.canvasDocument.canvasTableService.delete('row', this.element, selectedRowIndexes);
    }
  }

  public moveToNextTableCell(options?: { advanceRow: boolean }) {
    this.moveToCell((currentRow, currentColumn) => {
      let nextColumn = currentColumn + 1;
      let nextRow = currentRow;
      if (nextColumn > this.columns.length - 1) {
        if (options?.advanceRow) {
          nextColumn = 0;
          nextRow++;
        } else {
          nextColumn = currentColumn;
        }
      }
      if (nextRow < this.rows.length) {
        return { columnIndex: nextColumn, rowIndex: nextRow };
      }
      return null;
    });
  }

  public moveToPrevTableCell(options?: { advanceRow: boolean }) {
    this.moveToCell((currentRow, currentColumn) => {
      let prevColumn = currentColumn - 1;
      let prevRow = currentRow;
      if (prevColumn < 0) {
        if (options?.advanceRow) {
          prevColumn = this.columns.length - 1;
          prevRow--;
        } else {
          prevColumn = currentColumn;
        }
      }
      if (prevRow >= 0) {
        return { columnIndex: prevColumn, rowIndex: prevRow };
      }
      return null;
    });
  }

  public moveToNextTableRow() {
    this.moveToCell((currentRow, currentColumn) => {
      let nextColumn = currentColumn;
      let nextRow = currentRow + 1;
      if (nextRow < this.rows.length) {
        return { columnIndex: nextColumn, rowIndex: nextRow };
      }
      return null;
    });
  }

  public moveToPrevTableRow() {
    this.moveToCell((currentRow, currentColumn) => {
      let prevColumn = currentColumn;
      let prevRow = currentRow - 1;
      if (prevRow >= 0) {
        return { columnIndex: prevColumn, rowIndex: prevRow };
      }
      return null;
    });
  }

  public moveToCell(getNewCellIndex: any) {
    const selectedRanges = this.tableSelectionState.selectedRanges;
    if (selectedRanges?.length === 0) return;
    if (selectedRanges.length === 1 && selectedRanges[0].singular()) {
      const selectedRange = selectedRanges[0];
      const currentCell = this.tableCellState.getCell(selectedRange.startRow, selectedRange.startColumn);
      const newCellIndex = getNewCellIndex(selectedRange.startRow, selectedRange.startColumn);
      if (newCellIndex) {
        const rowIndex = newCellIndex.rowIndex;
        const columnIndex = newCellIndex.columnIndex;
        this.tableSelectionState.set([new TableRange(rowIndex, rowIndex, columnIndex, columnIndex)]);
        const isEditing = this.canvasDocument?.editorHandler?.isEditing(currentCell);
        if (isEditing) {
          this.canvasDocument?.editorHandler?.hideEditor();
          this.showEditor(rowIndex, columnIndex);
        }
        this.canvasDocument.queueDraw();
      }
    } else {
      const lastSelectedRange = selectedRanges[selectedRanges.length - 1];
      this.tableSelectionState.set([
        new TableRange(
          lastSelectedRange.startRow,
          lastSelectedRange.startRow,
          lastSelectedRange.startColumn,
          lastSelectedRange.startColumn,
        ),
      ]);
      this.canvasDocument.queueDraw();
    }
  }
}
