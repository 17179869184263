import { DocumentElement } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { OrderUtil } from 'src/app/boards/board/util/OrderUtil';
import { CanvasDocument } from '../../../../canvas-document';
import { ImageElement } from '../../../../components/image-element/image-element';
import { DrawOptions } from '../../../../renderers/canvas-renderer';
import { CanvasCellElement } from '../../canvas-cell-element';
import { Table, TableCell, TableColumn, TableRow } from '../table/table';
import { TableRange } from '../table/table-range';
import { TableCellRenderer } from './table-cell-renderer';

export class TableCellState {
  private renderer: TableCellRenderer;
  public cells: Array<Array<string>> = []; // rows of columns with cell id
  public cellsMap: Map<string, CanvasCellElement> = new Map(); // map of cell id, cell element
  constructor(
    private table: Table,
    private canvasDocument: CanvasDocument,
  ) {
    this.renderer = new TableCellRenderer(this.table, this);
  }

  public async preload(options): Promise<ImageElement[]> {
    const promises = [];
    this.cellsMap.forEach((cell) => {
      const column = this.table.column(cell.columnIndex);
      const row = this.table.row(cell.rowIndex);
      if (column && row) {
        promises.push(cell.preload(options));
      }
    });
    return await Promise.all(promises);
  }

  public render(ctx, params: { x; y; width; height }, options?: DrawOptions) {
    this.renderer.render(ctx, params, options);
  }

  public each(cb: (cellId: string, rowIndex: number, columnIndex: number) => void) {
    this.cells.forEach((cellIds, rowIndex) => {
      cellIds.forEach((cellId, columnIndex) => {
        cb(cellId, rowIndex, columnIndex);
      });
    });
  }

  public getCell(rowIndex, columnIndex): CanvasCellElement {
    return this.findCell(this.cells.at(rowIndex)?.at(columnIndex));
  }

  public getAllCells(): CanvasCellElement[] {
    const cells: CanvasCellElement[] = [];
    this.each((cellId, rowIndex, columnIndex) => {
      const cell = this.findCell(cellId);
      if (cell) {
        cells.push(cell);
      }
    });
    return cells;
    // return Array.from(this.cellsMap.values());
  }

  public getColumnCells(columnIndex: number): CanvasCellElement[] {
    return this.cells.map((cellIds) => cellIds[columnIndex]).map((cellId) => this.findCell(cellId));
  }

  public getColumnsCells(columnIndexes: number[]): CanvasCellElement[] {
    return this.cells
      .reduce(
        (acc, cellIds) =>
          acc.concat(cellIds.filter((cellId, columnIndex) => columnIndexes.indexOf(columnIndex) !== -1)),
        [],
      )
      .map((cellId) => this.findCell(cellId));
  }

  public getRowsCells(rowIndexes: number[]): CanvasCellElement[] {
    return this.cells
      .filter((cellIds, rowIndex) => rowIndexes.indexOf(rowIndex) !== -1)
      .flat()
      .map((cellId) => this.findCell(cellId));
  }

  public findCell(id: string): CanvasCellElement {
    return this.cellsMap.get(id);
  }

  /**
   * Update cell text height to fit it on the current column width.
   * Update row height if needed.
   * @param columnIndex
   * @returns
   */
  public resizeRowCellHeight(
    columnIndex?: number,
    config?: { force?: boolean },
  ): { elements: DocumentElement[]; undoElements: DocumentElement[] } {
    const { elements, undoElements } = { elements: [], undoElements: [] }; // row elements
    this.cells.forEach((cellIds, rowIndex) => {
      let maxHeight = this.table.rows[rowIndex].originalHeight;
      const columnCellIds: string[] = columnIndex != null ? [cellIds[columnIndex]] : cellIds;
      columnCellIds.forEach((cellId) => {
        const cell = this.findCell(cellId);
        cell.elementDefinition.size.width = this.table.columns[cell.columnIndex].originalWidth;
        const adjustedElement = cell.autoAdjustSize(config);
        const textHeight = adjustedElement?.size?.height;
        if (textHeight != null) {
          maxHeight = Math.max(maxHeight, textHeight);
        }
      });
      if (maxHeight != this.table.rows[rowIndex].originalHeight) {
        undoElements.push(ObjectUtil.cloneDeep(this.table.element.getRowElement(rowIndex)));
        this.table.resizeRow(rowIndex, maxHeight);
        elements.push(this.table.element.getRowElement(rowIndex));
      }
    });
    return { elements, undoElements };
  }

  public getCells(ranges: TableRange[]): CanvasCellElement[] {
    const cells: CanvasCellElement[] = [];
    ranges.forEach((range: TableRange) => {
      range.each((rowIndex, columnIndex) => {
        const cell = this.getCell(rowIndex, columnIndex);
        if (cell && cells.findIndex((c) => c.id === cell.id) === -1) {
          cells.push(cell);
        }
      });
    });
    return cells;
  }

  public getNonEmptyCells(ranges: TableRange[]): CanvasCellElement[] {
    return this.getCells(ranges).filter((cell) => !cell.isEmpty());
  }

  public moveColumnCells(from, to) {
    this.cells.forEach((cellIds, rowIndex) => {
      OrderUtil.move(cellIds, from, to);
    });
    this.resetIndexes();
  }

  public moveRowCells(from, to) {
    OrderUtil.move(this.cells, from, to);
    this.resetIndexes();
  }

  public addColumnCells(newColumnIndex, cellElements: DocumentElement[]) {
    this.cells.forEach((cellIds, rowIndex) => {
      const element = cellElements[rowIndex];
      cellIds.splice(newColumnIndex, 0, element.id);
      const cell = this.createCell(element, rowIndex, newColumnIndex);
      this.cellsMap.set(element.id, cell);
      this.resetIndexes();
    });
  }

  public addRowCells(newRowIndex, cellElements: DocumentElement[]) {
    const elements: CanvasCellElement[] = cellElements.map((element, columnIndex) => {
      const cell = this.createCell(element, newRowIndex, columnIndex);
      this.cellsMap.set(element.id, cell);
      return cell;
    });
    this.cells.splice(
      newRowIndex,
      0,
      elements.map((e) => e.id),
    );
    this.resetIndexes();
  }

  private resetIndexes() {
    this.each((cellId, rowIndex, columnIndex) => {
      this.updateIndex(cellId, rowIndex, columnIndex);
    });
  }

  private updateIndex(cellId: string, rowIndex: number, columnIndex: number) {
    const cell = this.cellsMap.get(cellId);
    cell.columnIndex = columnIndex;
    cell.rowIndex = rowIndex;
  }

  private createCell(element: DocumentElement, rowIndex: number, columnIndex: number) {
    const cell = new CanvasCellElement(element, this.canvasDocument, false);
    cell.table = this.table;
    cell.rowIndex = rowIndex;
    cell.columnIndex = columnIndex;
    return cell;
  }

  private findByRowIdAndColumnId(rowId: string, columnId: string): CanvasCellElement {
    return Array.from(this.cellsMap.values()).find(
      (e) => e.elementDefinition.rowId === rowId && e.elementDefinition.columnId === columnId,
    );
  }

  private getOrCreateCell(row: TableRow, column: TableColumn): CanvasCellElement {
    let cellElement: CanvasCellElement = this.findByRowIdAndColumnId(row.id, column.id);
    if (!cellElement) {
      const element = this.canvasDocument.documentDefinition.elements.find(
        (element) => element.type === 'cell' && element.rowId === row.id && element.columnId === column.id,
      );
      if (element) {
        cellElement = this.createCell(element, row.index, column.index);
        this.cellsMap.set(element.id, cellElement);
        console.log('Creating new cell element', cellElement);
      }
    }
    return cellElement;
  }

  /**
   * Resets ordered array of @cells that represent rows and column cells.
   * Creates new CanvasCellElement or uses existing one from @cellsMap
   * This resets cells state after existing or remote user actions.
   */
  public setCells() {
    const rows: Array<Array<string>> = [];
    this.table.rows.forEach((row, rowIndex) => {
      const cells: Array<string> = [];
      this.table.columns.forEach((column, columnIndex) => {
        const cell = this.getOrCreateCell(row, column);
        if (cell) {
          cells.push(cell.id);
          this.updateIndex(cell.id, rowIndex, columnIndex);
        } else {
          console.warn(
            'Table init warning: could not find cell',
            this.canvasDocument.documentDefinition.elements,
            row.id,
            column.id,
          );
        }
      });
      rows.push(cells);
    });
    this.cells = rows;
    const currentCellIds: string[] = this.cells.flat();
    const oldCellIds: string[] = Array.from(this.cellsMap.keys());
    const difference = oldCellIds.filter((id) => !currentCellIds.includes(id));
    difference.forEach((id) => {
      this.cellsMap.delete(id);
    });
    this.cells = rows;
  }

  public updateCellElement(element: DocumentElement) {
    const cell = this.findCell(element.id);
    if (cell) {
      cell.elementDefinition = ObjectUtil.mergeDeep(ObjectUtil.cloneDeep(cell.elementDefinition), element);
      this.canvasDocument?.editorHandler?.refreshElement(cell);

      if (this.table.element.isSelected) {
        const isEditing = this.canvasDocument?.editorHandler?.isEditing(cell);
        if (!isEditing) {
          this.canvasDocument?.editorHandler?.getCurrentStyle(cell);
        }
      }
    }
  }
}
