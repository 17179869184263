<div class="flex justify-between items-center w-[140px] select-none" (click)="preventClose($event)">
  <div class="ml-2">Create</div>
  <app-counter-widget
    #counter
    [defaultValue]="1"
    [maxValue]="10"
    [minValue]="1"
    (countChange)="updateCount($event)"
  ></app-counter-widget>
  <div>
    <button
      class="flex"
      data-test="create-typed-items-button-trigger"
      [matMenuTriggerFor]="menu.childMenu"
      #menuTrigger="matMenuTrigger"
      (mouseover)="openMenu(menuTrigger)"
    >
      <mat-icon class="text-gray-700">arrow_right</mat-icon>
    </button>
  </div>
</div>
<app-type-selector-menu #menu rootTypePath="item" (emitSelectedType)="createTypedItem($event)"></app-type-selector-menu>
