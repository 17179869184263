<div class="chooser-source-path centered-vertically">
  <div class="my-auto">
    <span
      data-test="item-data-chooser-source-name"
      [ngClass]="{
        'cursor-pointer': selectedItemType,
        'hover:underline': selectedItemType,
        'hover:text-primary': selectedItemType
      }"
      [matTooltip]="chooserSource?.name"
      [matTooltipDisabled]="chooserSource?.name.length < 20"
      (click)="setSourceItemType(null)"
    >
      {{ chooserSource?.name | shorten: maxPathLength }}
    </span>
    <ng-container *ngIf="selectedItemType">
      <span class="mx-1">/</span>
      <span
        data-test="item-data-chooser-item-type-name"
        [matTooltip]="selectedItemType.label"
        [matTooltipDisabled]="selectedItemType.label.length < 20"
      >
        {{ selectedItemType.label | shorten: maxPathLength }}
      </span>
    </ng-container>
  </div>

  <button
    *ngIf="enableSubTypeSelection"
    class="flex"
    data-test="item-type-quick-filter-selector"
    [matMenuTriggerFor]="filterOnItemTypeMenu.childMenu"
    #filterOnItemTypeMenuTrigger="matMenuTrigger"
    (click)="filterOnItemTypeMenuTrigger.openMenu()"
  >
    <mat-icon class="text-gray-700">keyboard_arrow_down</mat-icon>
  </button>
</div>

<app-type-selector-menu
  #filterOnItemTypeMenu
  rootTypePath="item"
  (emitSelectedType)="setSourceItemType($event)"
></app-type-selector-menu>
