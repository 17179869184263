<mat-menu #childMenu="matMenu" [overlapTrigger]="false">
  <span *ngFor="let typeMenuItem of typeMenu?.children">
    <!-- Handle branch node menu items -->
    <ng-container *ngIf="typeMenuItem.children && typeMenuItem.children.length > 0">
      <div
        mat-menu-item
        class="type-menu-item"
        color="primary"
        [matMenuTriggerFor]="menu.childMenu"
        #menuTrigger="matMenuTrigger"
        (mouseenter)="menuTrigger.openMenu()"
        [attr.data-test]="'type-menu-selector-' + typeMenuItem.id"
      >
        <span>{{ typeMenuItem.label }}</span>
      </div>
      <app-type-selector-menu
        #menu
        [typeMenu]="typeMenuItem"
        (emitSelectedType)="selectType($event)"
      ></app-type-selector-menu>
    </ng-container>
    <!-- Handle leaf node menu items -->
    <ng-container *ngIf="!typeMenuItem.children || typeMenuItem.children.length === 0">
      <div
        class="type-menu-item"
        mat-menu-item
        [disableRipple]="true"
        (click)="selectType(typeMenuItem.id)"
        [attr.data-test]="'type-menu-selector-' + typeMenuItem.id"
      >
        {{ typeMenuItem.label }}
      </div>
    </ng-container>
  </span>
  <span *ngIf="typeMenu?.children?.length === 0">
    <ng-container>
      <div
        class="type-menu-item"
        mat-menu-item
        [disableRipple]="true"
        (click)="selectType(typeMenu.id)"
        [attr.data-test]="'type-menu-selector-' + typeMenu.id"
      >
        {{ typeMenu.label }}
      </div>
    </ng-container>
  </span>
</mat-menu>
