<div *ngIf="layout === 'menu'">
  <div class="widgets-list">
    <button class="tinymce-format-button" (click)="setValue('outdent')" mat-icon-button matTooltip="Decrease indent">
      <mat-icon class="text-black60">format_indent_decrease</mat-icon>
    </button>
    <button class="tinymce-format-button" (click)="setValue('indent')" mat-icon-button matTooltip="Increase indent">
      <mat-icon class="text-black60">format_indent_increase</mat-icon>
    </button>
  </div>
</div>
<div *ngIf="layout === 'list'">
  <button class="tinymce-format-button" (click)="setValue('outdent')" mat-icon-button>
    <mat-icon class="text-black60">format_indent_decrease</mat-icon>
  </button>
  <button class="tinymce-format-button" (click)="setValue('indent')" mat-icon-button>
    <mat-icon class="text-black60">format_indent_increase</mat-icon>
  </button>
</div>
