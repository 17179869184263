import { Component, HostListener, OnDestroy, OnInit, Inject, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { DOCUMENT as HtmlDocument } from '@angular/common';
import { AuthService } from '@common/auth/auth.service';
import {
  Document,
  DocumentAction,
  DocumentChangeType,
  DocumentElement,
  DocumentElementFactory,
} from '@contrail/documents';
import { Subscription, combineLatest, Observable, Subject, fromEvent } from 'rxjs';
import { tap, map, takeUntil, throttleTime, skip, debounceTime, filter } from 'rxjs/operators';
import { Board, BoardService } from './board.service';
import { DocumentComponentService } from './document/document-component/document-component-service';
import { DocumentAssetService } from './document/document-asset/document-asset.service';
import { DocumentService } from './document/document.service';
import { KeyboardHandler } from './handlers/key-handler';
import { PasteHandler } from './handlers/paste-handler';
import { ZoomPanHandler } from './zoom-pan-handler';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { EditorModeSelectors } from '@common/editor-mode/editor-mode-store';
import { DocumentHistorySelectors } from '@common/document-history/document-history-store';
import { ObjectUtil } from '@contrail/util';
import { EditorMode } from '@common/editor-mode/editor-mode-store/editor-mode.state';
import { DocumentAnnotationService } from './document/document-annotation/document-annotation-service';
import { environment } from 'src/environments/environment';
import { SearchReplaceActions, SearchReplaceSelectors } from '@common/search-replace/search-replace-store';
import { BoardSearchService } from './board-search/board-search.service';
import { ContentService } from '@common/content/content.service';
import { FeatureFlagRegistryService } from '@common/feature-flags/feature-flags.service';
import { CanvasDocument, CANVAS_MODE } from './canvas/canvas-document';
import { BoardFrameService } from './board-frames-list/board-frames.service';
import { AnnotationLoader } from './annotations-loader';
import { ComponentCanDeactivate } from '@common/can-deactivate/component-can-deactivate';
import { Intercom } from '@common/analytics/ng-intercom';
import { Feature } from '@common/feature-flags/feature-flag';
import { DocumentContentEditorService } from './document/document-content-editor/document-content-editor.service';
import { DocumentSelectors } from './document/document-store';
import { FrameTemplatesService } from '@common/frame-templates/frame-templates.service';
import { BoardPropertyPoliciesService } from './board-property-policies/board-property-policies.service';
import { FeatureFlagsSelectors } from '@common/feature-flags';
import { CustomFontsActions, CustomFontsSelectors } from '@common/custom-fonts/custom-fonts-store';
import { CustomFontsLoader } from './custom-fonts-loader';
import { ClipboardActions } from '@common/clipboard/clipboard-store';
import { DocumentItemInspectorService } from './document/document-item-inspector/document-item-inspector.service';
import { ItemAssigmentInteractionService } from './document/action-dispatchers/document-element-interaction/item-assignment-interaction.service';
import { DocumentCreateItemsComponent } from './document/document-item/document-create-item/create-item-component/create-items.component';
import { DocumentDynamicTextService } from './document/document-text/document-dynamic-text/document-dynamic-text.service';
import { BoardsSelectors } from '../boards-store';
import { TouchEventsHandler } from './touch-event-handler';
import { CanvasUtil } from './canvas/canvas-util';

@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.scss'],
})
export class BoardComponent extends ComponentCanDeactivate implements OnInit, OnDestroy {
  @ViewChild(DocumentCreateItemsComponent) createItemsComponent: DocumentCreateItemsComponent;
  private destroy$ = new Subject();
  public isFullScreen$;
  public framePresentation$;
  public showMinimap$;
  public showPlaceholderCreation$;
  private showPlaceholderCreation = false;

  public zoomPanHandler: ZoomPanHandler;
  public touchEventsHandler: TouchEventsHandler;
  public currentBoard: Board;
  public document: Document;
  public canvasDocument: CanvasDocument;
  public svgId = 'SVG' + Math.floor(Math.random() * 10000);
  public boardId: string;
  public editorMode: EditorMode;
  public searchActive = false;
  searchActive$ = this.store.select(SearchReplaceSelectors.searchActive);
  searchResults$ = this.store.select(SearchReplaceSelectors.searchResults);
  subscriptions = new Subscription();
  activeSearchResultElementIndex = 0;
  assignItemToComponentActive = false;

  private isShiftKey = false;
  private dragEnterFromElement = null;
  private dragItemFromChooser = null;

  private readonly SKIP_KEY_EVENTS_FOR_COMPONENTS: Array<string> = [
    'app-side-menu',
    'app-item-chooser',
    'app-context-comments-list',
    'app-document-history',
    'app-chooser',
    'app-board-minimap',
    'app-comment-form',
  ];
  private readonly HANDLE_CTRL_KEY_EVENTS_FOR_COMPONENTS: Array<string> = ['app-side-menu']; // Array of components to handle wheel events for if ctrl is pressed, subset of SKIP_KEY_EVENTS_FOR_COMPONENTS
  private subscription: Subscription = new Subscription();

  hasSvgRecolorFeatureFlag: boolean = false;
  private customFonts: string;

  public isSharedLinkUser = false;

  constructor(
    private documentService: DocumentService,
    private activatedRoute: ActivatedRoute,
    private annotationLoader: AnnotationLoader,
    private boardService: BoardService,
    private boardFrameService: BoardFrameService,
    private store: Store<RootStoreState.State>,
    private documentComponentService: DocumentComponentService,
    private documentAssetService: DocumentAssetService,
    private documentAnnotationService: DocumentAnnotationService,
    private documentItemInspectorService: DocumentItemInspectorService,
    private contentEditorService: DocumentContentEditorService,
    private featureFlagService: FeatureFlagRegistryService,
    private keyBoardHandler: KeyboardHandler,
    private authService: AuthService,
    private boardSearchService: BoardSearchService,
    private pasteHandler: PasteHandler,
    private titleService: Title,
    private contentService: ContentService,
    private frameTemplateService: FrameTemplatesService,
    private boardPropertyPoliciesService: BoardPropertyPoliciesService,
    private documentDynamicTextService: DocumentDynamicTextService,
    private assignComponentService: ItemAssigmentInteractionService,
    private customFontsLoader: CustomFontsLoader,
    private intercom: Intercom,
    @Inject(HtmlDocument) private htmlDocument: HTMLDocument,
  ) {
    super();
    this.isFullScreen$ = this.boardService.fullScreen$;
    this.framePresentation$ = this.boardFrameService.presentationMode$;
    this.showMinimap$ = this.boardService.showMinimap$;
    this.showPlaceholderCreation$ = this.boardService.showPlaceholderCreation$;
    this.frameTemplateService.initialize(['FRAME']);
    this.isSharedLinkUser = this.boardService.isSharedLinkUser;
  }

  /**
   * Checks if there are unfinished file upload promises and warns the user about
   * unsaved changes
   * @returns
   */
  showConfirmDialog(): any {
    return this.documentService?.fileHandler?.showConfirmDialog() || this.contentEditorService.showConfirmDialog();
  }

  ngOnInit(): void {
    this.htmlDocument.body.classList.add('overscroll-none', 'overflow-hidden'); // avoid showing white space on scroll and disable default browser scroll behavior (for ex. scrolling right to go back a page on Chrome)
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) {
      this.htmlDocument.documentElement.classList.add('overflow-hidden');
      this.htmlDocument.body.classList.add('overflow-auto');
    }

    this.subscribe();

    this.contentService.screenCaptures$.pipe(takeUntil(this.destroy$)).subscribe((file) => {
      if (file) {
        this.addImageElementFromFile([file]);
      }
    });

    this.intercom.update({
      // hide the intercom
      hide_default_launcher: true,
    });

    this.store
      .select(DocumentSelectors.viewSize)
      .pipe(skip(1), debounceTime(2000), takeUntil(this.destroy$))
      .subscribe((res) => {
        const key = `lastLocation_` + this.boardId;
        const lastLocation = {
          position: { x: res?.viewBox?.x, y: res?.viewBox?.y },
          zoom: 1 / res?.viewScale?.x,
        };
        localStorage.setItem(key, JSON.stringify(lastLocation));
      });
  }

  ngOnDestroy() {
    this.canvasDocument?.clear();
    this.htmlDocument.body.classList.remove('overscroll-none', 'overflow-hidden');
    this.htmlDocument.documentElement.classList.remove('overflow-hidden');
    this.htmlDocument.body.classList.remove('overflow-auto');
    this.unsubscribe();
    this.boardService.clearCurrentBoard();
    this.boardFrameService.sortFormGroup.setValue({
      sortBy: { label: 'Left to right', property: 'horizontal' },
      keepSorted: false,
    });
    this.boardFrameService.socketChanges = false;

    this.destroy$.next(null);
    this.destroy$.complete();
  }
  subscribe() {
    this.activatedRoute.params.subscribe((params) => {
      if (params.boardId && this.boardId !== params.boardId) {
        this.boardId = params.boardId;
        this.boardService.init(this.boardId);
        this.store.dispatch(CustomFontsActions.loadCustomFonts());
        this.store.dispatch(ClipboardActions.loadClipboardItems());
      }
    });

    this.subscription.add(this.store.select(EditorModeSelectors.editorMode).subscribe((m) => (this.editorMode = m)));
    this.subscription.add(
      this.store.select(CustomFontsSelectors.customFonts).subscribe((customFonts) => (this.customFonts = customFonts)),
    );
    const reloadBoardSub = combineLatest([
      this.boardService.currentBoard,
      this.store.select(EditorModeSelectors.editorMode),
      this.store.select(DocumentHistorySelectors.currentEntitySnapshot),
      this.store.select(CustomFontsSelectors.customFontsLoaded).pipe(filter((customFontsLoaded) => customFontsLoaded)),
    ])
      .pipe(
        tap(async ([board, editorMode, entitySnapshot, customFontsLoaded]) => {
          if (!board) {
            return;
          }
          this.currentBoard = board;
          if (board) {
            this.titleService.setTitle(board?.name);
          }
          await this.loadSVG(entitySnapshot, editorMode);
        }),
      )
      .subscribe();
    this.subscription.add(reloadBoardSub);
    this.subscription.add(
      this.documentService.documentElementEvents.subscribe(this.handleDocumentElementEvent.bind(this)),
    );

    this.subscriptions.add(
      combineLatest([
        this.store.select(SearchReplaceSelectors.searchResults),
        this.store.select(SearchReplaceSelectors.activeSearchResultElement),
      ])
        .pipe(
          map(([searchResults, activeSearchResultElement]) => {
            this.activeSearchResultElementIndex = searchResults.findIndex(
              (searchResult) =>
                searchResult.id === activeSearchResultElement?.id &&
                searchResult.frameId === activeSearchResultElement?.frameId,
            );
          }),
        )
        .subscribe(),
    );
    this.subscriptions.add(
      this.annotationLoader.loaded.subscribe((loaded) => {
        if (loaded) {
          this.documentService.annotationOptions = AnnotationLoader.annotations;
        }
      }),
    );
    this.subscriptions.add(
      this.showPlaceholderCreation$.subscribe((show) => {
        if (!show && this.showPlaceholderCreation) {
          this.createItemsComponent?.setValue(1);
        }
        this.showPlaceholderCreation = show;
      }),
    );

    this.handleFileDragOver();
    this.registerMouseMoveHandler();
  }

  /** Handles events that occur on document elements, such as drags, clicks, etc.
   */
  handleDocumentElementEvent(event) {
    if (!event || !this.zoomPanHandler) {
      return;
    }
    if (event.eventType === 'dragged') {
      this.zoomPanHandler.autoPan(event.relativeMousePosition);
    }
    if (event.eventType === 'contextmenu') {
      // Mouseup event is not being called when context menu opens.
      // Stop right click dragging.
      this.zoomPanHandler.stopGrabbingMode(event);
    }
  }

  unsubscribe() {
    this.subscription.unsubscribe();
  }

  private async loadSVG(entitySnapshot, editorMode) {
    if (!this.currentBoard) {
      return;
    }
    this.canvasDocument?.clear();
    if (entitySnapshot) {
      this.document = ObjectUtil.cloneDeep(entitySnapshot.snapshot.document);
    } else {
      this.document = this.currentBoard.document;
    }

    this.store.select(FeatureFlagsSelectors.featureFlags).subscribe((flags) => {
      this.hasSvgRecolorFeatureFlag = !!flags.find((x) => x.featureName === Feature.SVG_RECOLORING);
      this.assignItemToComponentActive = !!flags.find((x) => x.featureName === Feature.ASSIGN_ITEM_TO_COMPONENT);
    });

    let mode = editorMode as CANVAS_MODE;
    if (editorMode === EditorMode.VIEW) {
      mode = CANVAS_MODE.PREVIEW;
    }
    this.canvasDocument = new CanvasDocument(
      'mainCanvas',
      this.document,
      this.documentService,
      mode,
      this.authService,
      { imageHost: environment.imageHost },
      AnnotationLoader.annotations,
      this.customFonts,
      this.boardPropertyPoliciesService.restrictedViewablePropertySlugs,
      this.hasSvgRecolorFeatureFlag,
    );
    // this.svgDocument = new SVGDocument('#mainCanvas', this.document, this.documentService, mode, this.authService, this.documentAnnotationService.getAnnotationOptions());
    this.documentService.setRenderer(this.canvasDocument);
    this.documentService.activateComponentAndImageInteraction(true); // turn on component-image-element-interaction
    this.documentService.activateAssignItemToComponent(this.assignItemToComponentActive); // turn on/off component-to-component-element-interaction
    // This is used to determine if an existing item can be assigned to a component based on a property value on a target component. Hardcoded for now.
    this.documentService.setAssignItemToComponentConditions([
      {
        property: 'lifecycleStage',
        values: ['Placeholder', 'placeholder', 'slot', 'Slot'], // this should have been internal value but not sure how this is set in the background service
      },
    ]);
    this.documentService.init(this.document, 'board:' + this.currentBoard.id);
    this.zoomPanHandler = new ZoomPanHandler(this.canvasDocument, this.currentBoard, this.store);
    this.boardService.initZoomPanHandler(this.zoomPanHandler);
    this.touchEventsHandler = new TouchEventsHandler(this.canvasDocument, this.zoomPanHandler);

    if (this.boardService.lastLocation) {
      this.boardService.gotoLastLocation();
    } else if (this.document.startingLocation) {
      this.boardService.navigateToStartingLocation(false);
    }

    this.documentDynamicTextService.init(this.documentService, this.store.select(BoardsSelectors.currentBoard));
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (!this.zoomPanHandler) {
      return;
    }
    this.zoomPanHandler.optimizeSize();
  }

  /** Handler for mouse wheel and track pad movement. */
  @HostListener('wheel', ['$event'])
  handleMouseWheelAndTrackPad(event: any) {
    if (!this.zoomPanHandler) {
      return;
    }

    const objHierarchy = event.path || (event.composedPath && event.composedPath());
    for (const obj in objHierarchy) {
      if (
        this.SKIP_KEY_EVENTS_FOR_COMPONENTS?.length &&
        objHierarchy[obj] &&
        this.SKIP_KEY_EVENTS_FOR_COMPONENTS.includes(objHierarchy[obj].localName) &&
        !(event.ctrlKey && this.HANDLE_CTRL_KEY_EVENTS_FOR_COMPONENTS.includes(objHierarchy[obj].localName))
      ) {
        // allow short circuit if ctrl is not pressed, to support scroll events while still blocking zoom events
        return;
      }
    }

    this.zoomPanHandler.mouseWheelEventSubjectZoom.next(event);
    this.zoomPanHandler.mouseWheelEventSubjectPan.next(event);

    // Prevent default to disable Safari/Firefox native mouse wheel animation to go back a page.
    event.preventDefault();
  }

  zoomIn() {
    if (!this.zoomPanHandler) {
      return;
    }
    this.zoomPanHandler.zoomIn();
  }
  zoomOut() {
    if (!this.zoomPanHandler) {
      return;
    }
    this.zoomPanHandler.zoomOut();
  }

  dragEnter(event) {
    event.stopPropagation();
    event.preventDefault();
    if (
      !event.fromElement ||
      this.dragEnterFromElement?.classList?.contains('chooser-data-asset') ||
      (document.getElementsByClassName('chooser-data-asset') || [])[0]?.contains(event?.fromElement)
    ) {
      this.dragEnterFromElement = true;
      this.canvasDocument.sendEvent({
        eventType: 'file_drag_enter',
        sourceMouseEvent: event,
        element: null,
      });
    } else if (
      this.assignItemToComponentActive &&
      (event?.fromElement?.classList?.contains('chooser') ||
        event?.fromElement?.classList?.contains('data-row') ||
        event?.fromElement?.classList?.contains('object-details') ||
        event?.fromElement?.classList?.contains('selector-checkbox'))
    ) {
      this.dragItemFromChooser = true;
      this.canvasDocument.sendEvent({
        eventType: 'item_drag_enter',
        sourceMouseEvent: event,
        element: null,
      });
    }
  }

  dragOver(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  handleFileDragOver() {
    this.subscription.add(
      fromEvent(document.getElementById('canvas-container'), 'dragover')
        .pipe(
          throttleTime(300, undefined, { leading: true, trailing: true }),
          tap((event: MouseEvent) => {
            if (this.dragEnterFromElement) {
              this.canvasDocument.sendEvent({
                eventType: 'file_drag_over',
                sourceMouseEvent: event,
                element: null,
              });
            } else if (this.dragItemFromChooser) {
              this.canvasDocument.sendEvent({
                eventType: 'item_drag_over',
                sourceMouseEvent: event,
                element: null,
              });
            }
          }),
        )
        .subscribe(),
    );
  }

  dragLeave(event) {
    event.stopPropagation();
    event.preventDefault();
    this.dragEnterFromElement = null;
    this.canvasDocument.sendEvent({
      eventType: 'file_drag_leave',
      sourceMouseEvent: event,
      element: null,
    });

    this.dragItemFromChooser = null;
    this.canvasDocument.sendEvent({
      eventType: 'item_drag_leave',
      sourceMouseEvent: event,
      element: null,
    });
  }

  drop(event) {
    if (this.editorMode !== EditorMode.EDIT) {
      return;
    }
    event.stopPropagation();
    event.preventDefault();

    this.dragEnterFromElement = null;
    this.dragItemFromChooser = null;

    if (event.dataTransfer.getData('dataObject')) {
      let data = event.dataTransfer.getData('dataObject');
      const options: DocumentElement = {
        position: this.zoomPanHandler.computeDocumentPositionForMouseEvent(event),
      };

      if (data) {
        data = JSON.parse(data);
      }

      if (data.entityType === 'asset') {
        const targetElement: DocumentElement = this.canvasDocument.getTargetElement();
        if (targetElement && targetElement?.type === 'component' && targetElement?.modelBindings?.item) {
          this.documentAssetService.assignImageItemFromAsset(data, targetElement);
        } else {
          this.documentAssetService.createElementFromAsset(data, options);
        }
      } else if (data.entityType === 'content') {
        this.documentAssetService.createElementFromContent(data, options);
      } else if (data.entityType === 'bound_text') {
        console.log('Dropping text: ', data);
        let text = data.text;
        options.size = {
          height: 40,
          width: 150,
        };
        options.propertyBindings = data.propertyBindings;
        // options.modelBindings = data.modelBindings;  // BL: Removing because we are setting board level bindings..  May come back in time.
        const textElement = DocumentElementFactory.createTextElement(text, options);

        const actions: Array<DocumentAction> = [];
        const action: DocumentAction = new DocumentAction(
          {
            elementId: textElement.id,
            elementData: textElement,
            changeType: DocumentChangeType.ADD_ELEMENT,
          },
          {
            elementId: textElement.id,
            elementData: textElement,
            changeType: DocumentChangeType.DELETE_ELEMENT,
          },
        );
        actions.push(action);
        this.documentService.deselectAllElements();
        this.documentService.handleDocumentActions(actions);
      } else {
        // For items and colors
        const targetElement: DocumentElement = this.canvasDocument.getTargetElement();
        if (data.item && targetElement && targetElement?.type === 'component' && targetElement?.modelBindings?.item) {
          this.assignComponentService.assignItemToDocumentElement(data, targetElement);
        } else {
          this.documentComponentService.addItemDataAsComponentElement(data, options);
        }
      }
    } else if (event.dataTransfer.files.length > 0) {
      const files = event.dataTransfer.files;
      const options = {
        position: this.zoomPanHandler.computeDocumentPositionForMouseEvent(event),
      };
      if (options?.position?.x == null || isNaN(options?.position?.x)) {
        options.position = this.zoomPanHandler.computeDocumentPositionForCanvasCenter();
      }
      this.addImageElementFromFile(files, options);
    }

    this.canvasDocument.sendEvent({
      eventType: 'file_drag_leave',
      sourceMouseEvent: event,
      element: null,
    });
  }
  async addImageElementFromFile(files: File[], options: DocumentElement = {}) {
    if (this.editorMode !== EditorMode.EDIT) {
      return;
    }
    if (!options?.position) {
      options.position = this.zoomPanHandler.computeDocumentPositionForCanvasCenter();
    }

    let targetElement: DocumentElement = this.canvasDocument.getTargetElement();
    if (targetElement?.type !== 'component' || !targetElement?.modelBindings?.item) {
      targetElement = null;
    }

    const elements = await this.documentService.fileHandler.addImageElementsFromFiles(
      files,
      options,
      null,
      targetElement,
    );
    if (elements?.length > 0) {
      const padding = 50;
      const { x, y, width, height } = CanvasUtil.getCommonBounds(elements);
      if (this.zoomPanHandler.viewBox.width <= width || this.zoomPanHandler.viewBox.height <= height) {
        this.zoomPanHandler.placeRectInCenter({ width, height }, { x, y }, padding);
      }
    }
  }

  handleMouseDown(event: MouseEvent) {
    if (!this.zoomPanHandler) {
      return;
    }
    this.zoomPanHandler.handleMouseDown(event);
  }

  // @HostListener('document:mousemove', ['$event'])
  registerMouseMoveHandler() {
    this.subscription.add(
      fromEvent(document, 'mousemove')
        .pipe(
          throttleTime(25, undefined, { leading: true, trailing: true }),
          tap((event: MouseEvent) => {
            if (this.zoomPanHandler) {
              this.zoomPanHandler.handleMouseMove(event);
            }
          }),
          // Throttle broadcasting mouse move to once every 300ms because we don't need
          // to send so many events.
          throttleTime(300, undefined, { leading: true, trailing: true }),
          tap((event: MouseEvent) => {
            if (this.zoomPanHandler) {
              this.zoomPanHandler.setLastKnownMousePosition({
                x: event.x,
                y: event.y,
              });
              this.boardService.broadcastMouseMove(this.zoomPanHandler.computeDocumentPositionForMouseEvent(event));
            }
          }),
        )
        .subscribe(),
    );
  }

  @HostListener('touchstart', ['$event'])
  touchstart(event: any) {
    this.touchEventsHandler?.onTouchStart(event);
  }

  @HostListener('touchmove', ['$event'])
  touchmove(event: any) {
    this.touchEventsHandler?.onTouchMove(event);
  }

  @HostListener('touchend', ['$event'])
  touchend(event: any) {
    this.touchEventsHandler?.onTouchEnd(event);
  }

  handleMouseUp(event: MouseEvent) {
    if (!this.zoomPanHandler) {
      return;
    }
    this.zoomPanHandler.handleMouseUp(event);
  }

  @HostListener('document:visibilitychange', ['$event'])
  handleVisibilityChange(event: MouseEvent) {
    if (!this.zoomPanHandler) {
      return;
    }
    this.documentService.handleDocumentNavigationEvent({
      eventType: 'visibilitychange',
      navigationType: document.visibilityState,
    });
    // Redraw canvas when visibility is restored
    // Workaround for https://issues.chromium.org/issues/328755781
    if (this.canvasDocument && document.visibilityState === 'visible') {
      this.canvasDocument.queueDraw();
    }
  }

  @HostListener('document:keydown', ['$event']) handleKeyDownEvent(event: KeyboardEvent) {
    if (event.code === 'KeyV' && event.shiftKey && (event.ctrlKey || event.metaKey)) {
      this.isShiftKey = true;
    }
  }

  @HostListener('document:keyup', ['$event']) handleKeyUpEvent(event: KeyboardEvent) {
    this.isShiftKey = false;
  }

  @HostListener('document:paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    if (this.editorMode !== EditorMode.EDIT) {
      return;
    }
    const options: DocumentElement = {};
    if (this.zoomPanHandler.lastKnownMousePosition) {
      options.position = this.zoomPanHandler.computeDocumentPositionForCoordinates(
        this.zoomPanHandler.lastKnownMousePosition,
      );
    } else {
      options.position = this.zoomPanHandler.computeDocumentPositionForCanvasCenter();
    }
    this.pasteHandler.handlePasteEvent(event, options, this.isShiftKey);
  }

  searchForElements(event) {
    this.boardSearchService.search(event);
  }

  setSearchActiveElement(event) {
    this.store.dispatch(SearchReplaceActions.setActiveSearchResultElement({ activeSearchResultElement: event }));
  }

  closeSearchPanel() {
    this.store.dispatch(SearchReplaceActions.toggleSearch());
  }
}

export function debounce(delay: number = 3): MethodDecorator {
  return function (target: any, propertyKey: string | symbol, descriptor: PropertyDescriptor) {
    const timeoutKey = Symbol();
    const original = descriptor.value;
    descriptor.value = function (...args) {
      clearTimeout(this[timeoutKey]);
      this[timeoutKey] = setTimeout(() => original.apply(this, args), delay);
    };
    return descriptor;
  };
}
