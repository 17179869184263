import { DocumentElement } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { CanvasDocument } from '../../canvas-document';
import { CanvasCellElement } from './canvas-cell-element';
import { CanvasTableElement } from './canvas-table-element';
import { TableService } from './table-manager/table.service';

export class CanvasTableService {
  constructor(private canvasDocument: CanvasDocument) {}

  public add(direction: 'row' | 'column', index, tableElement: CanvasTableElement) {
    this.canvasDocument.documentService.documentTableService.add(
      direction,
      index,
      tableElement.elementDefinition,
      tableElement.getTableChildElements(),
    );
  }

  public move(direction: 'column' | 'row', tableElement: CanvasTableElement, from: number, to: number) {
    this.canvasDocument.documentService.documentTableService.move(direction, tableElement.elementDefinition, from, to);
  }

  public clear(cells: CanvasCellElement[]) {
    this.canvasDocument.documentService.documentTableService.clear(cells.map((e) => e.elementDefinition));
  }

  public delete(direction: 'column' | 'row', tableElement: CanvasTableElement, indexes: number[]) {
    tableElement.table.tableCellState.setCells();
    tableElement.table.tableSelectionState.clear();
    this.canvasDocument.documentService.documentTableService.delete(
      direction,
      tableElement.elementDefinition,
      tableElement.getTableChildElements(),
      indexes,
    );
  }

  public deleteTable(tableElement: CanvasTableElement) {
    this.canvasDocument.documentService.documentTableService.deleteTable(
      tableElement.elementDefinition,
      tableElement.getTableChildElements(),
    );
  }

  /**
   * Represent cells to past as a new grid of rows and columns
   */
  public getTableElementsToPaste(tableElement: CanvasTableElement): DocumentElement[] {
    const selectedCells = tableElement.table.getSelectedCellElements();
    if (selectedCells?.length === 0) return;
    if (selectedCells.length === 1) {
      const cell = selectedCells[0];
      return [
        ObjectUtil.mergeDeep(ObjectUtil.cloneDeep(cell.elementDefinition), {
          scale: { ...tableElement.elementDefinition.scale },
          size: {
            width: tableElement.table.column(cell.columnIndex).originalWidth,
            height: tableElement.table.row(cell.rowIndex).originalHeight,
          },
          position: {
            x: tableElement.elementDefinition.position.x + tableElement.table.column(cell.columnIndex).originalX,
            y: tableElement.elementDefinition.position.y + tableElement.table.row(cell.rowIndex).originalY,
          },
        }),
      ];
    }
    return TableService.createTableFromCells(
      tableElement.elementDefinition,
      tableElement.getRowElements(),
      tableElement.getColumnElements(),
      selectedCells.map((e) => e.elementDefinition),
    );
  }

  public pasteTableCells(tableElement: CanvasTableElement, elements: DocumentElement[]) {
    const lastSelectedStartRange = tableElement.table.tableSelectionState.getLastSelectedStartRange();
    if (!lastSelectedStartRange) return;
    this.canvasDocument.documentService.documentTableService.paste(
      tableElement.elementDefinition,
      tableElement.getTableChildElements(),
      elements,
      lastSelectedStartRange.startRow,
      lastSelectedStartRange.startColumn,
    );
  }
}
