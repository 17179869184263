<div
  *ngIf="availableProperties"
  cdkDropList
  class="flex flex-col space-y-[5px]"
  (cdkDropListDropped)="drop($event)"
  (click)="hideFormatBar()"
>
  <ng-template ngFor let-element [ngForOf]="selectedPropertiesList">
    <div
      class="list-value h-[26px] bg-gray-50 flex flex-row items-center justify-between text-xs rounded-[5px] px-[7px] py-1.5"
      cdkDrag
    >
      <div class="flex items-center w-full text-black/60">
        <mat-icon class="cursor-move mr-2.5" cdkDragHandle>drag_handle</mat-icon>
        <div>
          {{ element.propertyDefinition?.label || (element.slug === 'thumbnail' ? 'Thumbnail' : 'Annotation') }}
        </div>

        <div class="flex items-center justify-end grow gap-2" *ngIf="element">
          <mat-icon
            class="!w-full cursor-pointer !flex items-center justify-end !text-[15px] mr-0"
            *ngIf="!element.includeLabel && element.propertyDefinition"
            (click)="toggleLabel(element)"
            >label_outline</mat-icon
          >
          <mat-icon
            class="!w-full cursor-pointer !flex items-center justify-end !text-[15px] mr-0"
            *ngIf="element.includeLabel && element.propertyDefinition"
            (click)="toggleLabel(element)"
            >label</mat-icon
          >

          <div (click)="showFormatBar(element, $event)" *ngIf="element.propertyDefinition">
            <app-property-view-builder-format-bar #formatBar (valuesChanged)="updateFormatting($event)">
            </app-property-view-builder-format-bar>
            <mat-icon
              class="!w-full cursor-pointer !flex items-center justify-end !text-[15px] mr-0"
              [color]="getActiveColor(element)"
              data-test="component-editor-property-font-settings"
              >text_fields</mat-icon
            >
          </div>
          <div [ngClass]="{ 'text-gray-400': element.isHidden }" class="cursor-pointer">
            <mat-icon
              class="!w-full !flex items-center justify-end !text-[15px]"
              *ngIf="element.propertyDefinition"
              (click)="removeElement(element)"
              >delete</mat-icon
            >
            <mat-icon
              class="!w-full !flex items-center justify-end !text-[15px]"
              *ngIf="element.slug === 'thumbnail'"
              (click)="hideElement(element)"
              data-test="component-editor-hide-thumbnail"
              >{{ element.isHidden ? 'visibility_off' : 'visibility' }}</mat-icon
            >
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <div class="h-[26px] bg-gray-50 flex flex-row items-center justify-between text-xs rounded-[5px] px-2">
    <form class="flex items-center w-full h-full">
      <mat-icon class="text-black24 !w-6 !h-6 !text-2xl !leading-6 cursor-pointer" (click)="openPanel($event)"
        >add_box</mat-icon
      >
      <input
        #propertyInput
        type="text"
        [formControl]="propertySearchFormControl"
        placeholder="Add property"
        [matAutocomplete]="auto"
        size="30"
      />
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addToPropertiesList($event.option.value)">
        <mat-option *ngFor="let element of filteredProperties" [value]="element">
          {{ element.propertyDefinition?.label }}
        </mat-option>
      </mat-autocomplete>
    </form>
  </div>
</div>
