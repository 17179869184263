import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-counter-widget',
  template: `
    <div class="flex items-center">
      <div class="flex" [ngClass]="error === true ? 'input error' : 'input'">
        <input
          data-test="counter-input"
          [min]="minValue"
          [max]="maxValue"
          type="number"
          matInput
          [formControl]="countInput"
        />
      </div>
      <div class="flex flex-col h-[16px] justify-between ml-[2px]">
        <div class="counter-button increase" data-test="counter-button-increase" (click)="increaseCount()">
          <svg viewBox="0 0 10 5">
            <path xmlns="http://www.w3.org/2000/svg" d="M5 0l5 5H0z" fill-rule="evenodd" fill="currentColor"></path>
          </svg>
        </div>
        <div class="counter-button decrease" data-test="counter-button-decrease" (click)="decreaseCount()">
          <svg viewBox="0 0 10 5">
            <path xmlns="http://www.w3.org/2000/svg" d="M5 5l5-5H0z" fill-rule="evenodd" fill="currentColor"></path>
          </svg>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .input {
        @apply flex items-center justify-center rounded-[3px] box-border border-[1px] border-solid border-gray-300 ml-[6px] mt-[2px] h-[20px] w-[30px];
        &.error {
          @apply border-[2px] border-solid border-red-500;
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
      .counter-button {
        @apply cursor-pointer flex justify-center w-5 text-gray-700;

        &.increase {
          @apply items-end;
        }

        &.decrease {
          @apply items-start;
        }

        svg {
          @apply w-2.5 h-1.5;
        }
      }
    `,
  ],
})
export class CounterWidgetComponent implements OnInit {
  @Input() minValue = 1;
  @Input() maxValue = 10;
  @Input() defaultValue = 1;
  @Output() countChange = new EventEmitter<void>();
  countInput: UntypedFormControl;
  error = false;
  constructor() {}

  ngOnInit(): void {
    this.countInput = new UntypedFormControl(this.defaultValue);
    this.countInput.valueChanges.subscribe((value) => {
      if (value < this.minValue || value > this.maxValue) {
        this.error = true;
        this.countChange.emit(null);
      } else {
        this.error = false;
        this.countChange.emit(this.countInput.value);
      }
    });
  }

  increaseCount() {
    if (this.countInput.value < this.maxValue) {
      this.countInput.setValue(this.countInput.value + 1);
    }
  }

  decreaseCount() {
    if (this.countInput.value > this.minValue) {
      this.countInput.setValue(this.countInput.value - 1);
    }
  }

  resetValue() {
    this.countInput.setValue(this.defaultValue);
  }
}
