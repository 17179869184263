import { CanvasDocument } from '../canvas-document';
import { SelectionHandler } from './canvas-selection-handlers/selection-handler';
import { CanvasEventHandlers } from './canvas-event-handlers/canvas-event-handlers';
import { SHAPE_ELEMENT_TYPES } from '../constants';

export const DRAG_DIRECTIONS_CURSORS = {
  top_left: 'nwse-resize',
  top_center: 'ns-resize',
  top_right: 'nesw-resize',
  mid_right: 'ew-resize',
  bottom_right: 'nwse-resize',
  bottom_center: 'ns-resize',
  bottom_left: 'nesw-resize',
  mid_left: 'ew-resize',
  top_line: 'crosshair',
  bottom_line: 'crosshair',
  rotate: 'pointer',
  crop_top_left: 'nwse-resize',
  crop_top_center: 'ns-resize',
  crop_top_right: 'nesw-resize',
  crop_mid_right: 'ew-resize',
  crop_bottom_right: 'nwse-resize',
  crop_bottom_center: 'ns-resize',
  crop_bottom_left: 'nesw-resize',
  crop_mid_left: 'ew-resize',
  crop_body: 'move',
  resize_row: 'ns-resize',
  resize_column: 'ew-resize',
  cell: 'move',
  column: 'move',
  row: 'move',
  drag_column: 'pointer',
  drag_row: 'pointer',
  add_column: 'pointer',
  add_row: 'pointer',
};

export const CURSORS = Object.assign(
  {
    root: 'auto',
    select: 'auto',
    paint_format: null,
    paint_format_hold: null,
    grab: 'grab',
    grabbing: 'grabbing',
    body: 'move',
    edit: 'move',
    pointer: 'pointer',
    zoom_in: 'zoom-in',
  },
  SHAPE_ELEMENT_TYPES.reduce((result, shape) => {
    result[shape] = 'crosshair';
    return result;
  }, {}),
  DRAG_DIRECTIONS_CURSORS,
);

export const DEFAULT_CURSOR = 'auto';

export const CUSTOM_CURSORS = {
  paint_format: 'paint-cursor',
  paint_format_hold: 'paint-cursor',
  generate_document: 'generate-document-cursor',
  create_sticky_note: 'create-sticky-note-cursor',
  new_item_family: 'new-item-family',
  new_typed_item_family: 'new-item-family',
  new_item_option: 'new-item-option',
  item_copy: 'item-copy',
  item_inspector: 'item-inspector',
  create_text_element: 'create-text-tool-cursor',
  text: 'create-text-box-cursor',
  dynamic_text: 'create-dynamic-text-cursor',
  pen: 'pen-cursor',
  highlighter: 'highlighter-cursor',
  eraser: 'eraser-cursor',
  assign_item: 'assign-item-cursor',
  create_table: 'create-table-cursor',
};

export class CanvasInteractionHandler {
  public selectionHandler: SelectionHandler;
  public canvasEventHandlers: CanvasEventHandlers;

  public interactionMode = 'select';
  public oldInteractionMode = 'select';
  private interactionModeBeforeGrab = 'select';

  public componentAndImageInteractionActive = false;
  public assignItemToComponentActive = false;
  public assignItemToComponentConditions = null;

  constructor(private canvasDocument: CanvasDocument) {
    if (this.canvasDocument.mode === 'EDIT') {
      this.selectionHandler = new SelectionHandler(this.canvasDocument);
    }
    if (['EDIT', 'COMMENT', 'PRESENT'].indexOf(this.canvasDocument.mode) !== -1) {
      this.canvasEventHandlers = new CanvasEventHandlers(this.canvasDocument);
    }
  }

  public setInteractionMode(mode) {
    this.oldInteractionMode = this.interactionMode;
    this.interactionMode = mode;
    if (this.canvasDocument.annotationHandler.annotationOptionMap[this.oldInteractionMode]) {
      this.setClassName(this.oldInteractionMode + '-cursor', false);
    } else {
      Object.values(CUSTOM_CURSORS).forEach((customCursor) => {
        if (this.canvasDocument.canvasDisplay.canvasDisplayElement.classList.contains(customCursor)) {
          this.setClassName(customCursor, false);
        }
      });
    }

    if (
      mode === 'root' ||
      SHAPE_ELEMENT_TYPES.indexOf(mode) !== -1 ||
      ['pen', 'highlighter', 'eraser'].indexOf(mode) !== -1
    ) {
      this.selectionHandler.deselectAll();
    }

    this.canvasDocument.submitCrop();
    this.canvasDocument.editorHandler?.hideEditor();
    this.setInteractionCursor();

    this.canvasDocument.actionsDispatcher.handleDocumentElementEvent({
      element: null,
      eventType: 'interactionMode',
      data: {
        interactionMode: this.interactionMode,
        oldInteractionMode: this.oldInteractionMode,
      },
    });
  }

  public setInteractionCursor(documentCursor?: string) {
    const canvasDisplayElement = this.canvasDocument.canvasDisplay.canvasDisplayElement;

    Object.values(CUSTOM_CURSORS).forEach((customCursor) => {
      if (canvasDisplayElement.classList.contains(customCursor)) {
        this.setClassName(customCursor, false);
      }
    });

    const cursor = CURSORS[documentCursor || this.interactionMode];
    const customCursor = CUSTOM_CURSORS[this.interactionMode];
    this.setCursor(cursor || DEFAULT_CURSOR);
    if (this.interactionMode === 'root') {
      this.interactionMode = 'select';
    } else if (this.canvasDocument.annotationHandler.annotationOptionMap[this.interactionMode]) {
      this.setClassName(this.interactionMode + '-cursor', true);
    } else if (customCursor) {
      this.setClassName(customCursor, true);
    }
  }

  private setCursor(cursor: string) {
    const canvasDisplayElement = this.canvasDocument.canvasDisplay.canvasDisplayElement;
    canvasDisplayElement.style.cursor = cursor;
  }

  private setClassName(className: string, isActive: boolean) {
    const canvasDisplayElement = this.canvasDocument.canvasDisplay.canvasDisplayElement;
    canvasDisplayElement.style.cursor = null;
    if (isActive) {
      canvasDisplayElement.classList.add(className);
    } else {
      canvasDisplayElement.classList.remove(className);
    }
  }

  public getInteractionMode() {
    return this.interactionMode;
  }

  public getOldInteractionMode() {
    return this.oldInteractionMode;
  }

  public isSelect() {
    return ['select', 'root'].indexOf(this.interactionMode) !== -1;
  }

  public isAssignItemSelect() {
    return this.interactionMode === 'assign_item';
  }

  public isNewItemOption() {
    return this.interactionMode === 'new_item_option';
  }

  public isItemCopy() {
    return this.interactionMode === 'item_copy';
  }

  public isAnnotate() {
    return this.interactionMode.indexOf('annotation-') > -1;
  }

  public isPaintFormat() {
    return this.interactionMode.indexOf('paint') > -1;
  }

  public isGrabbingMode() {
    return this.interactionMode === 'grabbing' || this.interactionMode === 'grab';
  }

  public isReadyToGrabMode() {
    return this.interactionMode === 'grab';
  }

  public isResizing() {
    return this.canvasEventHandlers?.isResizing();
  }

  /**
   * Pan mode can have 2 states: grabbing and ready-to-grab.
   * ready-to-grab turns on only when the user presses Space but doesn't presses the mouse yet (open hand icon).
   * Grabbing turns on when the user holds down the right click mouse, holds down the middle click button, or holds down the left click mouse while holding Space (grabbing hand icon).
   */
  public setGrabbingMode(modes: { isGrabbing: boolean; isReadyToGrab?: boolean }) {
    if (SHAPE_ELEMENT_TYPES.indexOf(this.interactionMode) !== -1) {
      return;
    }

    if (modes.isReadyToGrab) {
      //console.log('Start grab mode from mode ', this.interactionMode);
      if (modes.isGrabbing === false) {
        this.interactionModeBeforeGrab = this.interactionMode;
      }
      this.setInteractionMode('grab');
      return;
    }

    if (modes.isReadyToGrab === false && (this.interactionMode === 'grabbing' || this.interactionMode === 'grab')) {
      //console.log('Stop grab mode and start mode ', this.interactionModeBeforeGrab);
      this.interactionMode = this.interactionModeBeforeGrab;
      this.setInteractionCursor();
      return;
    }

    if (modes.isGrabbing && this.interactionMode !== 'grabbing') {
      //console.log('Start grabbing mode from mode ', this.interactionMode);
      this.setInteractionMode('grabbing');
    } else if (!modes.isGrabbing && this.interactionMode === 'grabbing') {
      //console.log('Stop grabbing mode and start mode ', this.oldInteractionMode);
      this.setInteractionMode(this.oldInteractionMode);
    }
  }
}
