import { Injectable } from '@angular/core';
import { Types } from '@contrail/sdk';

@Injectable({
  providedIn: 'root',
})
export class BoardPropertyPoliciesService {
  public restrictedViewablePropertySlugs;
  private restrictedViewablePropertyMap = {};

  public colorType;

  constructor() {}

  public async getTypes() {
    const itemType = await new Types().getType({ path: 'item' });
    const projectItemType = await new Types().getType({ path: 'project-item' });
    const assortmentItemType = await new Types().getType({ path: 'assortment-item' });
    this.colorType = await new Types().getType({ path: 'color' });
    if (
      !itemType.typePropertyPolicies &&
      !projectItemType.typePropertyPolicies &&
      !assortmentItemType.typePropertyPolicies
    ) {
      // if none of the properties is restricted, keep the restrictedViewablePropertySlugs as null.
      return;
    }
    this.restrictedViewablePropertyMap['item'] = itemType.typeProperties.map((prop) => prop.slug);
    this.restrictedViewablePropertySlugs = itemType.typeProperties
      .map((prop) => `item.${prop.slug}`)
      .concat(projectItemType.typeProperties.map((prop) => `projectItem.${prop.slug}`))
      .concat(assortmentItemType.typeProperties.map((prop) => `assortmentItem.${prop.slug}`))
      .concat(this.colorType.typeProperties.map((prop) => `color.${prop.slug}`));
  }

  public handleDocumentActions(actions) {
    if (this.restrictedViewablePropertySlugs) {
      // do not do anything if no property is restricted
      actions.forEach((action) => {
        if (
          action.changeDefinition.changeType === 'MODIFY_ELEMENT' &&
          action.changeDefinition?.elementData?.type === 'component'
        ) {
          this.handleDocumentAction(action.changeDefinition.elementData.elements);
          if (action.undoChangeDefinition) {
            this.handleDocumentAction(action.undoChangeDefinition.elementData.elements);
          }
        }
      });
    }
  }

  public handleWebsocketMessage(changes, currentBoardObject, backingAssortmentItems) {
    if (this.restrictedViewablePropertySlugs) {
      // do not do anything if no property is restricted
      if (changes.actions) {
        // changes happened on the elements
        changes.actions.forEach((action) => {
          if (action.changeDefinition?.elementData?.type === 'component') {
            const currentElement = currentBoardObject.document.elements.find(
              (el) => el.id === action.changeDefinition.elementId,
            );
            this.handleElementChange(action.changeDefinition.elementData.elements, currentElement);
            if (action.undoChangeDefinition) {
              this.handleElementChange(action.undoChangeDefinition.elementData.elements, currentElement);
            }
          }
        });
      } else {
        changes.forEach((change) => {
          // changes happened on the backingAssortmentItems
          if (change.changes.item) {
            const backingAssortmentItem = backingAssortmentItems.find(
              (assortmentItem) => assortmentItem.id === change.id,
            );
            this.restrictedViewablePropertyMap['item'].forEach((prop) => {
              if (!change.changes.item.hasOwnProperty(prop) && backingAssortmentItem.item[prop]) {
                change.changes.item[prop] = backingAssortmentItem.item[prop];
              }
            });
            Object.keys(change.changes.item).forEach((key) => {
              if (
                !this.restrictedViewablePropertyMap['item'].find((prop) => prop === key) &&
                !backingAssortmentItem.item.hasOwnProperty(key)
              ) {
                delete change.changes.item[key];
              }
            });
          }
        });
      }
    }
  }

  private handleDocumentAction(elements: any[]) {
    elements?.forEach((element) => {
      if (element.type === 'text') {
        if (
          !this.restrictedViewablePropertySlugs.includes(element.propertyBindings?.text) &&
          !['projectItem.project.name', 'assortmentItem.assortment.name', 'item.type.label'].includes(
            element.propertyBindings?.text,
          )
        ) {
          // remove the text property from text element when sending websocket message from a restricted user.
          delete element.text;
        }
      }
    });
  }

  private handleElementChange(elements: any[], currentElement: any) {
    elements?.forEach((element) => {
      if (element.type === 'text') {
        if (!this.restrictedViewablePropertySlugs.includes(element.propertyBindings?.text)) {
          delete element.text;
        } else if (!element.hasOwnProperty('text')) {
          // When a websocket message is sent from a restricted user, the text element will not have a "text" property.
          // When the websocket message recipient is an unrestricted user, copy the "text" property value from the existing text element.
          const existingInnerElement = currentElement.elements?.find(
            (innerElement) => innerElement.propertyBindings.text === element.propertyBindings.text,
          );
          if (existingInnerElement) {
            element.text = existingInnerElement.text;
          }
        }
      }
    });
  }
}
