import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { TypeProperty } from '@contrail/types';

@Component({
  selector: 'app-object-reference-chooser',
  templateUrl: './object-reference-chooser.component.html',
  styleUrls: ['./object-reference-chooser.component.scss'],
})
export class ObjectReferenceChooserComponent implements OnInit {
  @Input() typeProperty: TypeProperty;
  @Input() context: any;
  @Input() enableInfiniteScroll = false;
  @Output() entitySelected = new EventEmitter();
  @Output() close = new EventEmitter();

  public baseCriteria: { roles?: string; isPrimary?: boolean };
  public typePath: string;

  constructor() {}

  ngOnInit(): void {
    if (this.typeProperty?.referencedTypeRole === 'family') {
      this.baseCriteria = { roles: 'family' };
    } else if (this.typeProperty?.referencedTypeRole === 'option') {
      this.baseCriteria = { roles: 'option', isPrimary: true };
    }

    const isReferenceToRoot = this.typeProperty.referencedTypeRootSlug === this.typeProperty.referencedTypePath;
    if (!isReferenceToRoot) {
      this.typePath = this.typeProperty.referencedTypePath;
    }
  }

  handleSelection(entities: Array<any>) {
    if (entities?.length > 0) {
      this.entitySelected.emit(entities[0]);
    }
  }

  handleClose() {
    this.close.emit();
  }
}
