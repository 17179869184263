import { DocumentAction, DocumentChangeType, DocumentElement } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { TableService } from '../../canvas/elements/table/table-manager/table.service';
import { DocumentService } from '../document.service';

export class DocumentTableService {
  constructor(private documentService: DocumentService) {}

  public add(direction: 'row' | 'column', index, tableElement: DocumentElement, childElements: DocumentElement[]) {
    const isColumn = direction === 'column';
    const key = isColumn ? 'columnIds' : 'rowIds';
    const undoElementData = {
      id: tableElement.id,
      size: { ...tableElement.size },
      type: tableElement.type,
      tableId: tableElement.id,
      [key]: [...tableElement[key]],
    };
    const newElements = isColumn
      ? TableService.addColumn(tableElement, childElements, index)
      : TableService.addRow(tableElement, childElements, index);

    const actions = newElements.map((el, i) => {
      const action = new DocumentAction(
        {
          elementId: el.id,
          changeType: DocumentChangeType.ADD_ELEMENT,
          elementData: el,
        },
        {
          elementId: el.id,
          changeType: DocumentChangeType.DELETE_ELEMENT,
          elementData: el,
        },
      );
      return action;
    });
    this.documentService.handleDocumentActions([
      ...actions,
      new DocumentAction(
        {
          elementId: tableElement.id,
          changeType: DocumentChangeType.MODIFY_ELEMENT,
          elementData: {
            id: tableElement.id,
            size: tableElement.size,
            type: tableElement.type,
            tableId: tableElement.id,
            [key]: [...tableElement[key]],
          },
        },
        {
          elementId: tableElement.id,
          changeType: DocumentChangeType.MODIFY_ELEMENT,
          elementData: undoElementData,
        },
      ),
    ]);
  }

  public move(direction: 'column' | 'row', tableElement: DocumentElement, from: number, to: number) {
    const isColumn = direction === 'column';
    const key = isColumn ? 'columnIds' : 'rowIds';
    const undoElementData = {
      id: tableElement.id,
      type: tableElement.type,
      tableId: tableElement.id,
      [key]: [...tableElement[key]],
    };
    TableService.move(key, tableElement, from, to);
    this.documentService.handleDocumentActions([
      new DocumentAction(
        {
          elementId: tableElement.id,
          changeType: DocumentChangeType.MODIFY_ELEMENT,
          elementData: {
            id: tableElement.id,
            type: tableElement.type,
            tableId: tableElement.id,
            [key]: [...tableElement[key]],
          },
        },
        {
          elementId: tableElement.id,
          changeType: DocumentChangeType.MODIFY_ELEMENT,
          elementData: undoElementData,
        },
      ),
    ]);
  }

  public clear(cells: DocumentElement[]) {
    const actions = [];
    cells.forEach((cell) => {
      const undoElementData: DocumentElement = {
        id: cell.id,
        text: cell.text,
        type: cell.type,
        size: { ...cell.size },
      };
      TableService.clearCell(cell);
      actions.push(
        new DocumentAction(
          {
            elementId: cell.id,
            changeType: DocumentChangeType.MODIFY_ELEMENT,
            elementData: {
              id: cell.id,
              text: cell.text,
              type: cell.type,
              size: { ...cell.size },
            },
          },
          {
            elementId: cell.id,
            changeType: DocumentChangeType.MODIFY_ELEMENT,
            elementData: undoElementData,
          },
        ),
      );
    });
    if (actions.length > 0) {
      this.documentService.handleDocumentActions(actions);
    }
  }

  public delete(
    direction: 'column' | 'row',
    tableElement: DocumentElement,
    childElements: DocumentElement[],
    indexes: number[],
  ) {
    const isColumn = direction === 'column';
    const key = isColumn ? 'columnIds' : 'rowIds';
    const undoElementData = {
      id: tableElement.id,
      type: tableElement.type,
      tableId: tableElement.id,
      size: { ...tableElement.size },
      [key]: [...tableElement[key]],
    };
    const elements = isColumn
      ? TableService.deleteColumns(tableElement, childElements, indexes)
      : TableService.deleteRows(tableElement, childElements, indexes);
    this.documentService.handleDocumentActions([
      new DocumentAction(
        {
          elementId: tableElement.id,
          changeType: DocumentChangeType.MODIFY_ELEMENT,
          elementData: {
            id: tableElement.id,
            type: tableElement.type,
            tableId: tableElement.id,
            size: { ...tableElement.size },
            [key]: [...tableElement[key]],
          },
        },
        {
          elementId: tableElement.id,
          changeType: DocumentChangeType.MODIFY_ELEMENT,
          elementData: undoElementData,
        },
      ),
      ...elements.map(
        (element) =>
          new DocumentAction(
            {
              elementId: element.id,
              changeType: DocumentChangeType.DELETE_ELEMENT,
              elementData: {
                id: element.id,
                type: element.type,
                tableId: element.tableId,
              },
            },
            {
              elementId: element.id,
              changeType: DocumentChangeType.ADD_ELEMENT,
              elementData: ObjectUtil.cloneDeep(element),
            },
          ),
      ),
    ]);
  }

  public deleteTable(tableElement: DocumentElement, childElements: DocumentElement[]) {
    const actions = [tableElement, ...childElements]?.map(
      (el) =>
        new DocumentAction(
          {
            changeType: DocumentChangeType.DELETE_ELEMENT,
            elementId: el.id,
          },
          {
            changeType: DocumentChangeType.ADD_ELEMENT,
            elementId: el.id,
            elementData: el,
          },
        ),
    );
    this.documentService.handleDocumentActions(actions);
  }

  public paste(
    tableElement: DocumentElement,
    childElements: DocumentElement[],
    elementsToPaste: DocumentElement[],
    targetRow: number,
    targetColumn: number,
  ) {
    const { elementsToUpdate, elementsToCreate } = TableService.pasteTableCells(
      tableElement,
      childElements,
      elementsToPaste,
      targetRow,
      targetColumn,
    );
    const actions: DocumentAction[] = [];
    if (elementsToUpdate?.length > 0) {
      elementsToUpdate.map(({ change, undo }) => {
        actions.push(
          new DocumentAction(
            {
              elementId: change.id,
              changeType: DocumentChangeType.MODIFY_ELEMENT,
              elementData: change,
            },
            {
              elementId: undo.id,
              changeType: DocumentChangeType.MODIFY_ELEMENT,
              elementData: undo,
            },
          ),
        );
      });
    }
    if (elementsToCreate?.length > 0) {
      elementsToCreate.map((e) => {
        actions.push(
          new DocumentAction(
            {
              elementId: e.id,
              changeType: DocumentChangeType.ADD_ELEMENT,
              elementData: e,
            },
            {
              elementId: e.id,
              changeType: DocumentChangeType.DELETE_ELEMENT,
              elementData: e,
            },
          ),
        );
      });
    }
    this.documentService.handleDocumentActions(actions);
  }
}
