<div class="header" *ngIf="showHeader">
  <div class="title">{{ chooserTitleText }}</div>
  <div (click)="close.emit(); selectedItems = []" class="cursor-pointer flex-center" data-test="item-chooser-close">
    <mat-icon>close</mat-icon>
  </div>
</div>

<div class="chooser !-mt-3" *ngIf="!showSelectSource">
  <div class="source-wrapper centered-vertically" *ngIf="!hideSource">
    <div class="centered-vertically">
      <img
        class="source-icon {{ (chooserSourceOption$ | async)?.class || '' }}"
        [src]="(chooserSourceOption$ | async)?.icon"
        (click)="toggleSourceSourceSelector(true)"
        *ngIf="(chooserSourceOption$ | async)?.icon"
      />
      <app-item-data-source-path
        [chooserSource]="chooserSourceOption$ | async"
        [filterCriteria]="filterDefinition?.filterCriteria"
        [enableSubTypeSelection]="isItemTypeNavigable"
        (updateSourceItemType)="applyItemTypeFilter($event)"
      ></app-item-data-source-path>
    </div>
    <div class="cursor-pointer text-accent" data-test="change-assortment" (click)="toggleSourceSourceSelector(true)">
      Change
    </div>
  </div>

  <div class="search-bar-wrapper">
    <app-search-bar [placeholder]="'Search items'"></app-search-bar>
  </div>

  <div class="menu-wrapper" *ngIf="showFilter">
    <div class="menu-wrapper-left">
      <div class="mr-3 min-w-[16px]">
        <mat-checkbox
          *ngIf="allowAddMultipleEntities && (dataSource?.results$ | async)?.length"
          color="primary"
          (change)="handleToggleSelectAll($event)"
          [disabled]="!hasLoaded"
          [checked]="isSelectAllChecked"
          #selectItemCheckbox
          data-test="toggle-all-items"
          disableRipple="true"
        >
        </mat-checkbox>
      </div>

      <div class="sort-filter-menu">
        <app-menu
          data-test="app-filter-panel"
          name="Filter"
          icon="filter_list"
          position="left"
          [highlight]="isFilterActive"
        >
          <app-filter-panel
            [equalsOnly]="dataSourceType === 'ITEM_LIBRARY'"
            [sourceValues]="dataSource?.assortmentItemData$ | async"
            [filterDefinition]="filterDefinition"
            [propertyTypeDefaultFilterConditions]="propertyTypeDefaultFilterConditions"
            (filterCriteriaChange)="setFilterCriteria($event)"
          >
          </app-filter-panel>
        </app-menu>

        <app-menu data-test="app-sort-panel" name="Sort" icon="sort" position="left" [highlight]="currentSorts?.length">
          <app-sort-panel
            data-test="sort-panel"
            [currentSorts]="currentSorts"
            [properties]="sortProperties"
            (performSort)="performSort($event)"
          >
          </app-sort-panel>
        </app-menu>
      </div>

      <div class="item-action-menu-container">
        <mat-button-toggle-group
          *ngIf="allowItemLevelSelection && !isClipboard"
          color="primary"
          [value]="level"
          (change)="toggleFamilyOnly($event)"
        >
          <mat-button-toggle value="family" data-test="search-family-level" matTooltip="Item level">
            <mat-icon class="!text-[18px] !h-[18px]" svgIcon="tag"></mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="option" data-test="search-option-level" matTooltip="Option level">
            <mat-icon class="!text-[18px] !h-[18px]" svgIcon="hierarchy"></mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="show-all-control" *ngIf="showAllControl">
      <button
        *ngIf="isClipboard"
        class="refresh-button"
        matTooltip="Refresh"
        (click)="refreshData()"
        data-test="refresh-button"
        mat-button
      >
        <mat-icon class="!text-[22px]">refresh</mat-icon>
      </button>
      <app-menu
        name=""
        icon="more_vert"
        position="left"
        [highlight]="dateFilter || !showAll"
        data-test="click-more-horiz"
      >
        <div class="title">Advanced Options</div>
        <mat-divider></mat-divider>
        <div class="advanced-option">
          <mat-slide-toggle
            color="primary"
            (change)="toggleShowAll($event)"
            [checked]="!showAll"
            labelPosition="before"
            data-test="select-all-items-toggle"
            >Eligible only</mat-slide-toggle
          >
          <app-date-filter
            *ngIf="dataSourceType && dataSourceType !== 'ITEM_LIBRARY'"
            [dateFilterAttribute]="dateFilterAttribute"
            [dateFilterDefinition]="dateFilter"
            [dateFilterOption]="selectedDateFilterOption"
            (dateFilterChanged)="dateFilterChanged($event)"
            (dateFilterOptionChanged)="dateFilterOptionChanged($event)"
          >
          </app-date-filter>
        </div>
      </app-menu>
      <div class="count">{{ ((dataSource?.results$ | async)?.length | number) || 0 }}</div>
    </div>
  </div>
  <mat-divider></mat-divider>

  <div
    class="data-pane"
    *ngIf="(dataSource?.results$ | async)?.length && hasLoaded"
    data-test="item-chooser-items-list"
  >
    <virtual-scroller
      [style.height]="resultsHeight"
      #scroll
      [items]="dataSource?.results$ | async"
      class="narrow-scroll"
      (vsEnd)="handleScrollEnd($event)"
    >
      <app-item-data-chooser-card
        data-test="item-data-chooser-card"
        [ngClass]="activeEntityId === dataObj.id ? 'active-entity' : ''"
        [draggable]="draggable && isItemSelectable(dataObj)"
        [id]="dataObj.id"
        (dragstart)="startDrag($event)"
        (click)="handleClick(dataObj)"
        (add)="addItemData($event)"
        (toggleSelection)="toggleSelection($event)"
        class="data-row"
        [dataObj]="dataObj"
        [level]="level"
        [allowAdd]="allowAddEntity"
        [allowAddMultiple]="allowAddMultipleEntities"
        [allowAddDuplicate]="allowAddDuplicate"
        [isExistingItem]="isExistingItemOnSource(dataObj)"
        [isClipboardItem]="isClipboard"
        [selectedItemIndex]="getSelectedItemIndex(dataObj)"
        [allowShowDetails]="allowShowDetails"
        [isDisabled]="isItemDisabled(dataObj)"
        [isMaxNumberOfItemsSelected]="selectedItems?.length >= maximumItemCount"
        [class.disabled]="isItemDisplayDisabled(dataObj)"
        *ngFor="let dataObj of scroll.viewPortItems"
      >
      </app-item-data-chooser-card>
      <div class="flex justify-center mt-5" *ngIf="areMoreResultsLoading">
        <mat-progress-spinner diameter="30" mode="indeterminate"> </mat-progress-spinner>
      </div>
    </virtual-scroller>
  </div>
  <mat-divider></mat-divider>

  <div class="action" *ngIf="(dataSource?.results$ | async)?.length && hasLoaded && allowAddEntity">
    <ng-container *ngIf="selectedItems?.length && allowAddMultipleEntities">
      <button
        *ngIf="isClipboard"
        data-test="clear-selected-items-button"
        mat-raised-button
        color="primary"
        class="!mr-2 clear-selected"
        (click)="handleDeleteClipboardItems()"
      >
        CLEAR ({{ selectedItems?.length }})
      </button>
      <button
        data-test="add-selected-items-button"
        mat-raised-button
        color="primary"
        class="add-selected"
        (click)="handleAddSelectedItems()"
      >
        ADD SELECTED ({{ selectedItems?.length }})
      </button>
    </ng-container>
    <ng-container *ngIf="!selectedItems?.length && isClipboard">
      <button
        data-test="clear-all-items-button"
        mat-raised-button
        color="primary"
        class="!mr-2 clear-all-button"
        (click)="handleClearClipboard()"
      >
        CLEAR ALL
      </button>
      <button
        data-test="add-all-items-button"
        mat-raised-button
        color="primary"
        class="add-all-button"
        (click)="handleAddAllItems()"
      >
        ADD ALL
      </button>
    </ng-container>
  </div>

  <app-empty-placeholder
    class="!m-auto overflow-hidden max-w-[195px]"
    *ngIf="(dataSource?.results$ | async)?.length === 0 && hasLoaded"
    [title]="title"
    [icon]="icon"
    [footer]="footer"
  ></app-empty-placeholder>
  <div class="loading-container" *ngIf="!hasLoaded">
    <mat-progress-spinner diameter="50" mode="indeterminate"></mat-progress-spinner>
    <div class="message">Searching...</div>
  </div>
</div>

<div [style.display]="showSelectSource ? 'block' : 'none'" class="source-selector-container">
  <app-item-data-source-selector
    (selectSource)="handleSourceChange($event)"
    [sourceAssortment]="sourceAssortment"
    (cancel)="toggleSourceSourceSelector(false)"
  >
  </app-item-data-source-selector>
</div>
