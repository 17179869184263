import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ChooserSourceOption } from '../source-option';
import { SubTypesHelper, TypeListEntry } from '@contrail/sdk';
import { FilterCriteria } from '@contrail/filters';

@Component({
  selector: 'app-item-data-source-path',
  templateUrl: './item-data-source-path.component.html',
  styleUrls: ['./item-data-source-path.component.scss'],
})
export class ItemDataSourcePathComponent implements OnChanges {
  @Input() chooserSource: ChooserSourceOption;
  @Input() filterCriteria: FilterCriteria;
  @Input() enableSubTypeSelection: boolean;
  @Output() updateSourceItemType = new EventEmitter<string>();

  private itemTypes: TypeListEntry[];
  public selectedItemType: TypeListEntry;
  public maxPathLength = 30;

  constructor() {}

  async ngOnChanges(): Promise<void> {
    if (!this.itemTypes) {
      this.itemTypes = await SubTypesHelper.getTypeListFromPath('item');
    }

    this.setFilteredOnTypeName();
  }

  private setFilteredOnTypeName(): void {
    const typeFilter = this.filterCriteria?.propertyCriteria?.find(
      (criteria) => criteria?.filterPropertyDefinition?.slug === 'type',
    );

    const selectedType = this.itemTypes?.find((type) => type.id === typeFilter?.criteriaValue);
    if (!selectedType || !this.enableSubTypeSelection) {
      this.selectedItemType = null;
      this.maxPathLength = 30;
      return;
    }

    this.selectedItemType = selectedType;
    this.maxPathLength = 15;
  }

  public setSourceItemType(typeId: string) {
    const isSourceTypeUnchanged = typeId === this.selectedItemType?.id || (!typeId && !this.selectedItemType);

    if (isSourceTypeUnchanged || !this.enableSubTypeSelection) {
      return;
    }

    this.updateSourceItemType.emit(typeId);
  }
}
