<div class="flex">
  <button
    class="border-style-button"
    #optionListMenuTrigger="matMenuTrigger"
    [matMenuTriggerFor]="optionActionMenu"
    (menuOpened)="handleOptionListMenuOpened()"
    mat-icon-button
    matTooltip="Manage item & options"
    matTooltipPosition="above"
    data-test="item-option-context"
  >
    <mat-icon class="icons material-icons-outlined" svgIcon="hierarchy"></mat-icon>
  </button>

  <mat-menu #optionActionMenu="matMenu" class="border-style-menu text-black60 min-w-[250px]">
    <div *ngIf="queryItemOptionsInProgress" class="option" mat-menu-item>
      <mat-spinner diameter="20"></mat-spinner>
    </div>
    <div class="w-full h-full my-1" *ngIf="!queryItemOptionsInProgress">
      <div
        class="option"
        [disabled]="!contextualEntity?.optionName"
        data-test="item-component-remove-option"
        mat-menu-item
        (click)="removeOption()"
      >
        Switch to item level
      </div>
      <div
        *ngIf="entityType?.canCreate"
        mat-menu-item
        class="option flex select-none w-[220px]"
        (click)="preventClose($event)"
      >
        <div>Create options</div>
        <app-counter-widget
          [defaultValue]="1"
          [maxValue]="10"
          [minValue]="1"
          (countChange)="updateCount($event)"
        ></app-counter-widget>
        <div>
          <button
            class="flex text-[12px] ml-3 text-primary"
            [ngClass]="{ 'opacity-30': !itemCount }"
            [disabled]="!itemCount"
            data-test="item-component-create-option-button"
            (click)="addItemOptions()"
          >
            OK
          </button>
        </div>
      </div>
      <div
        class="option"
        [disabled]="itemOptions?.length === 0"
        data-test="item-component-switch-option"
        mat-menu-item
        [matMenuTriggerFor]="itemOptions?.length > 0 && optionAssignMenu"
      >
        Switch option
      </div>
      <div
        class="option"
        *ngIf="entityType?.canCreate"
        mat-menu-item
        data-test="item-component-copy-item"
        (click)="copyItem()"
      >
        Quick item copy
      </div>
      <div class="option" mat-menu-item data-test="item-component-add-to-clipboard" (click)="addToClipboard()">
        Add to clipboard
      </div>
    </div>
  </mat-menu>

  <mat-menu #optionAssignMenu="matMenu" class="text-black60">
    <div
      mat-menu-item
      class="option"
      [disabled]="option.id === contextualEntity.id"
      *ngFor="let option of itemOptions"
      (click)="assignOption(option)"
    >
      {{ option.optionName }}
    </div>
  </mat-menu>
</div>
