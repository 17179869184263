import { Injectable } from '@angular/core';
import { Assortment } from '@common/assortments/assortment';
import { Workspace } from '@common/workspaces/workspaces-store/workspaces.state';
import {
  Document,
  DocumentAction,
  DocumentChangeType,
  DocumentElement,
  DocumentNavigationEvent,
  PositionDefinition,
} from '@contrail/documents';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { UndoRedoService } from 'src/app/common/undo-redo/undo-redo-service';
import { WebSocketService } from 'src/app/common/web-socket/web-socket.service';
import { RootStoreState, UserSessionActions } from 'src/app/root-store';
import { BoardsActions, BoardsSelectors } from '../boards-store';
import { BoardsBackingAssortmentService } from './backing-assortment/boards-backing-assortment-service';
import { DocumentService } from './document/document.service';
import { DocumentManagerActions } from './document-manager/document-manager-store';
import { AssortmentsSelectors } from '@common/assortments/assortments-store';
import { BoardAnnotationService } from './annotation/board-annotation.service';
import { DocumentStatusMessageService } from './document/document-status/document-status-message.service';
import { debounceTime } from 'rxjs/operators';
import { CollectionStatusMessage } from '@common/collection-status-message/collection-status-message';
import { Actions, ofType } from '@ngrx/effects';
import { DocumentAnnotationService } from './document/document-annotation/document-annotation-service';
import { ZoomPanHandler } from './zoom-pan-handler';
import { ObjectUtil } from '@contrail/util';
import { FileDownloader } from './canvas/file-downloader';
import { environment } from 'src/environments/environment';
import { BoardPropertyPoliciesService } from './board-property-policies/board-property-policies.service';
import { DocumentComponentService } from './document/document-component/document-component-service';
import { DocumentGeneratorService } from './document-generator/document-generator.service';
import { DocumentCreateItemsService } from './document/document-item/document-create-item/document-create-items.service';
import { CoordinateBox } from '@contrail/svg';

export interface Board {
  name?: string;
  id?: string;
  document?: Document;
  backingAssortmentId?: string;
  backingAssortment?: Assortment;
  sourceAssortment?: any;
  workspaceId?: string;
  workspace?: Workspace;
  primaryContext?: any;
}
@Injectable({
  providedIn: 'root',
})
export class BoardService {
  public fullScreen$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public showMinimap$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public showPlaceholderCreation$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private currentBoardObject: Board = null;
  private currentBoardSubject: BehaviorSubject<Board> = new BehaviorSubject(this.currentBoardObject);
  public currentBoard: Observable<Board> = this.currentBoardSubject.asObservable();

  private backingAssortmentItems: any[];
  private statusMessages: any[];
  public zoomPanHandler: ZoomPanHandler;

  public lastLocation = null;
  public isSharedLinkUser = false;

  constructor(
    private documentService: DocumentService,
    private store: Store<RootStoreState.State>,
    private actions$: Actions,
    private webSocketService: WebSocketService,
    private backingAssortmentService: BoardsBackingAssortmentService,
    private undoRedoService: UndoRedoService,
    private boardAnnotationService: BoardAnnotationService,
    private annotationMessageService: DocumentStatusMessageService,
    private boardPropertyPoliciesService: BoardPropertyPoliciesService,
    private documentAnnotationService: DocumentAnnotationService,
    private documentComponentService: DocumentComponentService,
    private documentGeneratorService: DocumentGeneratorService,
    private documentCreateItemsService: DocumentCreateItemsService,
  ) {
    this.actions$.pipe(ofType(BoardsActions.BoardsActionTypes.CLEAR_BOARD)).subscribe(() => {
      // Clear status messages when changing boards.
      this.documentService.elementsAdded$.next(false);
      this.boardAnnotationService.clear();
      this.zoomPanHandler = null;
      this.currentBoardObject = null;
    });

    this.store.select(BoardsSelectors.currentBoard).subscribe((board) => {
      if (!board) {
        return;
      }

      if (this.currentBoardObject?.id !== board?.id) {
        this.showMinimap$.next(false);
        this.showPlaceholderCreation$.next(false);
        this.currentBoardObject = JSON.parse(JSON.stringify(board));
        this.currentBoardObject.document = this.currentBoardObject.document || {
          size: { height: 900, width: 1600 },
          elements: [],
        };
        this.currentBoardSubject.next(this.currentBoardObject);
        this.backingAssortmentService.initBackingAssortment(board);
      }

      this.updateMaxZoomOut();
    });

    // Init service for status messages (including warnings)
    this.annotationMessageService.init(
      this.store.select(BoardsSelectors.collectionStatusMessages) as Observable<Array<CollectionStatusMessage>>,
    );

    // Assign status message annotations (warnings)
    combineLatest(
      this.documentService.elementsAdded$.pipe(debounceTime(500)),
      this.store.select(BoardsSelectors.showSourceAssortmentWarning),
      this.annotationMessageService.statusMessages.pipe(debounceTime(1000)),
    ).subscribe(([elementsAdded, showSourceAssortmentWarning, statusMessages]) => {
      if (elementsAdded && statusMessages) {
        this.statusMessages = statusMessages;
        this.setElementAnnotations(true);
      }
    });

    // Every time new elements are added and when backing assortment items are changes,
    // recalculate annotations for all component elements. We recalculate them for entire
    // presentation so frame previews could be updated too
    combineLatest(
      this.documentService.elementsAdded$.pipe(debounceTime(500)),
      this.store.select(AssortmentsSelectors.backingAssortmentItems),
    ).subscribe(([elementsAdded, backingAssortmentItems]) => {
      if (elementsAdded && backingAssortmentItems) {
        this.backingAssortmentItems = backingAssortmentItems;
        this.setElementAnnotations();
      }
    });

    combineLatest(
      this.documentService.elementsAdded$.pipe(debounceTime(1000)),
      this.documentAnnotationService.annotatedElements$,
    ).subscribe(([elementsAdded, annotatedElements]) => {
      if (elementsAdded && annotatedElements?.length > 0) {
        this.documentService.setAnnotations(annotatedElements);
      }
    });

    this.documentService.documentActions.subscribe(this.handleDocumentActions.bind(this));
    this.documentService.backgroundUpdateDocumentActions.subscribe(
      this.handleBackgroundUpdateDocumentActions.bind(this),
    );
    this.documentService.documentNavigationEvents.subscribe(this.handleDocumentNavigationEvents.bind(this));
    this.documentService.interactionModeChanged$.pipe(debounceTime(500)).subscribe((mode) => {
      this.handleDocumentInteractionModeChanged(mode);
    });
  }

  private setElementAnnotations(statusMessagesOnly = false) {
    if (statusMessagesOnly) {
      this.boardAnnotationService.setElementAnnotations(this.statusMessages);
    } else {
      this.boardAnnotationService.setElementAnnotationByAssortmentItems(this.backingAssortmentItems);
    }
  }

  private handleBackgroundUpdateDocumentActions(actions) {
    if (!actions || actions.length === 0) {
      return;
    }
    const documentId = actions[0].changeDefinition.documentId;
    // Set file url to current session
    this.documentService.applyDocumentActions(actions, true);
    // Send file url to remote sessions
    this.documentService.sendSessionEvent(actions, { id: documentId });
  }

  /** Handles document actions:
   * - Adds to undo / redo stack
   * - Applys changes to the underlying document (via document service)
   * - Broadcasts the actions via websocket.
   * - Updates the board
   */
  private handleDocumentActions(actions) {
    console.log('BoardService: handleDocumentActions:', actions);
    if (!actions || actions?.length === 0) {
      return;
    }

    const changeDefinition = actions[0].changeDefinition;
    if (
      changeDefinition.changeType === 'ADD_ELEMENT' &&
      ['new_item_family', 'new_typed_item_family', 'new_item_option', 'item_copy'].includes(
        changeDefinition?.elementData?.type,
      )
    ) {
      if (changeDefinition.elementData.type === 'item_copy') {
        this.documentComponentService.copyItem(changeDefinition.elementData, true);
      } else if (['new_item_family', 'new_typed_item_family'].includes(changeDefinition.elementData.type)) {
        this.documentCreateItemsService.createAndAddNewItemFamilies(
          {
            position: changeDefinition.elementData.position,
            style: {},
          },
          changeDefinition.elementData.type === 'new_item_family',
        );
      } else {
        let itemFamily = null;
        if (changeDefinition.elementData.modelBindings?.item) {
          const itemId = changeDefinition.elementData.modelBindings.item.split(':')[1];
          const backingAssortmentItem = this.backingAssortmentItems.find((ai) => ai.item.id === itemId);
          if (backingAssortmentItem) {
            itemFamily = {
              itemFamilyId: backingAssortmentItem.item.itemFamilyId,
            };
            const options = { position: changeDefinition.elementData.position, style: {}, elements: [] };
            options.position.x = options.position.x + 50;
            options.style = changeDefinition.elementData.style;
            options.elements = changeDefinition.elementData.elements;
            this.documentCreateItemsService.createAndAddNewItemOptions(options, itemFamily, {}, true);
          } else {
            console.error('Item not found in backing assortment:', itemId, this.backingAssortmentItems);
          }
        } else {
          this.documentCreateItemsService.createAndAddNewItemOptions(
            {
              position: changeDefinition.elementData.position,
              style: {},
            },
            null,
            null,
            true,
          );
        }
      }
      return;
    }

    this.boardPropertyPoliciesService.handleDocumentActions(actions);
    // 1. Update document with that new element.
    // 2. Add that element item to backing assortment. This needs to happen after document is updated because it checks current document for that new element item.
    this.store.dispatch(DocumentManagerActions.handleDocumentElementActions({ actions }));
    this.backingAssortmentService.debounceProcessDocumentActions({
      actions,
      document: this.currentBoardObject.document,
    });
    // Commenting this out for now as it is causing issues with text elements
    // Potential enhacement: Only pan if the cursor is outside of the viewPort

    // this.adjustPanForTextElementChanges(actions);

    this.updateMaxZoomOut();
  }

  private updateMaxZoomOut() {
    const minZoomFactor = this.calculateZoomFactorForZoomToFit();
    if (minZoomFactor) {
      this.zoomPanHandler?.setMinZoomFactor(minZoomFactor);
    }
  }

  public calculateZoomFactorForZoomToFit(): number | null {
    const bound: CoordinateBox = this.documentService.getAllCommonBounds();
    if (!bound || (bound.width === 0 && bound.height === 0)) {
      // empty board
      return;
    }

    const renderer: any = this.documentService.documentRenderer;
    const canvasSize = renderer.state?.canvasDocument?.canvasDisplay?.canvasSize;
    const width = canvasSize.width - 200;
    const height = canvasSize.height - 240;

    const zoomFactorForZoomToFix = Math.max(bound.width / width, bound.height / height);
    return isNaN(zoomFactorForZoomToFix) ? null : zoomFactorForZoomToFix;
  }

  async init(boardId: string) {
    await this.boardPropertyPoliciesService.getTypes();
    this.store.dispatch(BoardsActions.loadCurrentBoard({ id: boardId }));
    this.store.dispatch(UserSessionActions.loadRemoteUsers({ sessionId: 'board:' + boardId }));
    this.store.dispatch(UserSessionActions.joinSession({ sessionId: 'board:' + boardId }));
  }

  initZoomPanHandler(zoomPanHandler: ZoomPanHandler) {
    this.zoomPanHandler = zoomPanHandler;
  }

  async clearCurrentBoard() {
    this.currentBoardSubject.next(null);
    this.store.dispatch(BoardsActions.loadCurrentBoardSuccess({ board: null }));
  }

  public broadcastMouseMove(mousePosition: PositionDefinition) {
    this.webSocketService.sendSessionEvent({
      eventType: 'REMOTE_USER_MOUSE_MOVED',
      changes: {
        mousePosition,
      },
    });
  }

  /**
   * Apply @actions to current document and update store.
   * This functions is used by websockets to sync the document
   * with remote user updates.
   * @param actions
   * @param skipSelect
   * @returns
   */
  public async handleDocumentElementsUpdated(actions: DocumentAction[], skipSelect = false) {
    if (actions?.length === 0) {
      return;
    }

    if (
      actions.length === 1 &&
      actions[0].changeDefinition.changeType === DocumentChangeType.REORDER_ELEMENT &&
      typeof actions[0].changeDefinition?.elementData === 'string'
    ) {
      const fileUrl = actions[0].changeDefinition?.elementData;
      const authContext = await this.documentService.authService.getAuthContext();
      const fileDownLoader = new FileDownloader(
        { apiToken: authContext.token, orgSlug: authContext.currentOrg.orgSlug },
        { imageHost: environment.imageHost },
      );
      const text = await fileDownLoader.downloadFileAsText(fileUrl);
      console.log('Getting reordered document elements from S3', fileUrl);
      let documentElements;
      try {
        documentElements = JSON.parse(text)?.documentElements;
      } catch (error) {
        return;
      }
      if (!documentElements) {
        return;
      }
      actions = actions.map((action) => {
        return new DocumentAction({
          changeType: action.changeDefinition.changeType,
          documentId: action.changeDefinition.documentId,
          elementData: documentElements,
        });
      });
    }
    this.documentService.applyDocumentActions(ObjectUtil.cloneDeep(actions), skipSelect);
    this.store.dispatch(DocumentManagerActions.updateDocumentElementStore({ actions }));
    const changeType = actions[0].changeDefinition.changeType;
    if (
      [DocumentChangeType.MODIFY_ELEMENT, DocumentChangeType.ADD_ELEMENT, DocumentChangeType.DELETE_ELEMENT].indexOf(
        changeType,
      ) !== -1
    ) {
      this.documentService.handleDocumentElementEvent({
        element: null,
        eventType: 'dragEnded',
      });
    }
  }

  /**
   * Handle adding @selectedElements on @frameId by remote user.
   * @param frameId
   * @param selectedElements
   */
  public handleAddElementsOnFrame(frameId: string, selectedElements: DocumentElement[]) {
    this.documentService.addElementsOnFrame(frameId, selectedElements);
  }

  /**
   * Handle removing @selectedElements by remote user.
   * @param selectedElements
   */
  public handleRemoveElementsFromFrame(selectedElements: DocumentElement[]) {
    this.documentService.removeElementsFromFrame(selectedElements);
  }

  /**
   * Set starting view box parameters for the document.
   */
  public setStartingLocation() {
    const viewBox = this.zoomPanHandler?.viewBox;
    if (viewBox && this.currentBoardObject?.document?.id) {
      this.currentBoardObject.document.startingLocation = {
        position: {
          x: viewBox.x,
          y: viewBox.y,
        },
        zoom: this.zoomPanHandler?.zoomFactor,
      };
      this.store.dispatch(
        DocumentManagerActions.updateDocument({
          id: this.currentBoardObject.document.id,
          document: {
            startingLocation: this.currentBoardObject.document.startingLocation,
          },
          message: 'Starting location has been set.',
        }),
      );
    }
  }

  public checkLastLocation(boardId) {
    const key = `lastLocation_` + boardId;
    const lastLocation = JSON.parse(localStorage.getItem(key));

    if (lastLocation) {
      this.lastLocation = { ...lastLocation };
    } else {
      this.lastLocation = null;
    }
  }

  private handleDocumentNavigationEvents(event: DocumentNavigationEvent) {
    if (event?.eventType === 'setLocation' && event.data && this.zoomPanHandler) {
      const zoomFactor = this.zoomPanHandler.clampZoomFactor(event.data.zoomFactor);
      const viewBox = {
        x: event.data.position.x,
        y: event.data.position.y,
        width: window.innerWidth * zoomFactor,
        height: window.innerHeight * zoomFactor,
      };
      this.setZoomFactor(zoomFactor);
      this.setViewPort(viewBox);
    }
  }

  public setViewPort(viewBox) {
    this.zoomPanHandler?.setViewPort(viewBox);
  }

  public setZoomFactor(zoomFactor) {
    this.zoomPanHandler?.setZoomFactor(zoomFactor);
  }

  public gotoLastLocation() {
    const zoomFactor = this.lastLocation.zoom;
    const viewBox = {
      x: this.lastLocation.position.x,
      y: this.lastLocation.position.y,
      width: window.innerWidth * zoomFactor,
      height: window.innerHeight * zoomFactor,
    };
    this.zoomPanHandler.setZoomFactor(zoomFactor);
    this.zoomPanHandler.setViewPort(viewBox, false);
  }

  /**
   * Update current view box and zoom to current starting location of the document
   */
  public navigateToStartingLocation(animate = true) {
    if (!this.zoomPanHandler || !this.currentBoardObject?.document) {
      return;
    }
    const zoomFactor = this.currentBoardObject.document?.startingLocation?.zoom || 1.0;
    const viewBox = {
      x: this.currentBoardObject.document?.startingLocation?.position?.x || 0,
      y: this.currentBoardObject.document?.startingLocation?.position?.y || 0,
      width: window.innerWidth * zoomFactor,
      height: window.innerHeight * zoomFactor,
    };
    this.zoomPanHandler.setZoomFactor(zoomFactor);
    this.zoomPanHandler.setViewPort(viewBox, animate);
  }

  public assignSourceAssortment(sourceAssortmentId: string) {
    this.store.dispatch(BoardsActions.assignBoardAssortment({ id: this.currentBoardObject.id, sourceAssortmentId }));
  }

  public assignSourceAssortmentAndRefresh(board: any) {
    this.store.dispatch(BoardsActions.assignBoardAssortmentSuccess({ board }));
  }

  public clearSourceAssortment() {
    this.store.dispatch(BoardsActions.clearBoardSourceAssortment({ id: this.currentBoardObject.id }));
  }

  public clearSourceAssortmentAndRefresh(board: any) {
    this.store.dispatch(BoardsActions.clearBoardSourceAssortmentSuccess({ board }));
  }

  public generateFrames(data: any, startingPosition: PositionDefinition) {
    this.documentGeneratorService.generateFrames(data, startingPosition);
  }

  fullScreen() {
    const ele: any = document.documentElement;
    if (ele?.requestFullscreen) {
      /* Chrome */
      ele.requestFullscreen();
    } else if (ele?.webkitRequestFullscreen) {
      /* Safari */
      ele.webkitRequestFullscreen();
    } else if (ele?.mozRequestFullScreen) {
      /* Firefox */
      ele.mozRequestFullScreen();
    } else if (ele?.msRequestFullScreen) {
      /* IE11 */
      ele.msRequestFullScreen();
    }
    this.fullScreen$.next(true);
  }

  exitFullScreen() {
    const doc: any = document;
    if (doc?.fullscreenElement) {
      /* Chrome */
      doc?.exitFullscreen();
    } else if (doc?.webkitFullscreenElement) {
      /* Safari */
      doc?.webkitExitFullscreen();
    } else if (doc?.mozFullScreenElement) {
      /* Firefox */
      doc?.mozExitFullscreen();
    } else if (doc?.msFullScreenElement) {
      /* IE11 */
      doc?.msExitFullscreen();
    }
    this.fullScreen$.next(false);
  }

  /**
   * Perform pan when edited text element's bottom positionexceeds the bottom of the canvas
   * @param actions
   */

  private adjustPanForTextElementChanges(actions: any) {
    actions.forEach((action) => {
      if (
        action.actionType === 'document' &&
        action.changeDefinition?.changeType === 'MODIFY_ELEMENT' &&
        action.changeDefinition?.elementData?.type === 'text'
      ) {
        const elementData = action.changeDefinition.elementData;

        // Parse HTML content to calculate visible text height
        const parser = new DOMParser();
        const htmlContent = parser.parseFromString(elementData.text, 'text/html');
        const paragraphs = Array.from(htmlContent.body.children); // Convert HTMLCollection to an array

        let totalTextHeight = 0;
        const defaultFontSize = 16; // Fallback font size in pixels
        const defaultLineHeight = 1.2; // Fallback line height multiplier

        paragraphs.forEach((paragraph) => {
          if (paragraph instanceof HTMLElement) {
            const computedFontSize = this.extractFontSizeFromElement(paragraph);
            console.log('computedStyle', computedFontSize, paragraph);
            // Get font size and line height from styles or fallback to defaults
            const fontSize = computedFontSize || defaultFontSize;
            const lineHeight = computedFontSize * defaultLineHeight || fontSize * defaultLineHeight;

            // Estimate number of lines in the paragraph
            const textContent = paragraph.textContent || '';
            const textWidth = this.measureTextWidth(textContent, '', fontSize);
            const paragraphWidth = elementData.size.width;
            const linesInParagraph = Math.ceil(textWidth / paragraphWidth);

            totalTextHeight += linesInParagraph * lineHeight;
          }
        });

        // Calculate text position and compare to viewport
        const yPos = elementData.position.y;
        const textBottomPos = yPos + totalTextHeight;

        const canvasTopPos = this.zoomPanHandler.viewBox.y;
        const canvasBottomPos = canvasTopPos + this.zoomPanHandler.viewBox.height;

        if (textBottomPos > canvasBottomPos || yPos < canvasTopPos) {
          const panDistance =
            textBottomPos > canvasBottomPos ? textBottomPos - canvasBottomPos + 100 : yPos - canvasTopPos - 100;

          this.zoomPanHandler.pan(0, panDistance);
        }
      }
    });
  }

  // Helper function to measure the width of a text string
  private measureTextWidth(text: string, fontFamily: string, fontSize: number): number {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d')!;
    context.font = `${fontSize}px ${fontFamily}`;
    return context.measureText(text).width;
  }

  private extractFontSizeFromElement(element: HTMLElement): number | null {
    // Check if the element itself has inline font-size
    const inlineStyle = element.getAttribute('style') || '';
    const fontSizeMatch = inlineStyle.match(/font-size:\s*(\d+\.?\d*)pt/); // Match "font-size: 24pt"
    if (fontSizeMatch) {
      const fontSizeInPt = parseFloat(fontSizeMatch[1]);
      return fontSizeInPt * 1.333; // Convert points to pixels (1pt = 1.333px)
    }

    // If no inline font-size, traverse child elements for a font-size
    for (const child of Array.from(element.children)) {
      if (child instanceof HTMLElement) {
        const childFontSize = this.extractFontSizeFromElement(child);
        if (childFontSize !== null) {
          return childFontSize;
        }
      }
    }

    // Fallback to computed styles if no inline font-size is found
    const computedFontSize = window.getComputedStyle(element).fontSize;
    if (computedFontSize) {
      const fontSizeInPx = parseFloat(computedFontSize);
      return fontSizeInPx;
    }
    return null;
  }

  private handleDocumentInteractionModeChanged(mode) {
    if (['new_item_option', 'new_item_family'].includes(mode)) {
      this.showPlaceholderCreation$.next(true);
    } else {
      this.showPlaceholderCreation$.next(false);
    }
  }
}
