import { Component, Input } from '@angular/core';
import { EntityReference } from '@contrail/sdk';
import { ContextualEntityHelper } from '../../../contextual-entity-helper';
import { ObjectUtil } from '@contrail/util';
import { ComponentEditorService } from '../../../component-editor/component-editor.service';
import { ProjectItemService } from '@common/projects/project-item.service';

@Component({
  selector: 'app-property-configurator-entity-project-details',
  templateUrl: './entity-project-details.component.html',
  styleUrls: ['./entity-project-details.component.scss'],
})
export class EntityProjectDetailsComponent {
  @Input() selectedElement: any;
  @Input() projectItem: any;
  @Input() workspace: any;
  @Input() contextualEntity: any;

  allProjectItems: any[];
  selectableProjects: any[];
  queryProjectItemsInProgress = false;
  addToCurrentProjectActive = false;

  constructor(
    private contextualEntityHelper: ContextualEntityHelper,
    private componentEditorService: ComponentEditorService,
    private projectItemService: ProjectItemService,
  ) {}

  async handleProjectMenuOpened() {
    this.addToCurrentProjectActive = false;
    this.queryProjectItemsInProgress = true;
    const itemEntityRef = new EntityReference(this.selectedElement.modelBindings.item);
    this.allProjectItems = await this.contextualEntityHelper.getProjectItems(itemEntityRef.id);
    this.selectableProjects = this.allProjectItems
      .filter((projectItem) => !projectItem.isInactive)
      .map((projectItem) => projectItem.project);
    if (
      !this.selectableProjects.find(
        (p) => p.id === this.workspace?.projectId || p.workspace?.id === this.workspace?.rootWorkspaceId,
      )
    ) {
      this.addToCurrentProjectActive = true;
    }
    this.queryProjectItemsInProgress = false;
  }

  public async assignProject(project) {
    if (project.id === this.projectItem?.projectId) {
      await this.projectItemService.upsertProjectItem(this.contextualEntity.id, {});
    } else {
      const updateElement = ObjectUtil.cloneDeep(this.selectedElement);
      updateElement.modelBindings.assortment = null;
      updateElement.modelBindings.assortmentItem = null;
      updateElement.modelBindings.projectItem = 'project-item:' + project.id + ':' + this.contextualEntity.id;
      const bindingChanges = updateElement.modelBindings;
      await this.componentEditorService.updateComponentElement(this.selectedElement, bindingChanges);
    }
  }

  public async assignToCurrentProject() {
    let projectItem;
    if (!this.projectItem) {
      projectItem = { itemId: this.contextualEntity.id, roles: this.contextualEntity.roles };
    } else {
      projectItem = ObjectUtil.cloneDeep(this.projectItem);
    }
    const inactiveProjectItem = this.allProjectItems.find(
      (p) => p.itemId === this.contextualEntity.id && p.projectId === this.workspace.projectId && p.isInactive,
    );
    if (inactiveProjectItem) {
      projectItem.isInactive = false;
    }
    projectItem = await this.projectItemService.carryoverProjectItem(projectItem);
    if (projectItem) {
      const updateElement = ObjectUtil.cloneDeep(this.selectedElement);
      updateElement.modelBindings.projectItem = `project-item:${projectItem.id}`;
      if (updateElement.modelBindings.assortment) {
        updateElement.modelBindings.assortment = null;
      }
      if (updateElement.modelBindings.assortmentItem) {
        updateElement.modelBindings.assortmentItem = null;
      }
      const bindingChanges = updateElement.modelBindings;
      await this.componentEditorService.updateComponentElement(this.selectedElement, bindingChanges);
    }
  }
}
