import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PropertyConfiguratorColorPickerComponent } from './property-configurator-color-picker/property-configurator-color-picker.component';
import { PropertyConfiguratorFontSizeComponent } from './property-configurator-font-size/property-configurator-font-size.component';
import { PropertyConfiguratorSelectorSizeComponent } from './property-configurator-selector-size/property-configurator-selector-size.component';
import { PropertyConfiguratorBorderSizeComponent } from './property-configurator-border-size/property-configurator-border-size.component';
import { PropertyConfiguratorBorderRadiusComponent } from './property-configurator-border-radius/property-configurator-border-radius.component';
import { PropertyConfiguratorBorderStyleComponent } from './property-configurator-border-style/property-configurator-border-style.component';
import { PropertyConfiguratorLineMarkerComponent } from './property-configurator-line-marker/property-configurator-line-marker.component';
import { PropertyConfiguratorFontFamilySelectorComponent } from './property-configurator-font-family-selector/property-configurator-font-family-selector.component';
import { PropertyConfiguratorTextAlignmentComponent } from './property-configurator-text-alignment/property-configurator-text-alignment.component';
import { PropertyConfiguratorBarComponent } from './property-configurator-bar/property-configurator-bar.component';
import { PropertyConfiguratorImageTransparencyComponent } from './property-configurator-image-transparency/property-configurator-image-transparency.component';
import { PropertyConfiguratorEntityDetailsComponent } from './property-configurator-entity-details/property-configurator-entity-details.component';
import { PropertyConfiguratorTextDecoratorComponent } from './property-configurator-text-decorator/property-configurator-text-decorator.component';
import { PropertyConfiguratorDropItemComponent } from './property-configurator-drop-item/property-configurator-drop-item.component';
import { PropertyConfiguratorDownloadComponent } from './property-configurator-download/property-configurator-download.component';
import { PropertyConfiguratorElementIconComponent } from './property-configurator-element-icon/property-configurator-element-icon.component';
import { PropertyConfiguratorListTypeComponent } from './property-configurator-list-type/property-configurator-list-type.component';
import { PropertyConfiguratorIndentationComponent } from './property-configurator-indentation/property-configurator-indentation.component';
import { PropertyConfiguratorEditNameComponent } from './property-configurator-edit-name/property-configurator-edit-name.component';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { ColorPickerModule } from 'ngx-color-picker';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatDividerModule } from '@angular/material/divider';
import { ItemsModule } from 'src/app/common/items/items.module';
import { ComponentsModule } from 'src/app/common/components/components.module';
import { PipesModule } from '@common/pipes/pipes.module';
import { PropertyConfiguratorLockedElementComponent } from './property-configurator-locked-element/property-configurator-locked-element.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PropertyConfiguratorLinkComponent } from './property-configurator-link/property-configurator-link.component';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { PropertyConfiguratorIframeComponent } from './property-configurator-iframe/property-configurator-iframe.component';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { ChooserModule } from '@common/chooser/chooser.module';
import { PropertyConfiguratorGroupElementComponent } from './property-configurator-group-element/property-configurator-group-element.component';
import { PropertyConfiguratorStickyNoteBackgroundComponent } from './property-configurator-sticky-note-background/property-configurator-sticky-note-background.component';
import { PropertyConfiguratorStickyNoteSizeComponent } from './property-configurator-sticky-note-size/property-configurator-sticky-note-size.component';
import { PropertyConfiguratorTextVAlignmentComponent } from './property-configurator-text-valignment/property-configurator-text-valignment.component';
import { PropertyConfiguratorMaskElementComponent } from './property-configurator-mask-element/property-configurator-mask-element.component';
import { PropertyConfiguratorClipContentComponent } from './property-configurator-clip-content/property-configurator-clip-content.component';
import { PropertyConfiguratorSizeInfoComponent } from './property-configurator-size-info/property-configurator-size-info.component';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { PropertyConfiguratorTextTypeComponent } from './property-configurator-text-type/property-configurator-text-type.component';
import { PropertyConfiguratorLineboardComponent } from './property-configurator-lineboard/property-configurator-lineboard.component';
import { PropertyConfiguratorDynamicTextComponent } from './property-configurator-dynamic-text/property-configurator-dynamic-text.component';
import { EntityOptionDetailsComponent } from './property-configurator-entity-details/entity-option-details/entity-option-details.component';
import { EntityProjectDetailsComponent } from './property-configurator-entity-details/entity-project-details/entity-project-details.component';

@NgModule({
  declarations: [
    PropertyConfiguratorColorPickerComponent,
    PropertyConfiguratorFontSizeComponent,
    PropertyConfiguratorSelectorSizeComponent,
    PropertyConfiguratorBorderSizeComponent,
    PropertyConfiguratorBorderRadiusComponent,
    PropertyConfiguratorBorderStyleComponent,
    PropertyConfiguratorLineMarkerComponent,
    PropertyConfiguratorFontFamilySelectorComponent,
    PropertyConfiguratorTextAlignmentComponent,
    PropertyConfiguratorTextVAlignmentComponent,
    PropertyConfiguratorBarComponent,
    PropertyConfiguratorImageTransparencyComponent,
    PropertyConfiguratorEntityDetailsComponent,
    PropertyConfiguratorTextDecoratorComponent,
    PropertyConfiguratorDropItemComponent,
    PropertyConfiguratorDownloadComponent,
    PropertyConfiguratorElementIconComponent,
    PropertyConfiguratorListTypeComponent,
    PropertyConfiguratorIndentationComponent,
    PropertyConfiguratorEditNameComponent,
    PropertyConfiguratorLockedElementComponent,
    PropertyConfiguratorLinkComponent,
    PropertyConfiguratorIframeComponent,
    PropertyConfiguratorGroupElementComponent,
    PropertyConfiguratorStickyNoteBackgroundComponent,
    PropertyConfiguratorStickyNoteSizeComponent,
    PropertyConfiguratorMaskElementComponent,
    PropertyConfiguratorClipContentComponent,
    PropertyConfiguratorSizeInfoComponent,
    PropertyConfiguratorTextTypeComponent,
    PropertyConfiguratorLineboardComponent,
    PropertyConfiguratorDynamicTextComponent,
    EntityOptionDetailsComponent,
    EntityProjectDetailsComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    FormsModule,
    MatInputModule,
    MatMenuModule,
    OverlayModule,
    ColorPickerModule,
    MatSelectModule,
    MatSliderModule,
    MatTooltipModule,
    ItemsModule,
    MatDividerModule,
    ComponentsModule,
    PipesModule,
    DragDropModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    ChooserModule,
  ],
  exports: [
    PropertyConfiguratorBarComponent,
    PropertyConfiguratorColorPickerComponent,
    PropertyConfiguratorFontSizeComponent,
    PropertyConfiguratorSelectorSizeComponent,
    PropertyConfiguratorBorderSizeComponent,
    PropertyConfiguratorBorderRadiusComponent,
    PropertyConfiguratorBorderStyleComponent,
    PropertyConfiguratorLineMarkerComponent,
    PropertyConfiguratorFontFamilySelectorComponent,
    PropertyConfiguratorTextAlignmentComponent,
    PropertyConfiguratorTextVAlignmentComponent,
    PropertyConfiguratorImageTransparencyComponent,
    PropertyConfiguratorTextDecoratorComponent,
    PropertyConfiguratorDropItemComponent,
    PropertyConfiguratorDownloadComponent,
    PropertyConfiguratorElementIconComponent,
    PropertyConfiguratorListTypeComponent,
    PropertyConfiguratorIndentationComponent,
    PropertyConfiguratorEditNameComponent,
    PropertyConfiguratorLockedElementComponent,
    PropertyConfiguratorIframeComponent,
    PropertyConfiguratorGroupElementComponent,
    PropertyConfiguratorStickyNoteBackgroundComponent,
    PropertyConfiguratorStickyNoteSizeComponent,
    PropertyConfiguratorMaskElementComponent,
    PropertyConfiguratorClipContentComponent,
    PropertyConfiguratorSizeInfoComponent,
    PropertyConfiguratorTextTypeComponent,
    PropertyConfiguratorLineboardComponent,
    PropertyConfiguratorDynamicTextComponent,
  ],
})
export class PropertyConfiguratorModule {}
