import { Injectable } from '@angular/core';
import {
  DocumentElementEvent,
  Document,
  DocumentAction,
  DocumentElement,
  DocumentTextElementEvent,
  DocumentSVGElementEvent,
  DocumentNavigationEvent,
  StyleDefinition,
} from '@contrail/documents';
import { DocumentRenderer } from '../document/document.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ActionRequest } from '@contrail/actions';
import { DocumentTableService } from '../document/document-table/document-table.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentTemplatesService {
  private documentActionsSubject: BehaviorSubject<Array<DocumentAction>> = new BehaviorSubject(null);
  public documentActions: Observable<Array<DocumentAction>> = this.documentActionsSubject.asObservable();

  private documentElementEventsSubject: Subject<DocumentElementEvent> = new Subject();
  public documentElementEvents: Observable<DocumentElementEvent> = this.documentElementEventsSubject.asObservable();

  private documentSVGElementEventsSubject: Subject<DocumentSVGElementEvent> = new Subject();
  public documentSVGElementEvents: Observable<DocumentSVGElementEvent> =
    this.documentSVGElementEventsSubject.asObservable();

  private documentNavigationEventsSubject: Subject<DocumentNavigationEvent> = new Subject();
  public documentNavigationEvents: Observable<DocumentNavigationEvent> =
    this.documentNavigationEventsSubject.asObservable();

  public currentDocument: Document;
  public documentRenderer: DocumentRenderer;
  public elementsAdded$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public ownerReference: string;
  lastAppliedTextFormat: any;
  lastAppliedStyle: Map<string, StyleDefinition> = new Map();

  public documentTableService: DocumentTableService;

  constructor() {}

  init(document: Document, ownerReference: string = null) {
    this.ownerReference = ownerReference;
    this.currentDocument = document;
  }

  handleDocumentActions(actions: Array<DocumentAction>, document?: Document) {
    actions.forEach((action) => {
      (action.actionType = 'document'), (action.changeDefinition.documentId = document?.id || this.currentDocument.id);
      if (action.undoChangeDefinition) {
        action.undoChangeDefinition.documentId = document?.id || this.currentDocument.id;
      }
    });
    this.documentActionsSubject.next(actions);
  }

  handleDocumentElementEvent(event: DocumentElementEvent): void {
    this.documentElementEventsSubject.next(event);
  }

  setRenderer(renderer: DocumentRenderer) {
    this.documentRenderer = renderer;
    this.documentRenderer.elementsAdded$.subscribe((b) => this.elementsAdded$.next(b));
  }

  public setInteractionMode(mode) {
    if (this.documentRenderer) {
      this.documentRenderer.setInteractionMode(mode);
    }
  }

  public handleDocumentTextElementEvent(event: DocumentTextElementEvent): void {}

  handleDocumentSvgElementEvent(event: DocumentSVGElementEvent): void {} //sends event from svg editor
  handleDocumentNavigationEvent(event: DocumentNavigationEvent): void {} //sends event from zoom-pan-handler

  public saveLastAppliedTextFormat(format: any): void {}

  setAnnotations: (annotatedElements: any[]) => void;
  //handleDocumentTextElementEvent: (event: DocumentTextElementEvent) => void; // sends event from text element
  handleActionRequest: (request: ActionRequest) => void;
  getTextFontProperties: () => any;
  updateSizeAndPositionForPropertyElements: (documentElements: any[], componentElement: DocumentElement) => any;
  updateSizeAndPositionForColorElements: (documentElements: any[], size?: any) => any;
  getNewFrameNumber: () => number;
}
