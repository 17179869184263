import { Component, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DocumentElement } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { ViewDefinition } from '@contrail/client-views';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Feature } from '@common/feature-flags/feature-flag';
import { FeatureFlagsSelectors } from '@common/feature-flags';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

import { ComponentEditorPropertySelectorComponent } from './component-editor-property-selector/component-editor-property-selector.component';
import { ComponentEditorService } from './component-editor.service';
import { SideMenuOverlay } from '../document-store/document.state';
import { DocumentActions } from '../document-store';
import { DocumentService } from '../document.service';
import { DocumentItemService } from '../document-item/document-item.service';
import { DocumentColorService } from '../document-color/document-color.service';
import { DocumentComponentUtils as ComponentUtils } from '../document-component/document-component.utils';
import { PropertyViewDefinitionBuilderComponent } from './property-view-definition-builder/property-view-definition-builder.component';

@Component({
  selector: 'app-component-editor',
  templateUrl: './component-editor.component.html',
  styleUrls: ['./component-editor.component.scss'],
})
export class ComponentEditorComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();
  public selectedTab = 0;
  @Input() element: DocumentElement;
  @ViewChild('propertySelector') propertySelector: ComponentEditorPropertySelectorComponent;
  @ViewChild('viewDefinitionBuilder') viewDefinitionBuilder: PropertyViewDefinitionBuilderComponent;
  public selectedElements: DocumentElement[] = []; // component elements | ITEM | COLOR
  public elements: any[] = [];
  public multipleElements: boolean;
  public currentComponentElement: DocumentElement;
  public currentComponentElementId: string;
  public currentComponentType: string; // ITEM | COLOR
  private availableElements: any[] = [];

  public viewId: string;
  public viewDefinitionApplicationViewSlug: string = ''; // 'common:item_component' | 'common:color_component'
  public addProperty = false;
  private currentTextStyle = {
    backgroundColor: 'rgba(0,0,0,0)',
    color: 'rgba(0,0,0,0.8)',
    font: { family: 'Roboto', size: 8, weight: 'normal', style: 'normal' },
    text: { align: 'left', decoration: 'none' },
  };
  private borderProperties$ = new Subject<any>();

  public editEntitiesFeatureFlag = false; // Properties tab feature flag
  private editorLoaded = false;

  constructor(
    private documentService: DocumentService,
    private documentItemService: DocumentItemService,
    private documentColorService: DocumentColorService,
    private componentEditorService: ComponentEditorService,
    private store: Store<RootStoreState.State>,
    private snackBar: MatSnackBar,
  ) {
    this.store
      .select(FeatureFlagsSelectors.featureFlags)
      .pipe(takeUntil(this.destroy$))
      .subscribe((flags) => {
        this.editEntitiesFeatureFlag = !!flags.find((x) => x.featureName === Feature.EDIT_ENTITIES);
      });
  }

  ngOnInit(): void {
    // reset the cachedEntities only when re-opening component editor sidebar.
    // otherwise we are resetting it when switching between components, which
    // causes a race condition: when user has open component editor sidebar for component A and
    // format paints component B - it updates component B with null values since cachedEntities is reset to [].
    this.store.dispatch(DocumentActions.clearDocumentModelEntities());

    // Dispatch canvas events | Set currentComponentElement | Load selected component
    this.subscribeDocumentElementEvents();
    // Load Objects           | service.componentObjectDetails | service.loadedComponentObject
    this.addPropertyConfigurator();
    // Subscribe              | service.componentObjectDetails
    this.subscribeToComponentObjectDetails();

    // Update border properties
    this.borderProperties$.pipe(debounceTime(500), takeUntil(this.destroy$)).subscribe((values) => {
      this.updateValues(values);
    });
  }

  public subscribeToComponentObjectDetails() {
    this.componentEditorService.componentObjectDetailsObs
      .pipe(debounceTime(300), takeUntil(this.destroy$))
      .subscribe((componentObjectDetails) => {
        if (componentObjectDetails) {
          this.availableElements = ObjectUtil.cloneDeep(componentObjectDetails.elements);
          this.currentComponentElementId = componentObjectDetails.entity.id;
          this.elements = ObjectUtil.cloneDeep(componentObjectDetails.elements).filter((property) => property.enabled);
          if (!this.editorLoaded && this.editEntitiesFeatureFlag && this.selectedElements.length > 50) {
            this.snackBar.open('More than 50 items were selected for property edit. Property edit is disabled.', '', {
              duration: 5000,
            });
          }
          this.editorLoaded = true;
        }
      });
  }

  private subscribeDocumentElementEvents() {
    // Load selected component
    this.documentService.documentElements.pipe(takeUntil(this.destroy$)).subscribe((elements) => {
      const element = elements?.find((e) => e.id === this.currentComponentElement?.id);
      if (element) {
        this.currentComponentElement = ObjectUtil.cloneDeep(element);
        this.setCurrentComponentType();
        this.componentEditorService.setLoadedComponent(element.elements, element);
      }
    });

    this.documentService.documentElementEvents.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (!event) {
        return;
      }

      const isComponent =
        DocumentItemService.isItemComponet(event.element) || DocumentColorService.isColorComponent(event.element);

      if (event.eventType === 'selected' && event.element) {
        if (event.element.isLocked || !isComponent) {
          this.hide();
          return;
        }
        if (this.currentComponentElement?.id === event.element.id) {
          return;
        }
        this.currentComponentElement = event.element;

        this.addPropertyConfigurator();
        this.hidePropertySelector();
      } else if (isComponent && event.eventType === 'dblclick') {
        this.currentComponentElement = event.element;
      } else if (event.eventType === 'deselect') {
        this.currentComponentElement = null;
        this.hide();
      }
      this.setCurrentComponentType();
    });
  }
  private addPropertyConfigurator() {
    const selectedComponents = this.documentService.getSelectedElements();
    const componentElements = selectedComponents.filter((element) => {
      const isComponent = DocumentItemService.isItemComponet(element) || DocumentColorService.isColorComponent(element);
      return isComponent && !element.isLocked;
    });
    // "ITEM" and "COLOR" components use different services
    // So we need to filter out the selected components based on their type
    if (componentElements?.length) {
      // set component type by first element of selectedElements
      const firstElement = componentElements[0];
      const contextElement = this.element ?? firstElement;
      if (contextElement.modelBindings?.item) {
        this.selectedElements = componentElements.filter((element) => DocumentItemService.isItemComponet(element));
      } else if (contextElement.modelBindings?.color) {
        this.selectedElements = componentElements.filter((element) => DocumentColorService.isColorComponent(element));
      }
    } else {
      this.hide();
      return;
    }

    this.multipleElements = this.selectedElements.length === 1 ? false : true;
    if (this.element) {
      this.currentComponentElement = ObjectUtil.cloneDeep(this.element);
      this.element = null;
    }
    this.currentComponentElement = this.currentComponentElement ?? ObjectUtil.cloneDeep(this.selectedElements[0]);
    this.setCurrentComponentType();

    const elements = this.currentComponentElement?.elements;
    if (elements) {
      this.currentTextStyle = {
        backgroundColor: ComponentUtils.getCurrentStyleValue(elements, 'style.backgroundColor') || 'rgba(0,0,0,0)',
        color: ComponentUtils.getCurrentStyleValue(elements, 'style.color') || 'rgba(0,0,0,0.8)',
        font: {
          family: ComponentUtils.getCurrentStyleValue(elements, 'style.font.family') || 'Roboto',
          size: ComponentUtils.getCurrentStyleValue(elements, 'style.font.size') || 8,
          weight: ComponentUtils.getCurrentStyleValue(elements, 'style.font.weight') || 'normal',
          style: ComponentUtils.getCurrentStyleValue(elements, 'style.font.style') || 'normal',
        },
        text: {
          align: ComponentUtils.getCurrentStyleValue(elements, 'style.text.align') || 'left',
          decoration: ComponentUtils.getCurrentStyleValue(elements, 'style.text.decoration') || 'none',
        },
      };
    }
    this.componentEditorService.loadObjects(this.selectedElements, this.currentComponentElement?.id);
  }

  public syncProperties(updatedProperties: any[], type?) {
    if (updatedProperties) {
      if (type === 'properties' && this.addProperty) {
        const len = updatedProperties.length;
        if (updatedProperties[len - 1].type === 'text') {
          updatedProperties[len - 1].style = {
            ...updatedProperties[len - 1].style,
            backgroundColor: this.currentTextStyle.backgroundColor,
            color: this.currentTextStyle.color,
            font: this.currentTextStyle.font,
            text: this.currentTextStyle.text,
          };
        }
      }
      this.addProperty = false;

      this.elements = updatedProperties;
    }
    this.updateProperties();
  }
  private updateProperties() {
    let newElements;
    if (this.currentComponentType === 'ITEM') {
      newElements = this.documentItemService.updateSizeAndPositionForPropertyElements(
        this.elements,
        this.currentComponentElement,
      );
    } else if (this.currentComponentType === 'COLOR') {
      newElements = this.documentColorService.updateSizeAndPositionForColorElements(this.elements, null);
    }
    this.elements = newElements;
    this.currentComponentElement.elements = this.elements;
    this.componentEditorService.updatePropertyValuesAndUpdateComponents(newElements, this.currentComponentElement);
  }
  // Border | Presets updates
  private updateValues(values) {
    if (values.style) {
      if (!this.currentComponentElement.style) {
        this.currentComponentElement.style = {};
      }
      this.currentComponentElement.style = ObjectUtil.mergeDeep(
        ObjectUtil.cloneDeep(this.currentComponentElement.style),
        values.style,
      );
      this.updateProperties();
    }
  }

  // Thumbnail is changed
  public syncComponent(updatedComponent: DocumentElement) {
    if (updatedComponent) {
      this.currentComponentElement = updatedComponent;
    }
  }
  // Text options
  public updateTextProperties(values) {
    if (values?.type === 'textAlign') {
      this.currentTextStyle.text.align = values.value;
    }
    const changes = ComponentUtils.convertToTextStyle(values);
    for (let i = 0; i < this.elements?.length; i++) {
      const element = this.elements[i];
      if (element?.type === 'text') {
        ObjectUtil.mergeDeep(element, changes);
      }
    }

    this.syncProperties(this.elements);
  }
  public getCurrentValue(attr) {
    return ComponentUtils.getCurrentStyleValue(this.elements, attr);
    // return this.currentTextStyle.text.align
  }
  // Font options
  public fontChanged(evt) {
    switch (evt.type) {
      case 'fontFamily':
        this.currentTextStyle.font.family = evt.value;
        break;
      case 'fontSize':
        this.currentTextStyle.font.size = evt.value;
        break;
      case 'textColor':
        this.currentTextStyle.color = evt.value;
        break;
      case 'textBackgroundColor':
        this.currentTextStyle.backgroundColor = evt.value;
        break;
      case 'bold':
        this.currentTextStyle.font.weight = evt.value ? 'bold' : 'normal';
        break;
      case 'italic':
        this.currentTextStyle.font.style = evt.value ? 'italic' : 'normal';
        break;
      case 'underline':
        this.currentTextStyle.text.decoration = evt.value ? 'underline' : 'none';
        break;
      case 'textAlign':
        this.currentTextStyle.text.align = evt.value;
        break;
    }
  }
  public fontClear(evt) {
    this.currentTextStyle = {
      backgroundColor: 'rgba(0,0,0,0)',
      color: 'rgba(0,0,0,0.8)',
      font: { family: 'Roboto', size: 8, weight: 'normal', style: 'normal' },
      text: { align: 'left', decoration: 'none' },
    };
  }
  // Border options
  public updateComponentBorder(values) {
    this.borderProperties$.next(values);
  }
  // PRESETS
  public handleComponentPropertyChange(view) {
    this.viewId = view.id;
    const formattedElements = this.formatProperties(view);
    this.syncProperties(formattedElements);
    if (view.style) {
      this.updateValues(view);
    }
  }
  // PRESETS view applied
  private formatProperties(view: ViewDefinition) {
    const formattedElements: any[] = [];
    view.properties.forEach((property) => {
      let formattedElement;
      if (['annotation', 'thumbnail'].includes(property.slug)) {
        formattedElement =
          property.slug === 'thumbnail'
            ? this.availableElements.find((element) => element.type === 'image')
            : this.availableElements.find((element) => element.type === 'annotation');
        formattedElement = ObjectUtil.cloneDeep(formattedElement);
        formattedElement.size = property.size;
        formattedElement.style = property.style;
        formattedElement.isHidden = property.isHidden;
        if (property.scale) {
          formattedElement.scale = property.scale;
        } else if (formattedElement.scale) {
          delete formattedElement.scale; // need to remove scale if it is not present in the preset
        }
      } else if (['container', 'colorRect'].includes(property.slug)) {
        formattedElement =
          property.slug === 'colorRect'
            ? this.availableElements.find((ele) => ele.type === 'rectangle' && ele?.propertyBindings)
            : this.availableElements.find((ele) => ele.type === 'rectangle' && !ele?.propertyBindings);
        formattedElement = ObjectUtil.cloneDeep(formattedElement);
        formattedElement.size = property.size;
        formattedElement.style = property.style;
        delete formattedElement?.label;
      } else {
        formattedElement = this.availableElements.find((element) => element.propertyDefinition?.slug === property.slug);
        if (formattedElement) {
          formattedElement = ObjectUtil.cloneDeep(formattedElement);
          formattedElement.style = property.style;
          if (property.includeLabel) {
            formattedElement.label = formattedElement.displayName;
          } else {
            delete formattedElement.label;
          }
        }
      }
      if (formattedElement) {
        formattedElements.push(formattedElement);
      }
    });
    return formattedElements;
  }

  // BOTTOM BUTTONS -------------------
  // `SAVE AS PRESET` Button
  public createPreset() {
    this.viewDefinitionBuilder.openCreateViewModal();
  }
  // `APPLY TO ALL` Button
  public applyChangesToAll() {
    this.componentEditorService.updateComponentElements(this.currentComponentElement, this.elements, true);
  }
  private applyChanges() {
    this.componentEditorService.updateComponentElements(this.currentComponentElement, this.elements, false);
  }

  // -------------------
  @HostListener('click', ['$event'])
  private hidePropertySelector() {
    this.propertySelector.hideWidget();
  }
  public hide() {
    const overlay: SideMenuOverlay = {};
    overlay.icon = '';
    overlay.label = 'Property Editor';
    overlay.slug = 'componentEditor';
    overlay.showChooser = false;
    this.componentEditorService.clearObjects();
    this.store.dispatch(DocumentActions.toggleChooser({ overlay }));
  }

  private setCurrentComponentType() {
    const isComponent = this.currentComponentElement?.type === 'component';
    if (isComponent && this.currentComponentElement?.modelBindings?.item) {
      this.currentComponentType = 'ITEM';
      this.viewDefinitionApplicationViewSlug = 'common:item_component';
    } else if (isComponent && this.currentComponentElement?.modelBindings?.color) {
      this.currentComponentType = 'COLOR';
      this.viewDefinitionApplicationViewSlug = 'common:color_component';
    } else {
      this.currentComponentType = null;
      this.viewDefinitionApplicationViewSlug = null;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
