export class EntitiesHelper {
  public static async getAllPaginatedResults<T>(
    getPage: (nextPageKey?: string) => Promise<{ results: T[]; nextPageKey?: string }>,
  ): Promise<T[]> {
    const results: T[] = [];
    let nextPageKey: string | undefined;

    do {
      const { results: pageResults, nextPageKey: newNextPageKey } = await getPage(nextPageKey);
      results.push(...pageResults);
      nextPageKey = newNextPageKey;
    } while (nextPageKey);

    return results;
  }
}
