import { DocumentElement, SizeDefinition } from '@contrail/documents';
import * as d3 from 'd3';
import { nanoid } from 'nanoid';
import { CanvasUtil } from './canvas-util';
import { FileDownloader } from './file-downloader';
import { ImageElement } from './components/image-element/image-element';
import { ColorUtil } from './util/color-util';

export class SVGHelper {
  static readonly ID_SUFFIX = '--svg-';
  // https://www.w3.org/TR/SVG11/linking.html#processingIRI
  // Elements and properties that allow IRI references and the valid target types for those references.
  // Meaning these properties can use url(#some-element)
  static IRI_TAG_PROPERTIES_MAP = {
    clipPath: ['clip-path'],
    'color-profile': ['color-profile'],
    cursor: ['cursor'],
    filter: ['filter'],
    linearGradient: ['fill', 'stroke'],
    marker: ['marker', 'marker-end', 'marker-mid', 'marker-start'],
    mask: ['mask'],
    pattern: ['fill', 'stroke'],
    radialGradient: ['fill', 'stroke'],
  };

  /**
   * Calls prefixIdsClasses on svg and children, so each embedded svg maintains unique ids/classnames from each other
   * @param svg
   */
  static prefixSvgAndChildren(svg: any): any {
    let prefix = nanoid(16);
    let svgHtml = this.prefixIdsClasses(svg, prefix);

    let childSvgElements = svgHtml.getElementsByTagName('svg');
    for (let element of childSvgElements) {
      prefix = nanoid(16);
      let newElement = this.prefixIdsClasses(element.outerHTML, prefix);

      element.parentElement.replaceChild(newElement, element);
    }

    return svgHtml;
  }

  /**
   * Change all mentions of ids and classes of an @svg to have @prefix
   * id="abc"       --> id="abc-@prefix"
   * #abc           --> #abc--svg-@prefix
   * fill:url(#b)   --> fill:url(#b--svg-@prefix);
   * class="abc"    --> class="abc-@prefix"
   * .abc           --> .abc--svg-@prefix
   * @param svg
   * @param prefix
   */
  static prefixIdsClasses(svg: any, prefix: string): any {
    let svgNode = svg;
    if (typeof svg === 'string') {
      const element = d3.create('svg');
      // Clean old prefix
      svg = svg?.replace(new RegExp(`${this.ID_SUFFIX}[A-Za-z0-9_-]{16}`, 'gm'), '');
      element.html(svg);
      svgNode = element.select('svg').node();
    }
    this.prefixClasses(svgNode, prefix);
    this.prefixIds(svgNode, prefix);
    return svgNode;
  }

  /**
   * Make all classes unique by adding @prefix
   * @param svg
   * @param prefix
   */
  private static prefixClasses(svg, prefix) {
    const suffix = this.ID_SUFFIX + prefix;
    const classRegex = /(\.(?:[_a-zA-Z0-9-]+))([\s{,])/g;
    const classElements = svg.querySelectorAll('[class]');

    // Get all elements that have class attribute and prefix class names
    for (const element of classElements) {
      const value = element.getAttribute('class');
      const newValue =
        value &&
        value
          .split(' ')
          .map((c) => c + suffix)
          .join(' ');
      if (newValue != value) {
        element.setAttribute('class', newValue);
      }
    }

    // Replace all class names in the style tag element
    const styleElements = svg.getElementsByTagName('style');
    for (const element of styleElements) {
      const value = element.textContent;
      let newValue;
      try {
        newValue = value && value.replace(classRegex, '$1' + suffix + '$2');
      } catch (e) {
        console.log(e);
      }
      if (newValue != value) {
        element.textContent = newValue;
      }
    }
  }

  /**
   * Make all ids unique by adding @prefix
   * @param svg
   * @param prefix
   */
  private static prefixIds(svg, prefix) {
    const idSuffix = this.ID_SUFFIX + prefix;
    // Regular expression occurences in the form url(#anyId) or url("#anyId")
    const iriRegex = /url\("?#([a-zA-Z][\w:.-]*)"?\)/g;
    // Get all elements with an id attribute
    const idElements = svg.querySelectorAll('[id]');
    const iriProperties = [];
    for (const element of idElements) {
      const tagName = element.localName;
      if (tagName in this.IRI_TAG_PROPERTIES_MAP) {
        this.IRI_TAG_PROPERTIES_MAP[tagName].forEach((property) => {
          if (iriProperties.indexOf(property) === -1) {
            iriProperties.push(property);
          }
        });
      }
      element.id += idSuffix;
    }

    if (svg.id) {
      svg.id += idSuffix;
    } else {
      svg.id = idSuffix;
    }

    if (iriProperties.length) {
      iriProperties.push('style');
    }

    const elements = svg.getElementsByTagName('*');
    let element = svg;
    for (let i = -1; element != null; ) {
      if (element.localName === 'style') {
        // If element is a style element, replace IDs in all occurences of "url(#anyId)" in text content
        const value = element.textContent;
        let newValue;
        try {
          newValue = value && value.replace(iriRegex, (match, id) => `url(#${id + idSuffix})`);
        } catch (e) {
          console.log(e);
        }
        if (newValue != value) {
          element.textContent = newValue;
        }
      } else if (element.hasAttributes()) {
        for (const propertyName of iriProperties) {
          const value = element.getAttribute(propertyName);
          const newValue = value && value.replace(iriRegex, (match, id) => `url(#${id + idSuffix})`);
          if (newValue != value) {
            element.setAttribute(propertyName, newValue);
          }
        }
        // Replace id in xlink:ref and href attributes
        ['xlink:href', 'href'].forEach((attr) => {
          var iri = element.getAttribute(attr);
          if (/^\s*#/.test(iri)) {
            // Check if iri is non-null and internal reference
            iri = iri.trim();
            element.setAttribute(attr, iri + idSuffix);
          }
        });
      }
      element = elements[++i];
    }
  }

  /** Locates the correct source SVG text for a document element, download files as needed. */
  public static async getSVGTextFromDocumentElement(
    element: DocumentElement,
    fileDownloader: FileDownloader,
  ): Promise<string> {
    if (!element) {
      console.error('Element null in getSVGTextFromDocumentElement');
      return;
    }
    let svgHtml;
    let urlToSVGFile = element.url;

    // Handle content use case, where the element url is to a viewable, not the original SVG
    if (element.modelBindings?.content) {
      if (element.alternateUrls?.originalFile) {
        urlToSVGFile = element.alternateUrls?.originalFile;
      }
    }
    if (element?.type === 'component' && element?.modelBindings?.item) {
      const imageElement = element?.elements?.find((e) => e.type === 'image');
      const originalFile = imageElement?.alternateUrls?.originalFile;
      if (originalFile?.indexOf('.svg') !== -1) {
        urlToSVGFile = originalFile;
      }
    }
    if (urlToSVGFile) {
      svgHtml = await fileDownloader.downloadFileAsText(urlToSVGFile);
    } else if (element.text) {
      svgHtml = element.text;
    }
    return svgHtml;
  }

  /**
   * Change fill for @svgHtml paths to @hexColor
   * @param svgHtml
   * @param hexColor
   * @returns recolored SVG HTML
   */
  public static async recolorSVG(uniqueIdentifier: string, svgHtml: string, hexColor: string): Promise<string> {
    const d3SVGElementResult = SVGHelper.getD3SVGElement(svgHtml, uniqueIdentifier);
    const element = d3SVGElementResult.element;
    const canGetComputedStyle = d3SVGElementResult.canGetComputedStyle;
    let tempContainer = d3SVGElementResult.tempContainer;
    if (!element) {
      return;
    }

    const svgNode = element.selectAll('path, polygon, polyline, circle, rect').select(function (d, i) {
      const fill = d3.select(this).style('fill');
      if (!canGetComputedStyle) {
        return this;
      } else if (
        canGetComputedStyle &&
        fill &&
        fill !== 'rgb(0, 0, 0)' &&
        fill !== 'rgb(34, 34, 32)' &&
        fill !== 'none'
      ) {
        return this;
      }
    });

    svgNode.style('fill', hexColor);

    tempContainer?.remove();
    tempContainer = null;

    return element.select('svg').node().outerHTML;
  }

  public static getD3SVGElement(
    svgHtml: string,
    uniqueIdentifier?: string,
  ): { element; tempContainer; canGetComputedStyle } {
    let element,
      canGetComputedStyle = false;
    let tempContainer;
    try {
      const parser = new DOMParser();
      const id = `temp-${uniqueIdentifier || nanoid(16)}`;
      tempContainer = document.createElement('div');
      tempContainer.style.position = 'absolute';
      tempContainer.style.display = 'none';
      tempContainer.id = id;
      const svgElement = parser.parseFromString(svgHtml, 'text/html')?.body?.childNodes[0];
      tempContainer.appendChild(svgElement);
      document.getElementById('mainCanvas').appendChild(tempContainer);
      element = d3.select(`[id="${id}"]`);
      canGetComputedStyle = true;
    } catch (e) {
      tempContainer?.remove();
      tempContainer = null;
    }

    if (!element || element?.size() === 0) {
      canGetComputedStyle = false;
      element = d3.create('svg');
      element.html(svgHtml);
    }

    return { element, tempContainer, canGetComputedStyle };
  }

  /**
   * Check if @color is valid color: hex, rgb(a), hsl
   * @param color
   * @returns
   */
  public static isColorString(color) {
    if (!color) return false;
    if (color === 'none') return false;
    const validColor = /(?:#|0x)(?:[a-fA-F0-9]{3}|[a-fA-F0-9]{6})\b|(?:rgb|hsl)a?\([^\)]*\)/gi;
    return color.match(validColor);
  }

  public static color(str) {
    return SVGHelper.isColorString(str) ? str : null;
  }

  /**
   * Parse HTML @str to find value of attribute @attr
   * [fill]="#fff" ---> #fff
   * @param attr
   * @param str
   * @returns
   */
  private static getAttributeValue(attr: string, str: string) {
    const regex = new RegExp(`(${attr})=["']?((?:.(?!["']?\s+(?:\S+)=|\s*\/?[>"']))+.)["']?`, 'gm');
    const match = regex.exec(str);
    return match?.length && match[2];
  }

  /**
   * Parse HTML inline style @str to find @attr property value
   * "style"="fill: #fff; border: 1px solid" --> #fff
   * @param attr
   * @param str
   * @returns
   */
  private static getInlineStyleValue(attr: string, str: string) {
    const regex = new RegExp(`${attr}:\s*([^;]*)`, 'gm');
    const match = regex.exec(str);
    return match?.length && match[1]?.trim();
  }

  /**
   * Parse CSS @str to find value of CSS property @attr
   *
   * fill: #fff;
   * border: 1px solid;
   * --->
   * #fff
   * @param attr
   * @param str
   * @returns
   */
  private static getStyleValue(attr: string, str: string) {
    const regex = new RegExp(`${attr}:\s*([^;]*)`, 'gm');
    let match;
    const result = [];
    while ((match = regex.exec(str)) !== null) {
      if (match.index === regex.lastIndex) {
        regex.lastIndex++;
      }
      result.push(match[1]?.trim());
    }
    return result;
  }

  /**
   * Get unique fills and strokes for @svgHtml
   * Use regex to extract colors because using native
   * browser style property always converts the value
   * to rgb per spec
   * https://stackoverflow.com/questions/39208559/browsers-automatically-evaluate-hex-or-hsl-colors-to-rgb-when-setting-via-elemen
   * https://developer.mozilla.org/en-US/docs/Web/CSS/color
   * @param svgHtml
   * @returns
   */
  public static getColors(svgHtml: string) {
    let element = document.createElement('svg');
    element.innerHTML = svgHtml;
    let fillColors = [];
    let strokeColors = [];

    const fills = element.querySelectorAll('[fill]');
    for (let i = 0; i < fills.length; i++) {
      const element = fills[i];
      const fill = this.getAttributeValue('fill', element.outerHTML);
      // const fill = element.getAttribute('fill');
      fill && fillColors.push(fill);
    }

    // const strokes = element.querySelectorAll('[stroke]');
    // for (let i = 0; i < strokes.length; i++) {
    //   const element = strokes[i];
    //   const stroke = this.getAttributeValue('stroke', element.outerHTML);
    //   // const stroke = element.getAttribute('stroke');
    //   stroke && strokeColors.push(stroke);
    // }

    const styles = element.querySelectorAll('[style]');
    for (let i = 0; i < styles.length; i++) {
      const element: HTMLElement = styles[i] as HTMLElement;
      const style = element.style;
      const fill = this.getInlineStyleValue('fill', element.outerHTML);
      // const fill = style['fill'];
      fill && fillColors.push(fill);

      // const stroke = this.getInlineStyleValue('stroke', element.outerHTML);
      // // const stroke = style['stroke'];
      // stroke && strokeColors.push(stroke);
    }

    const style: HTMLStyleElement = element.querySelector('style');
    if (style?.textContent) {
      fillColors = fillColors.concat(this.getStyleValue('fill', style.textContent));
      // strokeColors = strokeColors.concat(this.getStyleValue('stroke', style.textContent));
      // const stylesheet = new CSSStyleSheet();
      // stylesheet.replaceSync(style.textContent);
      // for (let i = 0; i < stylesheet.cssRules.length; i++) {
      //   const fill = (stylesheet.cssRules[i] as CSSStyleRule)?.style?.fill;
      //   const fillColor = SVGHelper.color(fill);
      //   fillColor && fillColors.push(fillColor);

      //   const stroke = (stylesheet.cssRules[i] as CSSStyleRule)?.style?.stroke;
      //   const strokeColor = SVGHelper.color(stroke);
      //   strokeColor && strokeColors.push(strokeColor);
      // }
    }

    element = null;

    return {
      fills: fillColors.filter(CanvasUtil.uniqueFilter).filter((c) => this.color(c)),
      // strokes: strokeColors.filter(CanvasUtil.uniqueFilter).filter((c) => this.color(c)),
    };
  }

  public static getSvgGraphicTypes(): string[] {
    return ['circle', 'ellipse', 'image', 'line', 'path', 'polygon', 'polyline', 'rect', 'text', 'use'];
  }

  public static isNodeInDefs(node: Node): boolean {
    let currentNode = node;

    while (currentNode.parentNode) {
      if (currentNode.nodeName == 'defs') {
        return true;
      } else if (currentNode.nodeName == 'svg') {
        return false;
      } else {
        currentNode = currentNode.parentNode;
      }
    }

    return false;
  }

  // Traverses up hierarchy to see if any parent is of a particular type.
  // IE to filter out elements that belong to a pattern node
  public static isNodeChildOfType(node: Node, parentType: string): boolean {
    let currentNode = node;

    while (currentNode.parentNode) {
      if (currentNode.nodeName == parentType) {
        return true;
      } else if (currentNode.nodeName == 'svg') {
        return false;
      } else {
        currentNode = currentNode.parentNode;
      }
    }

    return false;
  }

  public static getSvgChildElements(svgRootElement: SVGElement): SVGElement[] {
    const childElements: SVGElement[] = [];

    const svgComponentTypes = this.getSvgGraphicTypes();

    let sel = d3
      .select(svgRootElement)
      .selectAll(svgComponentTypes.join(', '))
      .filter(function (d) {
        return !SVGHelper.isNodeChildOfType(this, 'pattern'); // Don't select elements that are children of a pattern node
      });
    for (let j = 0; j < sel.nodes().length; j++) {
      childElements.push(sel.nodes()[j]);
    }

    return childElements;
  }

  public static getSvgStyleRules(svgRootElement: SVGElement): CSSStyleRule[] {
    const svgStyleRules: CSSStyleRule[] = [];

    const svgStyle = d3.select(svgRootElement).selectAll('style');

    if (svgStyle.nodes().length) {
      const cssRules = svgStyle.nodes()[0].sheet.rules as CSSRuleList;

      for (let i = 0; i < cssRules.length; i++) {
        svgStyleRules.push(cssRules[i] as CSSStyleRule);
      }
    }

    return svgStyleRules;
  }

  public static getStyleRulesFromElement(svgElement: SVGElement): CSSStyleRule[] {
    const elementStyleRules: CSSStyleRule[] = [];

    let sel = d3.select(svgElement);
    if (sel.nodes()) {
      const svgStyleRules = SVGHelper.getSvgStyleRules(sel.nodes()[0].ownerSVGElement);
      const className = sel.nodes()[0].className?.baseVal;

      if (className != '') {
        svgStyleRules.forEach((rule) => {
          if (rule.selectorText.includes(className)) {
            elementStyleRules.push(rule);
          }
        });
      }
    }

    return elementStyleRules;
  }

  public static getColorsInSvg(svgRootElement: SVGElement, includeInlineStyles: boolean = false) {
    let fillColors = SVGHelper.getColorsInElements(SVGHelper.getSvgChildElements(svgRootElement)).fills;

    // This will add all the colors found in the inline styles, however this is usually undesirable because it will potentially add colors that are not actually in use due to being overridden
    // getColorsInElements will already return any colors found in applied styles that are not being overridden
    if (includeInlineStyles) {
      fillColors = fillColors.concat(SVGHelper.getColorsInSvgStyles(svgRootElement).fills);
    }

    return {
      fills: fillColors.filter(CanvasUtil.uniqueFilter).filter((c) => this.color(c)),
    };
  }

  public static getColorsInElements(svgElements: SVGElement[]) {
    let fillColors = [];

    for (let i = 0; i < svgElements.length; i++) {
      let hasFill: boolean = false; // Does the element have a fill attribute or style?
      let fillAttr = svgElements[i]?.getAttribute('fill');
      let styleAttrs = svgElements[i]?.getAttribute('style')?.split(';');
      if (styleAttrs) {
        for (let j = 0; j < styleAttrs.length; j++) {
          if (styleAttrs[j].includes('fill')) {
            let fillAttr = styleAttrs[j].split(':');

            let color = fillAttr[1].trim();
            color = ColorUtil.isRgb(color) ? ColorUtil.rgbToHexString(color) : color;

            fillColors.push(color);
            hasFill = true;
          }
        }
      }

      if (!hasFill && fillAttr && fillAttr != 'none') {
        let color = fillAttr.trim();
        color = ColorUtil.isRgb(color) ? ColorUtil.rgbToHexString(color) : color;

        fillColors.push(color);
        hasFill = true;
      }

      // If the element does not have a fill attribute then we look for a fill in svg inline styles
      if (!hasFill) {
        let elementStyleRules: CSSStyleRule[] = SVGHelper.getStyleRulesFromElement(svgElements[i]);
        elementStyleRules.forEach((rule) => {
          for (let j = 0; j < rule.style.length; j++) {
            if (rule.style[j] == 'fill') {
              let color = rule.style.fill.trim();
              color = ColorUtil.isRgb(color) ? ColorUtil.rgbToHexString(color) : color;

              fillColors.push(color);
            }
          }
        });
      }
    }

    return {
      fills: fillColors.filter(CanvasUtil.uniqueFilter).filter((c) => this.color(c)),
    };
  }

  public static getColorsInSvgStyles(svgRootElement: SVGElement) {
    const svgStyleRules = SVGHelper.getSvgStyleRules(svgRootElement);
    return SVGHelper.getColorsInStyleRules(svgStyleRules);
  }

  public static getColorsInStyleRules(styleRules: CSSStyleRule[]) {
    let fillColors = [];

    styleRules.forEach((rule) => {
      for (let j = 0; j < rule.style.length; j++) {
        // ignore anything that starts with url for now
        if (rule.style[j] == 'fill' && rule.style.fill.substring(0, 3) != 'url') {
          let fillColor = ColorUtil.isRgb(rule.style.fill)
            ? ColorUtil.rgbToHexString(rule.style.fill)
            : rule.style.fill;
          fillColors.push(fillColor);
        }
      }
    });

    return {
      fills: fillColors.filter(CanvasUtil.uniqueFilter).filter((c) => this.color(c)),
    };
  }

  public static getChildElementsWithColor(svgRootElement: SVGElement, color: string): SVGElement[] {
    return SVGHelper.getElementsWithColor(SVGHelper.getSvgChildElements(svgRootElement), color);
  }

  public static getElementsWithColor(svgElements: SVGElement[], color: string): SVGElement[] {
    color = ColorUtil.isRgb(color) ? ColorUtil.rgbToHexString(color) : color;

    const foundElements = new Array<SVGElement>();

    for (let i = 0; i < svgElements.length; i++) {
      let hasFill: boolean = false;
      let fillAttr = svgElements[i]?.getAttribute('fill');
      let styleAttrs = svgElements[i]?.getAttribute('style')?.split(';');
      // Inline style fill takes precedence
      if (styleAttrs) {
        for (let j = 0; j < styleAttrs.length; j++) {
          if (styleAttrs[j].includes('fill')) {
            hasFill = true;
            const attrColor = styleAttrs[j].split(':')[1].trim();
            color = color.trim();

            if (ColorUtil.areEqualColors(attrColor, color)) {
              foundElements.push(svgElements[i]);
            }
          }
        }
      }

      if (!hasFill && fillAttr) {
        hasFill = true;
        let attrColor = fillAttr.trim();

        if (ColorUtil.areEqualColors(attrColor, color)) {
          foundElements.push(svgElements[i]);
        }
      }

      if (!hasFill) {
        // if no fill attribute or fill property in style, check the style classes
        const styleRules: CSSStyleRule[] = SVGHelper.getStyleRulesFromElement(svgElements[i]);
        const matchingRules: CSSStyleRule[] = SVGHelper.getStyleRulesWithColor(styleRules, color);

        if (matchingRules.length) {
          foundElements.push(svgElements[i]);
        }
      }
    }

    return foundElements;
  }

  public static getSvgStyleRulesWithColor(svgRootElement: SVGElement, color: string): CSSStyleRule[] {
    const svgStyleRules = SVGHelper.getSvgStyleRules(svgRootElement);
    return SVGHelper.getStyleRulesWithColor(svgStyleRules, color);
  }

  public static getStyleRulesWithColor(styleRules: CSSStyleRule[], color: string): CSSStyleRule[] {
    const matchingRules: CSSStyleRule[] = [];

    styleRules.forEach((rule) => {
      // ignore anything that starts with url for now
      for (let i = 0; i < rule.style.length; i++) {
        if (
          rule.style[i] == 'fill' &&
          rule.style.fill.substring(0, 3) != 'url' &&
          ColorUtil.areEqualColors(rule.style.fill, color)
        ) {
          matchingRules.push(rule);
        }
      }
    });

    return matchingRules;
  }

  public static setColorInChildElements(svgRootElement: SVGElement, color: string) {
    return SVGHelper.setColorInElements(SVGHelper.getSvgChildElements(svgRootElement), color);
  }

  public static setColorInElements(svgElements: SVGElement[], color: string) {
    for (let i = 0; i < svgElements.length; i++) {
      const hasFillAttr = svgElements[i].hasAttribute('fill');
      const hasInlineFillAttr = svgElements[i]?.style?.fill;

      // If the element has a fill attribute, overwrite it.  If not, set the fill via the style attribute
      // Inline style fill takes precedence
      if (hasFillAttr && !hasInlineFillAttr) {
        d3.select(svgElements[i]).attr('fill', color);
      } else {
        d3.select(svgElements[i]).style('fill', color);
      }
    }
  }

  public static setColorInStyleRules(styleRules: CSSStyleRule[], color: string) {
    styleRules.forEach((rule) => {
      rule.style.fill = color;
    });
  }

  public static replaceColorInSvg(svgRootElement: SVGElement, oldColor: string, newColor: string) {
    SVGHelper.replaceColorInElements(SVGHelper.getSvgChildElements(svgRootElement), oldColor, newColor);
    SVGHelper.setColorInStyleRules(SVGHelper.getSvgStyleRulesWithColor(svgRootElement, oldColor), newColor);
  }

  public static replaceColorInElements(svgElements: SVGElement[], oldColor: string, newColor: string) {
    const matchingElements = SVGHelper.getElementsWithColor(svgElements, oldColor);

    return SVGHelper.setColorInElements(matchingElements, newColor);
  }

  /**
   * Escape string to be used in RegExp
   * @param str
   * @returns
   */
  private static escape(str: string): string {
    if (!str) {
      return '';
    }
    return str.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }

  /**
   * Replace all occurances of @colorToReplace with @color in @svgHtml
   * @param colorToReplace
   * @param color
   * @param svgHtml
   * @returns
   */
  public static replaceColor(colorToReplace: string, color: string, svgHtml: string): string {
    if (!svgHtml || svgHtml === '') {
      return svgHtml;
    }
    return svgHtml.replace(new RegExp(`${this.escape(colorToReplace)}`, 'g'), color);
  }

  public static getSize(svgHtml: string): SizeDefinition {
    try {
      const [match, viewBox] = svgHtml.match(/viewBox="(.*?)"/) || [null, null];
      if (viewBox) {
        const [x, y, width, height] = viewBox?.replace(/, /gi, ',')?.split(/[ ,]+?/g);
        if (width && height) {
          return { width: parseFloat(width), height: parseFloat(height) };
        }
        return null;
      }
    } catch (e) {
      console.log('Error parsing svg', e, svgHtml);
      return null;
    }
  }

  public static isSvg(element: DocumentElement): boolean {
    return element?.type === 'svg';
  }

  public static async getCanvasBlob(svgHtmlString: string): Promise<{ canvasBlob: Blob }> {
    const blob = new Blob([svgHtmlString], { type: 'image/svg+xml' });
    const url = URL.createObjectURL(blob);
    const canvasBlob = await ImageElement.toCanvasBlobObject(url, 'image/svg+xml', 1, 8, true);
    URL.revokeObjectURL(url); // revoke SVG URL since it's not needed anymore as SVG was turned into Canvas image
    return { canvasBlob };
  }

  // Multiplies all nested transform matrices together to calculate a single matrix for an svg element
  public static calculateFlatTransformMatrixForElement(
    svgElement: SVGGraphicsElement,
    parentNode: SVGElement,
  ): DOMMatrix {
    let transformMatrix = new DOMMatrix();
    if (svgElement.transform.baseVal.length) {
      transformMatrix = svgElement.transform.baseVal.consolidate().matrix;
    }

    // Collect all parent transform matrices in an array
    let currElement = svgElement;
    let matrixStack: DOMMatrix[] = [transformMatrix];
    while (currElement.parentNode) {
      currElement = currElement.parentNode as SVGGraphicsElement;
      // Stop iterating at the first svg tag
      if (currElement.nodeName == 'svg') break;

      if (currElement.nodeName === 'symbol') {
        const useSymbol = parentNode.querySelector(`[*|href="#${currElement.id}"]`) as SVGGraphicsElement;
        if (useSymbol?.transform?.baseVal?.length) {
          matrixStack.push(useSymbol.transform.baseVal.consolidate().matrix);
        }
      }

      if (currElement.transform?.baseVal?.length) {
        matrixStack.push(currElement.transform.baseVal.consolidate().matrix);
      }
    }

    // Multiply them together to create a single matrix representing all transformations
    let flattenedMatrix = new DOMMatrix();
    for (let i = matrixStack.length - 1; i >= 0; i--) {
      flattenedMatrix = flattenedMatrix.multiplySelf(matrixStack[i]);
    }

    return flattenedMatrix;
  }

  public static getParentSvgNode(svgElement: SVGElement): Node {
    let currElement = svgElement;
    while (currElement.parentNode) {
      if (currElement.parentNode.nodeName == 'svg') {
        return currElement.parentNode;
      }

      currElement = currElement.parentNode as SVGElement;
    }

    return null;
  }
}
