import {
  DocumentAction,
  DocumentElement,
  DocumentSVGElementEvent,
  DocumentTextElementEvent,
  DocumentNavigationEvent,
  PositionDefinition,
  SizeDefinition,
  StyleDefinition,
} from '@contrail/documents';
import { DocumentElementEvent } from '@contrail/documents';
import { Document, ViewBox } from '@contrail/documents';
import { CanvasDisplay } from './canvas-display';
import { CanvasInteractionHandler } from './interactions/canvas-interaction-handler';
import { BehaviorSubject, Observable } from 'rxjs';
import { ObjectUtil } from '@contrail/util';
import { CanvasElement } from './elements/canvas-element';
import { DocumentActionsDispatcher } from './document-actions-dispatcher';
import { CanvasState } from './state/canvas-state';
import { CanvasRenderer, DrawOptions } from './renderers/canvas-renderer';
import { AnnotationHandler } from './interactions/annotation-handler';
import { Frame } from './state/canvas-frame-state';
import { CanvasFrameElement } from './elements/frame/canvas-frame-element';
import { ActionRequest } from '@contrail/actions';
import { CanvasEventHandlers } from './interactions/canvas-event-handlers/canvas-event-handlers';
import { HIGHLIGHT_COLOR } from './constants';
import { TextImageGenerator } from './elements/text/text-image-generator';
import { CanvasUtil } from './canvas-util';
import { IframeElementHelper } from './elements/iframe/iframe-element-helper';
import { EditorHandler } from './components/editor/editor-handler';
import { stickyEditorId } from './elements/sticky-note/editor/sticky-note-editor';
import { editorId, TextFormat } from './elements/text/editor/text-editor';
import { iframeElementContainerId } from './elements/iframe/iframe-element-container';
import { Group } from './state/canvas-group-state';
import { CoordinateBox } from './coordinate-box';
import { Mask } from './state/canvas-mask-state';
import { CanvasMaskState } from './state/canvas-mask-state';
import { CacheManager } from './cache/cache-manager';
import { ImageElementCache } from './cache/image-element-cache';
import { SvgEditorHandler } from './components/svg-editor/svg-editor-handler';
import { SVGCombiner } from './elements/svg/svg-combiner';
import { CanvasTableService } from './elements/table/canvas-table.service';
import { CanvasTableElement } from './elements/table/canvas-table-element';
import { DocumentTableService } from '../document/document-table/document-table.service';

export interface DocumentRenderingMetricService {
  recordMetrics(metric: any): any;
}

export interface CanvasAppContext {
  imageHost?: string;
}

export interface CopiedProperties {
  element?: DocumentElement;
  textFormat?: TextFormat;
}

export enum CANVAS_MODE {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
  PREVIEW = 'PREVIEW',
  PRESENT = 'PRESENT',
  COMMENT = 'COMMENT',
  SNAPSHOT = 'SNAPSHOT',
}

export interface DocumentManagementService {
  setAnnotations: (annotatedElements: any[]) => void;
  handleDocumentElementEvent(event: DocumentElementEvent): void;
  handleDocumentActions(actions: Array<DocumentAction>): void;
  handleDocumentTextElementEvent(event: DocumentTextElementEvent): void; // sends event from text element
  handleDocumentSvgElementEvent(event: DocumentSVGElementEvent): void; //sends event from svg editor
  handleDocumentNavigationEvent(event: DocumentNavigationEvent): void; //sends event from zoom-pan-handler
  handleActionRequest(request: ActionRequest): void;
  getTextFontProperties(): any;
  updateSizeAndPositionForPropertyElements(documentElements: any[], componentElement: DocumentElement): any;
  updateSizeAndPositionForColorElements(documentElements: any[], size?: any): any;
  getNewFrameNumber(): number;
  saveLastAppliedTextFormat(format: any): void;
  lastAppliedTextFormat: any;
  lastAppliedStyle: Map<string, StyleDefinition>;
  documentRenderingMetricService?: DocumentRenderingMetricService;
  documentSVGElementEvents: Observable<DocumentSVGElementEvent>;
  documentNavigationEvents: Observable<DocumentNavigationEvent>;
  documentTableService: DocumentTableService;
}
export class CanvasDocument {
  public state: CanvasState;
  public canvasDisplay: CanvasDisplay;
  public canvasRenderer: CanvasRenderer;
  public interactionHandler: CanvasInteractionHandler;
  public annotationHandler: AnnotationHandler;
  public editorHandler: EditorHandler;
  public actionsDispatcher: DocumentActionsDispatcher;
  public elementsAdded$ = new BehaviorSubject<boolean>(false);
  public isFirefox;
  public supportsTouchEvents = false;
  public iframeElementHelper: IframeElementHelper;
  public svgEditorHandler: SvgEditorHandler;
  public svgCombiner: SVGCombiner;
  public canvasTableService: CanvasTableService;

  constructor(
    public elementId: string,
    public documentDefinition: Document,
    public documentService: DocumentManagementService,
    public mode: CANVAS_MODE = CANVAS_MODE.EDIT,
    protected authService,
    public appContext: CanvasAppContext,
    protected annotationOptions = [],
    protected customFonts?: string,
    protected restrictedViewablePropertySlugs?: string[],
    public hasSvgRecolorFeatureFlag?: boolean,
    private canvasScale?: number,
  ) {
    this.clear();
    TextImageGenerator.init(customFonts);
    this.isFirefox = CanvasUtil.isFirefox();
    this.supportsTouchEvents = 'ontouchstart' in globalThis;
    this.state = new CanvasState(this, restrictedViewablePropertySlugs);
    this.state.registerBackground(this.documentDefinition.background);
    this.state.registerElements(this.documentDefinition.elements);
    this.actionsDispatcher = new DocumentActionsDispatcher(this);
    this.canvasDisplay = new CanvasDisplay(this, this.documentDefinition, this.canvasScale);
    this.canvasRenderer = new CanvasRenderer(this);
    this.interactionHandler = new CanvasInteractionHandler(this);
    this.editorHandler = new EditorHandler(this);
    this.annotationHandler = new AnnotationHandler(this, annotationOptions);
    this.iframeElementHelper = new IframeElementHelper(this);
    this.canvasTableService = new CanvasTableService(this);
    if (this.mode === 'EDIT') {
      this.svgEditorHandler = new SvgEditorHandler(this);
      this.svgCombiner = new SVGCombiner(this);
    }
  }

  public getViewBox() {
    return this.canvasDisplay.getViewBox();
  }

  public getViewScale() {
    return this.canvasDisplay.getViewScale();
  }

  public getCanvasSize(): SizeDefinition {
    return this.canvasDisplay.getCanvasSize();
  }

  public getDevicePixelRatio(): number {
    return this.canvasDisplay.getDevicePixelRatio();
  }

  public getBoundingClientRect() {
    return this.canvasDisplay.getBoundingClientRect();
  }

  public async getUserContext() {
    if (this.authService.getAuthContext) {
      return await this.authService.getAuthContext();
    }
    return this.authService; // passing in the actual authContext without calling the getAuthContext() function.
  }

  public getScaledValue(v) {
    const viewScale = this.getViewScale();
    return Math.max(v, Math.round(v / viewScale.x));
  }

  public getStrokeWidth(v) {
    const viewScale = this.getViewScale();
    const scaled = v / viewScale.x;
    return Math.round(scaled * 100) / 100;
    // return Math.min(Math.max(Math.round((scaled * 100) / 100), 0.5), 24);
    // console.log(scaled, v, viewScale)
    // if (scaled > v) {
    //   return scaled * 1.4;
    // } else {
    //   return v;
    // }
  }

  /**
   * Main function that draws elements on the canvas
   * @param documentSize
   * @param canvasSize
   * @param viewBox
   */
  public syncState(
    documentSize: SizeDefinition,
    documentViewBox: ViewBox = { width: documentSize.width, height: documentSize.height, x: 0, y: 0 },
    canvasSize: SizeDefinition = { width: documentSize.width, height: documentSize.height },
    viewBox: ViewBox = { width: canvasSize.width, height: canvasSize.height, x: 0, y: 0 },
  ) {
    this.editorHandler?.redrawEditor();
    this.iframeElementHelper?.hideIframe();
    this.canvasDisplay.setSize(documentSize, documentViewBox, canvasSize, viewBox);
    this.svgEditorHandler?.updateEditorTransform();
    this.queueDraw();
  }

  public setSize(
    documentSize: SizeDefinition,
    documentViewBox: ViewBox = { width: documentSize.width, height: documentSize.height, x: 0, y: 0 },
    canvasSize: SizeDefinition = { width: documentSize.width, height: documentSize.height },
    viewBox: ViewBox = { width: canvasSize.width, height: canvasSize.height, x: 0, y: 0 },
  ) {
    this.canvasDisplay.setSize(documentSize, documentViewBox, canvasSize, viewBox);
  }

  public draw(options?: DrawOptions) {
    this.canvasRenderer.draw(options);
  }

  public debounceDraw() {
    this.canvasRenderer.debounceDraw();
  }

  public queueDraw() {
    this.canvasRenderer.queueDraw();
  }

  public async preloadElements(options: DrawOptions) {
    return await this.state.preloadElements(options);
  }

  public getElementInPosition(posX, posY): CanvasElement {
    return this.state.getElementInPosition(posX, posY);
  }

  public applyChanges(changes: DocumentElement[]) {
    console.log('apply changes', changes);
    if (!changes?.length) {
      return;
    }
    for (let i = 0; i < changes.length; i++) {
      const element = changes[i];
      this.state.updateElement(element.id, ObjectUtil.cloneDeep(element));
    }
    // Update frame elements after all changes were applied
    for (let i = 0; i < changes.length; i++) {
      const element = changes[i];
      this.state.updateFrameElement(element.id, ObjectUtil.cloneDeep(element));
    }
    const tableIds = [...new Set(changes.map((e) => e.tableId))];
    for (let i = 0; i < tableIds?.length; i++) {
      this.state.updateTableElement(tableIds[i]);
    }

    this.draw();
  }

  public addElements(elements: Array<DocumentElement>, select = false): Promise<any> {
    // console.log('add elements', elements);
    if (!elements?.length) {
      return;
    }
    this.state.registerElements(elements, this.mode === 'EDIT' ? select : false, false);
    this.draw();
  }

  public reorderElements(elements: Array<DocumentElement>) {
    if (elements?.length === 0) {
      return;
    }
    this.state.reorderElements(elements);
    this.draw();
  }

  removeElements(ids: Array<string>) {
    console.log('removeElements: ', ids);
    if (!ids.length) {
      return;
    }
    const realIds = ids.filter((id) => id && id?.length > 0);
    this.state.frameState?.removeElements(ids);
    this.state.deleteElements(realIds);
    this.documentDefinition.elements = this.documentDefinition.elements.filter((el) => !realIds.includes(el.id));
    this.state.frameState?.reorderElements(this.documentDefinition.elements);
    this.draw();
    this.actionsDispatcher.handleDocumentElementEvent({
      element: null,
      eventType: 'deselect',
    });
  }

  /**
   * Fully clears canvas element, text editor element and removes event listeners from document
   */
  public clear() {
    this.elementId &&
      document.querySelectorAll(`#${this.elementId} #${editorId}-container`)?.forEach((e) => e?.remove());
    this.elementId &&
      document.querySelectorAll(`#${this.elementId} #${stickyEditorId}-container`)?.forEach((e) => e?.remove());
    this.elementId &&
      document.querySelectorAll(`#${this.elementId} #${iframeElementContainerId}`)?.forEach((e) => e?.remove());
    this.elementId && document.querySelectorAll(`#${this.elementId} canvas`)?.forEach((e) => e?.remove());
    if (this.mode === 'EDIT') {
      document.removeEventListener('mousedown', CanvasEventHandlers.mousedownHandler);
      document.removeEventListener('mousemove', CanvasEventHandlers.mousemoveHandler);
      document.removeEventListener('mouseup', CanvasEventHandlers.mouseupHandler);
      CacheManager?.clear();
    }
    this.state?.clear();
    this.annotationHandler?.clear();
    this.iframeElementHelper?.clear();
    this.svgEditorHandler?.clear();
    this.editorHandler?.removeEditor();
    this.canvasDisplay?.clear();
  }

  public highlightElement(id: string, color?: string) {
    const element = this.state?.getElementById(id);
    if (element) {
      element.isHighlighted = color || HIGHLIGHT_COLOR;
    }
    this.queueDraw();
  }

  public dehighlightElement(id: string) {
    const element = this.state?.getElementById(id);
    if (element) {
      element.isHighlighted = false;
    }
    this.queueDraw();
  }

  public outlineElements(ids: string[], color: string) {
    for (let i = 0; i < ids?.length; i++) {
      const element = this.state.getElementById(ids[i]);
      if (element) {
        element.isOutlined = color;
      }
    }
    this.queueDraw();
  }

  public removeOutlineElements(ids: string[]) {
    for (let i = 0; i < ids?.length; i++) {
      const element = this.state.getElementById(ids[i]);
      if (element) {
        element.isOutlined = false;
      }
    }
    this.queueDraw();
  }

  public removeHighlightElement() {
    this.canvasRenderer.highlightBoxRenderer.removeHighlightElement();
  }

  public getHighlightElement(): DocumentElement {
    return this.canvasRenderer?.highlightBoxRenderer?.highlightElement?.elementDefinition;
  }

  public loadDocument(document: Document) {}
  getSelectedElements(): Array<DocumentElement> {
    return (
      this.interactionHandler?.selectionHandler
        ?.getSelectedCanvasElements()
        ?.map((element: CanvasElement) => element.elementDefinition) || []
    );
  }

  public getSelectedElementsByOrder(): Array<DocumentElement> {
    return (
      this.interactionHandler?.selectionHandler?.selectedElementsByOrder
        ?.map((id) => this.getCanvasElementById(id)?.elementDefinition)
        ?.filter((element) => element) || []
    );
  }

  /**
   * Get selected elements, include group and mask members
   * @returns
   */
  public getSelectedExpandedElements() {
    const elements = this.getSelectedElements();
    let selectedElements: DocumentElement[] = [];

    elements.forEach((element) => {
      if (element.type === 'group') {
        // Do not include elements that are already selected.
        const includeMaskMembers = true;
        const includeFrameElements = true;
        const elementsInGroup = this.getAllElementsInGroup(element.id, includeFrameElements, includeMaskMembers).filter(
          (element) => !selectedElements.find((selectedElement) => selectedElement.id === element.id),
        );
        selectedElements = selectedElements.concat(elementsInGroup);
      } else if (this.isMask(element)) {
        if (!this.isEditingMask(element.id)) {
          const maskMembers = this.getMaskMembers(element.id)
            .map((e) => e.elementDefinition)
            .filter((e) => !selectedElements.find((s) => s.id === e.id));
          selectedElements = selectedElements.concat(maskMembers);
        }
      }
      if (selectedElements?.findIndex((e) => e.id === element.id) === -1) {
        selectedElements.push(element);
      }
    });

    // create a map of elements to board index
    let elementsBoardIndexMap = new Map();
    selectedElements.forEach((documentElement) => {
      let boardIndex = this.state.canvasElements.indexOf(this.getCanvasElementById(documentElement.id));
      elementsBoardIndexMap.set(documentElement, boardIndex);
    });

    // sort selectedElements based on boardIndex
    selectedElements.sort((a, b) => {
      let indexA = elementsBoardIndexMap.get(a);
      let indexB = elementsBoardIndexMap.get(b);

      return indexA - indexB;
    });

    return selectedElements;
  }

  public setInteractionMode(mode: string) {
    this.interactionHandler.setInteractionMode(mode);
  }
  public getInteractionMode(): string {
    return this.interactionHandler.getInteractionMode();
  }
  public getOldInteractionMode(): string {
    return this.interactionHandler.getOldInteractionMode();
  }
  copySelectedElement() {}
  activateComponentAndImageInteraction(active: boolean) {
    if (this.interactionHandler) {
      this.interactionHandler.componentAndImageInteractionActive = active;
    }
  }

  activateAssignItemToComponent(active: boolean) {
    if (this.interactionHandler) {
      this.interactionHandler.assignItemToComponentActive = active;
    }
  }

  setAssignItemToComponentConditions(conditions: any[]) {
    if (this.interactionHandler) {
      this.interactionHandler.assignItemToComponentConditions = conditions;
    }
  }

  getAssignItemToComponentConditions() {
    return this.interactionHandler?.assignItemToComponentConditions;
  }

  applyTextChanges(changes: DocumentTextElementEvent) {
    this.editorHandler.applyDocumentTextElementEvent(changes);
  }

  copySelectedElementProperties(): CopiedProperties {
    const selectedElements = this.interactionHandler?.selectionHandler?.getSelectedCanvasElements();
    if (selectedElements?.length > 0) {
      const element = selectedElements[selectedElements.length - 1];
      if (element.elementDefinition.type === 'text') {
        return {
          textFormat: this.editorHandler?.copySelectedTextElementStyle(element),
          element: this.editorHandler?.isEditing(element) ? null : ObjectUtil.cloneDeep(element.elementDefinition),
        };
      }
      return { element: ObjectUtil.cloneDeep(element.elementDefinition) };
    }
  }

  applyCopiedProperties(properties: CopiedProperties) {
    if (properties.textFormat) {
      this.editorHandler?.applyCopiedProperties(properties);
    }
  }

  deselectAll() {
    this.submitCrop();
    this.interactionHandler?.selectionHandler?.deselectAll();
  }

  deselectElements(elements: DocumentElement[]) {
    const canvasElements = this.toCanvasElements(elements);
    if (canvasElements?.length > 0) {
      for (let i = 0; i < canvasElements.length; i++) {
        this.interactionHandler?.selectionHandler?.deselect(canvasElements[i]);
      }
      const selectedElements = this.getSelectedElements();
      this.actionsDispatcher.handleDocumentElementEvent({
        element: selectedElements?.length > 0 ? selectedElements[0] : null,
        selectedElements,
        eventType: 'selected',
      });
      this.draw();
    }
  }

  selectAll() {
    this.submitCrop();
    this.interactionHandler?.selectionHandler?.deselectAll(); // deselect all first to avoid selecting locked elements
    this.interactionHandler?.selectionHandler?.selectAllUnlockedElements();
    this.draw();
  }
  selectElement(element: DocumentElement) {
    const canvasElement = this.getCanvasElementById(element.id);
    canvasElement && this.interactionHandler?.selectionHandler?.selectElementAndDraw(canvasElement, false);
  }

  selectElements(elements: DocumentElement[], multiSelect = false) {
    const canvasElements = this.toCanvasElements(elements);
    if (canvasElements?.length > 0) {
      this.interactionHandler?.selectionHandler?.selectElements(canvasElements, multiSelect);
      this.draw();
    }
  }

  documentGenerationHighlights(elements: DocumentElement[]) {
    const canvasElements = elements.map((element) => this.getCanvasElementById(element.id));
    canvasElements.forEach((element) => {
      (element as CanvasFrameElement).documentGenerationHighlight = true;
    });
    this.draw();
  }

  removeDocumentGenerationHighlights(elements: DocumentElement[]) {
    const canvasElements = elements.map((element) => this.getCanvasElementById(element.id));
    canvasElements.forEach((element) => {
      (element as CanvasFrameElement).documentGenerationHighlight = false;
    });
    this.draw();
  }

  getTextFontProperties() {}
  updateSizeAndPositionForPropertyElements(documentElements: any[], componentElement: DocumentElement) {
    return this.documentService?.updateSizeAndPositionForPropertyElements(documentElements, componentElement);
  }

  updateSizeAndPositionForColorElements(documentElements: any[], size?: any) {
    return this.documentService?.updateSizeAndPositionForColorElements(documentElements, size);
  }

  /**
   * Get frame for @element if it belongs on a frame
   * @param element
   */
  public getFrameForElement(element: DocumentElement): Frame {
    return this.state.frameState?.getFrameForElement(element);
  }

  public getFrames(): Map<string, Frame> {
    return this.state?.frameState?.frames;
  }

  public getFrameElements(): Map<string, string> {
    return this.state?.frameState?.frameElements;
  }

  public editFrameName(id) {
    const canvasFrameElement = this.state.getElementById(id);
    if (canvasFrameElement?.elementDefinition?.type === 'frame') {
      (canvasFrameElement as CanvasFrameElement).frameInputElement.show();
    }
  }

  /**
   * Add @elements to @frameId
   * Used when elements were added to a frame by remote sessions
   * @param frameId
   * @param elements
   */
  public addElementsOnFrame(frameId: string, elements: DocumentElement[]) {
    this.state.frameState?.addElementsOnFrame(frameId, elements);
  }

  /**
   * Remove @elements from frames
   * Used when elements were removed from frames by remote sessions
   * @param elements
   */
  public removeElementsFromFrame(elements: DocumentElement[]) {
    const ids = elements?.map((element) => element.id);
    this.state.frameState?.removeElements(ids);
  }

  public isOnlyFrameSelected() {
    return this.interactionHandler?.selectionHandler?.frameSelectionHandler?.isOnlyFrameSelected();
  }

  public isOnlyFrameInElements(elements) {
    return this.interactionHandler?.selectionHandler?.frameSelectionHandler?.isOnlyFrameInElements(elements);
  }

  public onlyFramesInElements(elements) {
    return this.interactionHandler?.selectionHandler?.frameSelectionHandler?.onlyFramesInElements(elements);
  }

  public getCanvasElementById(id): CanvasElement {
    return this.state.getElementById(id);
  }

  public toCanvasElements(elements: DocumentElement[]): CanvasElement[] {
    if (!elements?.length) {
      return [];
    }
    return elements.map((element) => this.getCanvasElementById(element.id)).filter((element) => element);
  }

  public getElementIndex(id): number {
    return this.state.getElementIndex(id);
  }

  public getVisibleElements(): CanvasElement[] {
    return this.state.getVisibleElements();
  }

  /**
   * Set annotations and warnings
   * @param annotatedElements
   */
  public setAnnotations(annotatedElements, shouldRedraw = true) {
    this.annotationHandler.setAnnotations(annotatedElements, shouldRedraw);
  }

  public toDocumentPosition(x: number, y: number): PositionDefinition {
    const viewBox = this.getViewBox();
    const viewScale = this.getViewScale();
    const boundingClientRect = this.getBoundingClientRect();
    return CanvasUtil.toDocumentPosition(x, y, viewBox, viewScale, boundingClientRect);
  }

  public toDocumentSize(width: number, height: number): SizeDefinition {
    const viewScale = this.getViewScale();
    return CanvasUtil.toDocumentSize(width, height, viewScale);
  }

  public toWindowPosition(x: number, y: number, relative = true): PositionDefinition {
    const viewBox = this.getViewBox();
    const viewScale = this.getViewScale();
    const boundingClientRect = this.getBoundingClientRect();
    return CanvasUtil.toWindowPosition(x, y, viewBox, viewScale, boundingClientRect, relative);
  }

  public toWindowSize(width: number, height: number): SizeDefinition {
    const viewScale = this.getViewScale();
    return CanvasUtil.toWindowSize(width, height, viewScale);
  }

  public sendEvent(event: DocumentElementEvent) {
    this.interactionHandler?.canvasEventHandlers?.sendEvent(event);
  }

  public getTargetElement(): DocumentElement {
    return (
      this.interactionHandler?.canvasEventHandlers?.fileDragHandler?.targetElement?.elementDefinition ||
      this.interactionHandler?.canvasEventHandlers?.itemDragHandler?.targetElement?.elementDefinition
    );
  }

  public getGroupById(id: string): Group {
    return this.state.groupState.groups.get(id);
  }

  public getGroupByMemberId(id: string): Group {
    return this.state.groupState.getGroupByMemberId(id);
  }

  public getAllElementsInGroup(
    groupElementId: string,
    includeFrameElements: boolean,
    includeMaskElements: boolean,
  ): DocumentElement[] {
    return this.state.groupState
      .getAllElementsInGroup(groupElementId, false, includeFrameElements, includeMaskElements)
      .map((element) => element.elementDefinition);
  }

  public getAllCommonBounds(): CoordinateBox {
    return this.state?.getCommonBounds(this.state?.canvasElements);
  }

  public getCommonBounds(elements: DocumentElement[], options?: DrawOptions): CoordinateBox {
    return this.state?.getCommonBounds(this.toCanvasElements(elements), options);
  }

  public isMask(element: DocumentElement): boolean {
    return CanvasMaskState.isMask(element);
  }

  public isMaskOrMaskMember(id: string): Mask {
    return this.state?.maskState?.isMaskOrMaskMember(id);
  }

  public getMaskMembers(id: string): CanvasElement[] {
    return this.state?.maskState?.getMaskMembers(id);
  }

  public isEditingMask(id: string): boolean {
    return this.state?.maskState?.isEditingMask(id);
  }

  public getMaskByMemberId(id: string): Mask {
    return this.state?.maskState?.getMaskByMemberId(id);
  }

  public getImageCacheMetrics() {
    return ImageElementCache?.getCacheMetrics();
  }

  public toDataURL(type = 'image/png', encoderOptions?: number): string {
    return this.canvasDisplay?.toDataURL(type, encoderOptions);
  }

  public setSvgElementSelection(elementId: string, svgElementSelectionIds: string[]): SVGElement[] {
    return this.svgEditorHandler?.setSvgElementSelection(elementId, svgElementSelectionIds);
  }

  public setSvgElementHighlights(elementId: string, svgElementIds: string[]): SVGElement[] {
    return this.svgEditorHandler?.setSvgElementHighlights(elementId, svgElementIds);
  }

  public clearSvgElementSelection(elementId: string) {
    this.svgEditorHandler?.clearSvgElementSelection(elementId);
  }

  public clearSvgElementHighlights(elementId: string) {
    this.svgEditorHandler?.clearSvgElementHighlights(elementId);
  }

  public startCrop(element: DocumentElement) {
    const canvasElement = this.getCanvasElementById(element.id);
    if (canvasElement) {
      this.state.cropState.startCrop(canvasElement);
    }
  }

  public isEditingCrop(): boolean {
    return !!this.state.cropState.isEditingCrop;
  }

  public stopCrop() {
    this.state.cropState.stopCrop();
  }

  public isCropping(elementId: string) {
    return this.state.cropState.isCropping(elementId);
  }

  public cancelCrop() {
    this.state.cropState.cancelCrop();
  }

  public submitCrop() {
    this.state.cropState.submitCrop();
  }

  public getMinOriginalSizeScale() {
    return Math.min(...this.state.canvasElements.map((e) => e.getOriginalSizeScale()));
  }

  public async combineElementsIntoSvg(elements: DocumentElement[]): Promise<string> {
    if (!SVGCombiner.areElementsEligibleForCombine(elements)) return;
    const canvasElements = this.toCanvasElements(elements);
    return await this.svgCombiner?.combineElementsIntoSvg(canvasElements);
  }

  public isSingleTableSelectedAndActive(): CanvasTableElement {
    const element = this.getSingleSelectedTable();
    return element?.table?.tableSelectionState?.selectedRanges?.length > 0 ? element : null;
  }

  public getValidRowsToDelete() {
    const element = this.getSingleSelectedTable();
    return element?.table?.tableSelectionState?.getValidRowsToDelete();
  }

  public getValidColumnsToDelete() {
    const element = this.getSingleSelectedTable();
    return element?.table?.tableSelectionState?.getValidColumnsToDelete();
  }

  public handleTableDelete(element: CanvasTableElement, direction?: 'column' | 'row', indexes?: number[]) {
    if (direction) {
      this.canvasTableService.delete(direction, element, indexes);
    } else {
      element?.table?.handleDeleteAction();
    }
  }

  public moveToNextTableCell(element: CanvasTableElement, options?: { advanceRow: boolean }) {
    element?.table?.moveToNextTableCell(options);
  }

  public moveToPrevTableCell(element: CanvasTableElement, options?: { advanceRow: boolean }) {
    element?.table?.moveToPrevTableCell(options);
  }

  public moveToNextTableRow(element: CanvasTableElement) {
    element?.table?.moveToNextTableRow();
  }

  public moveToPrevTableRow(element: CanvasTableElement) {
    element?.table?.moveToPrevTableRow();
  }

  public getTableChildElements(element: DocumentElement): DocumentElement[] {
    if (element?.type === 'table') {
      const tableElement = this.getCanvasElementById(element.id);
      if (tableElement) {
        return (tableElement as CanvasTableElement)?.getTableChildElements();
      }
    }
    return [];
  }

  public getSingleSelectedTable(): CanvasTableElement {
    const selectedElements = this.interactionHandler?.selectionHandler?.getSelectedCanvasElements();
    if (selectedElements?.length !== 1) return null;
    if (selectedElements[0].elementDefinition.type !== 'table') return null;
    return selectedElements[0] as CanvasTableElement;
  }

  public getTableElementsToPaste(): DocumentElement[] {
    const tableElement = this.isSingleTableSelectedAndActive();
    if (!tableElement) return;
    return this.canvasTableService.getTableElementsToPaste(tableElement);
  }

  public pasteTableCells(elements: DocumentElement[]) {
    const tableElement = this.isSingleTableSelectedAndActive();
    if (!tableElement) return;
    this.canvasTableService.pasteTableCells(tableElement, elements);
  }
}
