import { PropertyType } from '@contrail/types';
import { FilterDefinition, FilterOption } from './filter-definition';

export class FilterHelper {
  public static toSimpleCriteria(filterDef: FilterDefinition) {
    const simpleCriteria = {};
    for (const criteria of filterDef?.filterCriteria?.propertyCriteria || []) {
      if (!criteria?.filterPropertyDefinition) {
        continue;
      }

      const isTypeReference = criteria.filterPropertyDefinition.propertyType === PropertyType.TypeReference;
      const propertySlug = criteria.filterPropertyDefinition.slug;
      const slug = isTypeReference ? `${propertySlug}Id` : propertySlug;
      simpleCriteria[slug] = criteria.criteriaValue;
    }
    return simpleCriteria;
  }

  public prepareFilterDefinition(filterDef: FilterDefinition, sourceData): FilterDefinition {
    const filterObj = JSON.parse(JSON.stringify(filterDef));
    this.determineDerviedOptionValues(filterObj, sourceData);
    return filterObj;
  }

  private determineDerviedOptionValues(filterDef: FilterDefinition, sourceData): void {
    filterDef.filterPropertyDefinitions
      .filter((prop) => prop.optionsType === 'derived')
      .map((prop) => {
        prop.options = this.getUniqueOptions(prop, sourceData);
      });
  }

  private getUniqueOptions(filter, sourceData) {
    const options: Array<FilterOption> = [];
    const valueMap = {};
    sourceData.map((obj) => {
      const propertySet = obj.properties || obj.atts;
      if (!propertySet) {
        return;
      }
      const val = propertySet[filter.slug];
      if (val) {
        valueMap[val] = val;
      }
    });
    const uniqueVals = Object.values(valueMap).sort();
    for (const val of uniqueVals) {
      options.push({ value: '' + val, display: '' + val });
    }
    return options;
  }
}
