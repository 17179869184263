import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Types } from '@contrail/sdk';
import { Type, TypeProperty } from '@contrail/types';

@Component({
  selector: 'app-object-reference-chip',
  templateUrl: './object-reference-chip.component.html',
  styleUrls: ['./object-reference-chip.component.scss'],
})
export class ObjectReferenceChipComponent implements OnChanges {
  @Input() object: any;
  @Input() typeProperty: TypeProperty;
  @Input() edit = false;
  @Input() maxDisplayLength = 16;
  @Input() allowDisplayTextShortening = true;
  @Input() clickable = true;
  @Output() clearValue = new EventEmitter();
  @Output() clicked = new EventEmitter();

  public objectName: string;
  public objectNumber: string;
  public hasStatus: boolean;
  public colorStage: string;
  public type: Type;
  constructor() {}

  async ngOnChanges() {
    this.objectName = this.object.name;
    if (this.object?.typeId) {
      this.type = await new Types().getType({ id: this.object.typeId });
      if (this.type.typeProperties.find((prop) => prop.slug === 'itemStatus')) {
        this.hasStatus = true;
        if (this.object.itemStatus === 'concept' || !this.object.itemStatus) {
          this.colorStage = 'concept';
        } else {
          this.colorStage = null;
        }
      }
    }

    const isItemObject = this.typeProperty?.referencedTypeRootSlug === 'item';
    if (isItemObject) {
      this.setObjectNameForItem();
    }
  }

  private setObjectNameForItem() {
    const isItemOptionProperty = this.typeProperty.slug === 'itemOption';
    const isOptionReference = this.typeProperty.referencedTypeRole === 'option';

    if (isItemOptionProperty) {
      this.objectName = this.object.optionName;
      return;
    }

    if (isOptionReference) {
      this.objectName = `${this.object.name} - ${this.object.optionName}`;
      return;
    }

    this.objectName = this.object.name;
  }

  remove() {
    this.clearValue.emit();
  }

  viewDetails() {
    if (this.clickable) {
      this.clicked.emit();
    }
  }
}
