<div class="flex">
  <button
    class="border-style-button"
    #assortmentMenuTrigger="matMenuTrigger"
    [matMenuTriggerFor]="projectMenu"
    (menuOpened)="handleProjectMenuOpened()"
    mat-icon-button
    matTooltip="Control project context"
    matTooltipPosition="above"
    data-test="item-project-context"
  >
    <mat-icon
      [ngClass]="{ 'opacity-30': !projectItem }"
      class="icons material-icons-outlined"
      svgIcon="project"
    ></mat-icon>
  </button>

  <mat-menu #projectMenu="matMenu" class="border-style-menu text-black60">
    <div *ngIf="queryProjectItemsInProgress" class="option" mat-menu-item>
      <mat-spinner diameter="20"></mat-spinner>
    </div>
    <div class="w-full h-full my-1" *ngIf="!queryProjectItemsInProgress">
      <div
        mat-menu-item
        *ngFor="let project of selectableProjects"
        mat-menu-item
        [disabled]="project.id === projectItem?.projectId"
        class="option"
        (click)="assignProject(project)"
      >
        {{ project.name }}
      </div>
      <div
        mat-menu-item
        class="!text-primary option"
        *ngIf="addToCurrentProjectActive"
        (click)="assignToCurrentProject()"
      >
        + Add to project
      </div>
    </div>
  </mat-menu>
</div>
