import { Injectable } from '@angular/core';
import { API_VERSION, Entities, SortOrderOptions } from '@contrail/sdk';
import { PaginatedSearchResult } from '@common/entities/entities.interfaces';
import { EntitiesHelper } from '@common/entities/entities-helper';
import { EntitySnapshot } from './document-history-store/document-history.state';

@Injectable({
  providedIn: 'root',
})
export class EntitySnapshotService {
  public async getEntitySnapshotById(id: string) {
    const snapshot = await new Entities().get({ entityName: 'entity-snapshot', id });
    if (snapshot.snapshotDownloadURL) {
      const response = await fetch(snapshot.snapshotDownloadURL);
      const snapshotDetails = await response.json();
      snapshot.snapshot = snapshotDetails;
    }
    return snapshot;
  }

  public async getAllEntitySnapshots(options: { criteria: { [key: string]: any }; relations?: string[] }) {
    const { criteria, relations } = options;

    const snapshots = await EntitiesHelper.getAllPaginatedResults<EntitySnapshot>((nextPageKey) =>
      this.getEntitySnapshotsPaginated({ criteria, relations, nextPageKey }),
    );

    return snapshots;
  }

  public async getEntitySnapshotsPaginated(options: {
    criteria: { [key: string]: any };
    relations?: string[];
    nextPageKey?: string;
  }): Promise<PaginatedSearchResult<EntitySnapshot>> {
    const { criteria, relations, nextPageKey } = options;

    const results: PaginatedSearchResult<EntitySnapshot> = await new Entities().get({
      entityName: 'entity-snapshot',
      criteria,
      relations,
      order: [{ order: SortOrderOptions.DESC, orderField: 'createdOn' }],
      take: 3000,
      apiVersion: API_VERSION.V2,
      paginate: true,
      nextPageKey,
    });

    return results;
  }
}
