import { SVGHelper } from '../../svg-helper';
import { SvgElementSelectionManager } from './svg-element-selection-manager';
import * as d3 from 'd3';

/**
 * Manages SVGElements created to overlay on top of existing elements for highlighting purposes
 */
export class SvgElementOverlayManager {
  private overlayElementsMap: Map<string, SVGElement>; //Map of overlay elements by source element id
  private selectionManager: SvgElementSelectionManager;
  private overlayParentElement: SVGElement;

  constructor(elementSelectionManager: SvgElementSelectionManager) {
    this.overlayElementsMap = new Map<string, SVGElement>();

    this.selectionManager = elementSelectionManager;

    const _this = this;
    this.selectionManager.onSelectionStateAdded.subscribe((selectionEvent) => {
      _this.handleSelectionStateAdded(selectionEvent.affectedElements);
    });

    this.selectionManager.onSelectionStateRemoved.subscribe((selectionEvent) => {
      _this.handleSelectionStateRemoved(selectionEvent.affectedElements);
    });
  }

  setOverlayParent(parentElement: SVGElement) {
    this.overlayParentElement = parentElement;
  }

  public handleSelectionStateAdded(elements: SVGElement[]) {
    for (let i = 0; i < elements.length; i++) {
      this.addOverlayElement(elements[i]);
    }
  }

  public handleSelectionStateRemoved(elements: SVGElement[]) {
    for (let i = 0; i < elements.length; i++) {
      // if element is neither highlighted nor selected then we can dispose of the corresponding overlay element
      if (
        !this.selectionManager.isElementHighlighted(elements[i]) &&
        !this.selectionManager.isElementSelected(elements[i])
      ) {
        this.removeOverlayElement(elements[i]);
      }
    }
  }

  private addOverlayElement(srcElement: SVGElement) {
    if (this.overlayElementsMap.has(srcElement.id)) {
      return;
    }

    const overlayElement: SVGElement = this.createOverlayElement(srcElement);
    this.overlayElementsMap.set(srcElement.id, overlayElement);

    const parentNode = SVGHelper.getParentSvgNode(srcElement);
    if (parentNode) {
      parentNode.appendChild(overlayElement);
    } else this.overlayParentElement.appendChild(overlayElement);
  }

  private removeOverlayElement(srcElement: SVGElement) {
    if (this.overlayElementsMap.has(srcElement.id)) {
      let overlayElement = this.overlayElementsMap.get(srcElement.id);
      overlayElement.remove();
      this.overlayElementsMap.delete(srcElement.id);
    }
  }

  clearOverlayElements(): void {
    this.overlayElementsMap.forEach((value, key) => {
      value.remove();
    });

    this.overlayElementsMap.clear();
  }

  private createOverlayElement(srcElement: SVGElement): SVGElement {
    let overlayElement: SVGGraphicsElement;
    const svgGraphicsElement = srcElement as SVGGraphicsElement;
    let transformMatrix = SVGHelper.calculateFlatTransformMatrixForElement(
      svgGraphicsElement,
      this.overlayParentElement,
    );
    let transformMatrixStr = this.getMatrixAsString(transformMatrix);

    switch (srcElement.nodeName) {
      case 'circle':
      case 'ellipse':
      case 'line':
      case 'polygon':
      case 'polyline':
      case 'rect':
      case 'path':
      case 'use':
        overlayElement = svgGraphicsElement.cloneNode(true) as SVGGraphicsElement;
        overlayElement.id = 'overlay_' + svgGraphicsElement.id;

        d3.select(overlayElement)
          .style('stroke', '#41a7f0')
          .attr('fill-opacity', '0.0')
          .attr('pointer-events', 'none')
          .attr('transform', transformMatrixStr)
          .attr('class', '');

        break;

      case 'image':
      case 'text':
      default:
        const boundingRect = svgGraphicsElement.getBBox();

        const rectElement = document.createElementNS('http://www.w3.org/2000/svg', 'rect');

        overlayElement = d3
          .select(rectElement)
          .attr('id', 'overlay_' + svgGraphicsElement.id)
          .attr('x', boundingRect.x)
          .attr('y', boundingRect.y)
          .attr('width', boundingRect.width)
          .attr('height', boundingRect.height)
          .attr('transform', transformMatrixStr)
          .attr('fill-opacity', 0)
          .attr('stroke', '#41a7f0')
          .attr('stroke-width', '3px')
          .attr('pointer-events', 'none')
          .attr('class', '')
          .node();

        break;
    }

    return overlayElement;
  }

  private getMatrixAsString(matrix: DOMMatrix): string {
    return `matrix(${matrix.a} ${matrix.b} ${matrix.c} ${matrix.d} ${matrix.e} ${matrix.f})`;
  }
}
